/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import DialogRequest from "../dialogs/Request";
import { combinePortsAndCities, getApiHost } from "../../misc/api";
import SearchResultRouteNear from "../SearchResultRouteNear";
import DialogRequestFree from "../dialogs/RequestFree";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../misc/localization";
import SearchFilter from "../SearchFilter";
import { ReactComponent as IconSettings } from "../../assets/icons/settings.svg";
import { api_getSettingByCode } from "../../redux/actions/settings";
import { api_getBanners } from "../../redux/actions/banners";
import Carousel from "react-material-ui-carousel";
import GetMeMoreInformation from "../dialogs/GetMeMoreInformation";
import CloseIcon from '@mui/icons-material/Close';
import lodash from "lodash";

const otherLimit = 10;

const SearchResult = (props) => {
    const location = useLocation();

    moment.locale(getLanguage(location));

    const allPorts = useSelector(state => state.port.data);
    const allCities = useSelector(state => state.city.data);
    const companies = useSelector(state => state.company.data);
    const limits = useSelector(state => state.route.limits);
    const validTill = useSelector(state => state.route.validTill);
    const allTags = useSelector(state => state.tag.data);
    
    const [ports, setPorts] = useState([]);
    const [exact, setExact] = useState(null);
    const [near, setNear] = useState(null);
    const [filter, setFilter] = useState(null);
    const [openRequestDialog, setOpenRequestDialog] = useState(false);
    const [requestDialogRoute, setRequestDialogRoute] = useState(null);
    const [showedOther, setShowedOther] = useState(false);
    const [openRequestFreeDialog, setOpenRequestFreeDialog] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [showBanners, setShowBanners] = useState(true);
    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState([]);
    const [openGetMeMoreInformation, setOpenGetMeMoreInformation] = useState(false);

    useEffect(() => {
        getBanners();
    }, []);

    useEffect(() => {
        setPorts(combinePortsAndCities(allPorts, allCities));
    }, [allPorts, allCities]);

    useEffect(() => {
        if (companies.length) {
            if (Array.isArray(props.data.exact) && typeof limits.exact.countAfter === "number") {
                setExact(props.data.exact);
                setNear(props.data.nearLocation);
            }
        }
    }, [props.data, companies]);

    useEffect(() => {
        if (props.filter.POL && props.filter.POD) {
            if (filter && (props.filter.POL !== filter.POL || props.filter.POD !== filter.POD))
                setExact(null);
            setFilter(props.filter);
        }
    }, [props.filter]);

    const getBanners = async () => {
        setShowBanners((await api_getSettingByCode("showSearchPageBanner"))?.value === "Y" || false);
        setBanners((await api_getBanners()).filter(x => x.type === "search_page"));
    }

    const sendRequest = (route) => {
        setRequestDialogRoute(route);
        setOpenRequestDialog(true);
    }

    const closeRequestDialog = () => {
        setOpenRequestDialog(false);
        setRequestDialogRoute(null);
    }

    const onClickShowOther = () => {
        setShowedOther(true);
    }

    const closeRequestFreeDialog = () => {
        setOpenRequestFreeDialog(false);
    }

    const   applayFilter = (filter) => {
        let exact = lodash.cloneDeep(props.data.exact);
        let near = lodash.cloneDeep(props.data.nearLocation);

        if (filter.exact || filter.near) {
            if (!filter.exact)
                exact = [];
            
            if (!filter.near) {
                near = [];
            }
        }

        if ((filter.withPrices || filter.woPrices) && !(filter.withPrices && filter.woPrices)) {
            const validTillsWithPrices = validTill.filter(x => x.prices.some(y => y.coc?.length || y.soc?.length));

            if (filter.withPrices) {
                exact = exact.filter(x => validTillsWithPrices.some(y => y.line === x.line));
                near = near.filter(x => validTillsWithPrices.some(y => y.line === x.line));
            }

            if (filter.woPrices) {
                exact = exact.filter(x => !validTillsWithPrices.some(y => y.line === x.line));
                near = near.filter(x => !validTillsWithPrices.some(y => y.line === x.line));
            }
        }

        if (filter.checkedTags.length) {
            const lines = [];
            filter.checkedTags.forEach(x => {
                const neededTagsIds = allTags.filter(t => t.value === x.value).map(t => t._id);
                const _companies = companies.filter(y => y.tags.filter(t => neededTagsIds.indexOf(t) !== -1).length);
                if (_companies.length) {
                    for (const company of _companies)
                        if (lines.indexOf(company.line) === -1)
                            lines.push(company.line);
                }
            });

            exact = exact.filter(y => lines.indexOf(y.line) !== -1);
            near = near.filter(y => lines.indexOf(y.line) !== -1);
        }

        if (filter.checkedCompanies.length) {
            const lines = [];
            filter.checkedCompanies.forEach(y => {
                const filteredCompanies = companies.filter(z => z.company === y.company);
                for (const company of filteredCompanies)
                    lines.push(company.line);
            });

            exact = exact.filter(y => lines.indexOf(y.line) !== -1);
            near = near.filter(y => lines.indexOf(y.line) !== -1);
        }
        
        setExact(exact);
        setNear(near);
        closeFilter();
    }

    const resetFilter = () => {
        setExact(props.data.exact);
        setNear(props.data.nearLocation);
        closeFilter();
    }

    const openFilter = () => {
        setIsOpenFilter(true);
        const height = window.document.querySelector(".filter").clientHeight;
        window.document.querySelector("body #root").style.height = `${height}px`;
        window.document.querySelector("body #root").style.overflow = `hidden`;
    }

    const closeFilter = () => {
        setIsOpenFilter(false);
        window.document.querySelector("body #root").style.height = `auto`;
        window.document.querySelector("body #root").style.overflow = `auto`;
    }

    const onSlideClick = (item) => {
        if (Array.isArray(item.onClick) && item.onClick.length) {
            for (const arrItem of item.onClick) {
                switch (arrItem.type) {
                    case "js":
                        switch (arrItem.value) {
                            case "moreInfoDialog":
                                if (item?.id > 0 && item?.id <= 5) {
                                    if (typeof window.ym === "function")
                                        window.ym(89710989,'reachGoal','banner ' + item.id);
                                }
                                setBanner(item);
                                setOpenGetMeMoreInformation(true);
                                break;
                            default:
                        }
                        break;
                    case "link":
                        window.location.href = arrItem.value;
                        break;
                    default:
                }
            }
        }
    }

    const getImg = (banner) => {
        const dom = window.document.querySelector(".carousel");
        const width = dom?.clientWidth || 0;

        const lang = getLanguage(location);
        switch (width) {
            case 1216:
                return lang === "ru" ? banner.imgSrcDesktop : (lang === "tr" ? banner.imgSrcDesktopTr : banner.imgSrcDesktopEn);
            case 684:
                return lang === "ru" ? banner.imgSrcTablet : (lang === "tr" ? banner.imgSrcTabletTr : banner.imgSrcTabletEn);
            case 328:
                return lang === "ru" ? banner.imgSrcMobile : (lang === "tr" ? banner.imgSrcMobileTr : banner.imgSrcMobileEn);
            default:
                return lang === "ru" ? banner.imgSrcDesktop : (lang === "tr" ? banner.imgSrcDesktopTr : banner.imgSrcDesktopEn);
        }
    }

    const onCloseGetMeMoreInformation = () => {
        setOpenGetMeMoreInformation(false);
    }

    return <div className="SearchResultContainer">
        {filter
            ? <div className={`filter ${isOpenFilter ? "open" : ""}`}>
                <SearchFilter open={isOpenFilter} onApplyFilter={applayFilter} onResetFilter={resetFilter} isLoading={exact === null && near === null} exact={props.data.exact} near={props.data.nearLocation} />
            </div>
            : <div />
        }
        <div className={`filterButton ${!exact && !near ? "hide" : ""}`}>
            <Button className="btn" variant="contained" onClick={openFilter}>{getLocalizeText("components/searchResult", "filter", getLanguage(location))} <IconSettings /></Button>
        </div>

        <div>
            {showBanners
                ? <div className="carouselContainer">
                    <div className="carouselCloseContainer">
                        <span>{getLocalizeText("components/searchResult", "adv", getLanguage(location))}</span>
                        <CloseIcon className="carouselClose" onClick={() => setShowBanners(false)} />
                    </div>
                    <Carousel
                        className="carousel" 
                        bull
                        height={280} 
                        interval={7000} 
                        animation="slide" 
                        duration={700} 
                        stopAutoPlayOnHover={true} 
                        indicatorContainerProps={{className: "bullets"}}
                        navButtonsProps={{className: "navButtons"}}
                    >
                        {banners.map(x => <img key={`banner${x.img}`} x-data-img={x.img} className="banner" src={`${getApiHost()}/static/banners/${getImg(x)}`} alt={x.name} onClick={() => onSlideClick(x)} />)}
                    </Carousel>
                </div>
                : null
            }

            {exact && Array.isArray(exact) && Array.isArray(ports) && ports.length && Array.isArray(companies) && companies.length
                ?   <div className="searchResult">
                        <div className="header">                                    
                            <div className="title">{getLocalizeText("components/searchResult", "found", getLanguage(location))} ({exact.length})</div>
                        </div>
                        {exact.length
                            ?   <div className="blocks">
                                    {exact.length
                                        ?   exact.map(route => <SearchResultRouteNear
                                                key={route._id.toString()}
                                                route={route}
                                                ports={ports}
                                                filter={filter}
                                                companies={companies}
                                                sendRequest={sendRequest}
                                                validTill={validTill?.find(x => x.line === route.line) || null}
                                                validTillDate={validTill?.find(x => x.line === route.line)?.prices.find(x => x.soc.find(y => y.validTill))?.soc.find(y => y.validTill)?.validTill || validTill?.find(x => x.line === route.line)?.prices.find(x => x.coc.find(y => y.validTill))?.coc.find(y => y.validTill)?.validTill || null}
                                            />)
                                        :   null
                                    }
                                </div>
                            :   <div className="notFound hide">{getLocalizeText("components/searchResult", "notFound", getLanguage(location))}</div>
                        }

                        <div className="header mt">                                    
                            <div className="title">{getLocalizeText("components/searchResult", "foundNear", getLanguage(location))} ({near.length})</div>
                        </div>
                        {near.length > 0
                            ?   <>
                                    <div className="blocks">
                                        {near && filter
                                            ?   near.filter((route, index) => index < otherLimit).map( route => <SearchResultRouteNear 
                                                    key={route._id.toString()}
                                                    route={route}
                                                    ports={ports}
                                                    filter={filter}
                                                    companies={companies}
                                                    sendRequest={sendRequest}
                                                />)
                                            :   null
                                        }
                                    </div>
                                    <div className={`otherBtn${showedOther ? " hide" : ""}${near.length > otherLimit ? "" : " hide"}`}>
                                        <Button variant="outlined" onClick={onClickShowOther}>{getLocalizeText("components/searchResult", "showMore", getLanguage(location))}</Button>
                                    </div>
                                    <div className={`blocks other ${showedOther ? "show" : ""}`}>
                                        {near && filter
                                            ?   near.filter((route, index) => index >= otherLimit).map( route => <SearchResultRouteNear
                                                key={route._id.toString()}
                                                route={route}
                                                ports={ports}
                                                filter={filter}
                                                companies={companies}
                                                sendRequest={sendRequest}
                                            />)
                                            :   null
                                        }
                                    </div>
                                </>
                            :   null
                        }
                    </div>
                :   null
            }
        </div>

        <DialogRequest open={openRequestDialog} onClose={closeRequestDialog} route={requestDialogRoute} routeDisabled={true} />
        <DialogRequestFree open={openRequestFreeDialog} onClose={closeRequestFreeDialog} />
        <GetMeMoreInformation banner={banner} open={openGetMeMoreInformation} onClose={onCloseGetMeMoreInformation} />
    </div>
}

export default SearchResult;