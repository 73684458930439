import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";
import { api_saveDomContentByCode } from "../../../../redux/actions/domContent";

const AdminDomContentEditDialog = (props) => {
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [descriptionEn, setDescriptionEn] = useState("");
    const [descriptionTr, setDescriptionTr] = useState("");
    const [value, setValue] = useState("");

    useEffect(() => {
        if (props.item) {
            setCode(props.item.code);
            setDescription(props.item.description);
            setDescriptionEn(props.item.descriptionEn);
            setDescriptionTr(props.item.descriptionTr);
            setValue(props.item.value);
        }
    }, [props.item]);

    const resetForm = () => {
        setCode("");
        setDescription("");
        setDescriptionEn("");
        setDescriptionTr("");
        setValue("");
    }

    const onClose = () => {
        resetForm();
        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onSave = async () => {
        const r = await api_saveDomContentByCode(props.item.code, code, {
            description,
            descriptionEn,
            descriptionTr
        }, value);
        if (r) {
            onClose();
            if (typeof props.onSave === "function")
                props.onSave();

            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Содержимое было успешно сохранено</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не удалось сохранить содержимое</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
    }

    const change = (e) => {
        const name = e.target.name[0].toUpperCase() + e.target.name.substring(1);
        const value = e.target.value;

        eval(`set${name}("${value}")`);
    }

    return <Dialog className="AdminDomContentEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="row">
                    <TextField fullWidth size="small" label="Код" value={code} name="code" onChange={change} />
                </div>
                <div className="row">
                    <TextField fullWidth size="small" label="Описание, ru" value={description} name="description" onChange={change} multiline rows={1} />
                </div>
                <div className="row">
                    <TextField fullWidth size="small" label="Описание, en" value={descriptionEn} name="descriptionEn" onChange={change} multiline rows={1} />
                </div>
                <div className="row">
                    <TextField fullWidth size="small" label="Описание, tr" value={descriptionTr} name="descriptionTr" onChange={change} multiline rows={1} />
                </div>
                <div className="row">
                    <TextField fullWidth size="small" label="Значение" value={value} name="value" onChange={change} />
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminDomContentEditDialog;