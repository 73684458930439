import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";
import { api_createUser, api_saveUser } from "../../../../../redux/actions/users";
import { noAutofill } from "../../../../../misc/dom";

const AdminUserEditDialog = (props) => {
    const allTags = useSelector(state => state.tag.data);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [active, setActive] = useState(false);

    const [eEmail, setEEmail] = useState(false);
    const [ePassword, setEPassword] = useState(false);
    
    useEffect(() => {
        if (props.item) {            
            setEmail(props.item.email);
            setActive(props.item.active);
        }
    }, [props.item]);

    const resetForm = () => {
        setEmail("");
        setPassword("");
        setPassword2("");
        setActive(false);

        setTimeout(() => {
            clearError();
        }, 300);
    }

    const clearError = () => {
        setEEmail(false);
        setEPassword(false);
    }

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
        resetForm();
    }

    const isValid = () => {
        let hasErrors = false;

        if (!email.length) {
            setEEmail(true);
            hasErrors = true;
        }

        if (password.length && password !== password2) {
            setEPassword(true);
            hasErrors = true;
        }

        return !hasErrors;
    }

    const onSave = async () => {
        clearError();
        if (isValid()) {
            const data = {
                email,
                password,
                active
            };

            let r = null;
            if (props.item?._id) {
                r = await api_saveUser(props.item._id, data);
            }
            else {
                r = await api_createUser(data);
            }

            if (r) {
                notification.addNotification({
                    message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Пользователь успешно сохранен</span></div>,
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
                if (typeof props.onSave === "function")
                    props.onSave();
                    onClose();
            }
            else {
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> Не удалось сохранить пользователя</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
            }
        }
    }

    const changePassword = (e) => {
        setPassword(e.target.value);
    }

    const changePassword2 = (e) => {
        setPassword2(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeActive = (e) => {
        setActive(e.target.checked);
    }

    return <Dialog className="AdminUserEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="row">
                    <TextField required fullWidth size="small" label="E-mail" variant="outlined" value={email} onChange={changeEmail} error={eEmail} autoComplete={noAutofill()} />
                </div>

                <div className="row">
                    <TextField type="password" fullWidth size="small" label="Пароль" variant="outlined" value={password} onChange={changePassword} error={ePassword} autoComplete={noAutofill()} />
                </div>

                <div className="row">
                    <TextField type="password" fullWidth size="small" label="Повторите пароль" variant="outlined" value={password2} onChange={changePassword2} error={ePassword} autoComplete={noAutofill()} />
                </div>

                <div className="row">
                    <FormControlLabel control={<Checkbox checked={active} onChange={changeActive} />} label="Активно" />
                </div>
                
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminUserEditDialog;