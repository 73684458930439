import React, { useLayoutEffect, useState } from "react";
import "./style.scss";
import TopMenu from "../../menues/TopMenu";
import { Button } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";
import icon_menu from "../../assets/icons/menu.svg";
import { ReactComponent as MenuSvg } from "../../assets/icons/menu.svg";
import MobileMenu from "../../menues/MobileMenu";
import { isScrollOnTop } from "../../misc/scroll";
import icon_logo from "../../assets/icons/logo.svg";
import icon_logo_text from "../../assets/icons/logoText.svg";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import { ReactComponent as CloseSvg } from "../../assets/icons/x.svg";
import LangSwitcher2 from "../LandSwitcher2";

const Header = (props) => {
    const location = useLocation();

    const [openMobileMenu, setOpenMobileMenu] = useState(false);

    useLayoutEffect(() => {
        if (props.fixed) {
            window.addEventListener("scroll", onScroll);
            onScroll();
        }

        return () => {
            if (props.fixed)
                window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const onScroll = () => {
        const domHeader = document.querySelector(".headerBg");
        const domLogo = document.querySelector(".logoTitle");
        const domLogoR = document.querySelector(".logoR");
        const domMobileMenuIcon = document.querySelector(".mobileMenuIcon");

        if (domHeader) {
            if (isScrollOnTop()) {
                domHeader.classList.remove("open");
                domLogo.classList.add("top");
                domLogoR.classList.add("top");
                domMobileMenuIcon.classList.add("top");
            }
            else {
                domHeader.classList.add("open");
                domLogo.classList.remove("top");
                domLogoR.classList.remove("top");
                domMobileMenuIcon.classList.remove("top");
            }
        }
    }

    const getLogoUrl = () => {
        const curLang = getLanguage(location);
        if (curLang === "ru")
            return `${window.location.protocol}//${window.location.host.toLowerCase().replace("top.", "")}`;
        return `${window.location.protocol}//${window.location.host.toLowerCase().replace("top.", "")}/${curLang}/`;
    }

    return <div className={`Header ${props.fixed ? "fixed" : ""}`}>
        <div className="headerContainer">
            <div className="headerContent">
                <a href={getLogoUrl()} className="leftBlock">
                    <img className="logo" src={icon_logo} alt="logo" />
                    <div className="logoTitle"><img src={icon_logo_text} alt="logo text" /></div>
                </a>
                <div className="centerBlock">
                    <TopMenu fixed={props.fixed} />
                </div>
                <div className="mobileMenu">
                    <div className={`langContainer ${openMobileMenu ? "hide" : ""}`}>
                        <LangSwitcher2 />
                    </div>
                    <div className="menuContainer" onClick={() => setOpenMobileMenu((value) => !value)}>
                        <MenuSvg className={`mobileMenuIcon ${!openMobileMenu ? "active" : ""}`} />
                        <CloseSvg className={`mobileMenuIcon ${openMobileMenu ? "active" : ""}`} />
                        <div className="menuText">
                            {getLocalizeText("menues/mobile", "menu", getLanguage(location))}
                        </div>
                    </div>
                </div>
                <MobileMenu open={openMobileMenu} onClose={() => setOpenMobileMenu(false)}/>
            </div>
        </div>
    </div>
}

export default Header;
