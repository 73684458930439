import axios from "axios";
import { getApiHost } from "../../misc/api";
import { TAGS_DATA, TAGS_FETCHED, TAGS_FETCHING, TAGTYPES_DATA, TAGTYPES_FETCHED, TAGTYPES_FETCHING } from "../types";

const apiHost = getApiHost();

export const api_getTags = () => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: TAGS_FETCHING,
            payload: true
        });

        data = (await axios.get(`${apiHost}/api/tags`)).data;
    }
    catch(e) {
        dispatch({
            type: TAGS_FETCHING,
            payload: false
        });

        dispatch({
            type: TAGS_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: TAGS_DATA,
        payload: data
    });

    dispatch({
        type: TAGS_FETCHING,
        payload: false
    });

    dispatch({
        type: TAGS_FETCHED,
        payload: true
    });
    
    return Promise.resolve(data);
}

export const api_getTagTypes = () => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: TAGTYPES_FETCHING,
            payload: true
        });

        data = (await axios.get(`${apiHost}/api/tagTypes`)).data;
    }
    catch(e) {
        dispatch({
            type: TAGTYPES_FETCHING,
            payload: false
        });

        dispatch({
            type: TAGTYPES_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: TAGTYPES_DATA,
        payload: data
    });

    dispatch({
        type: TAGTYPES_FETCHING,
        payload: false
    });

    dispatch({
        type: TAGTYPES_FETCHED,
        payload: true
    });
    
    return Promise.resolve(data);
}

export const api_saveTagTypeByCode = async (code, color, background, iColor, iText, iTextEn, iTextTr) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/tagTypes/${code}`;
        const data = (await axios.put(`${apiHost}${api}`, {background, color, iColor, iText, iTextEn, iTextTr}, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_addTag = async (tag) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/tags`;
        const data = (await axios.post(`${apiHost}${api}`, tag, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveTag = async (id, tag) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/tags/${id}`;
        const data = (await axios.put(`${apiHost}${api}`, tag, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_deleteTag = async (id) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/tags/${id}`;
        const data = (await axios.delete(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_getSearchTags = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/searchTags`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_addSearchTag = async (tag) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/searchTags`;
        const data = (await axios.post(`${apiHost}${api}`, tag, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveSearchTag = async (id, tag) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/searchTags/${id}`;
        const data = (await axios.put(`${apiHost}${api}`, tag, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_deleteSearchTag = async (id) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/searchTags/${id}`;
        const data = (await axios.delete(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}