import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import MainLayout from "../../layouts/MainLayout";
import img_ship from "../../assets/imgs/ship404.png";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import { Button } from "@mui/material";

const Error404Page = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const goToMain = () => {
        navigate(getLocalizeUrl(location, "/"));
    }

    return <div className="Error404Page">
        <MainLayout footer="no-form">
            <div className="content">
                <div className="blocks">
                    <div className="block">
                        <h1>{getLocalizeText("pages/notFound", "title", getLanguage(location))}</h1>
                        <div className="description">{getLocalizeText("pages/notFound", "description", getLanguage(location))}</div>
                        <div>
                            <Button variant="contained" className="btn" onClick={goToMain}>{getLocalizeText("pages/notFound", "return", getLanguage(location))}</Button>
                        </div>
                    </div>
                    <div className="img">
                        <img src={img_ship} alt="404" />
                    </div>
                </div>
            </div>
        </MainLayout>
    </div>
}

export default Error404Page;