import React from "react";
import "./style.scss";

const Card = ({ title, icon }) => {

    return (
        <div className="Card">
            <div className="CardImg">{icon}</div>
            <h3 className="CardTitle">{title}</h3>
        </div>
    );
};

export default Card;
