import React, { useState } from "react";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import "./style.scss";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import { useLocation } from "react-router-dom";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import MailFeedback from "../../../mails/MailFeedback";
import { api_mailSend } from "../../../redux/actions";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const sbj = "Обратная связь";

const FeedbackForm = (props) => {
    const location = useLocation();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperEmail, setHelperEmail] = useState("");
    const [helperName, setHelperName] = useState("");
    const [helperPhone, setHelperPhone] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeComment = (e) => {
        setComment(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!phone.trim().length) {
            ok = false;
            setHelperPhone(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrPhone(true);
        }
        else {
            setPhoneSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrPhone(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperPhone("");
        setHelperEmail("");
        setHelperRule("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','feedback');

            // Save to logs
            const reqLog = {
                type: "feedback",
                data: {
                    name,
                    email,
                    phone,
                    comment
                }
            };
            await api_addReqLog(reqLog);

            // Send to sea-logic
            const msg = getMailTemplate(<MailFeedback
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
            api_mailSend(sbj, msg, null, "html");

            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            setRule(false);
            resetErrors();
            
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Мы скоро с вами свяжемся.</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  

            return false;
        }
    }

    return <div className="FeedbackForm">
        <h3>{getLocalizeText("dialogs/main", "linkWithUs", getLanguage(location))}</h3>
        <div className="row grid-1-1">
            <div>
                <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                <div>
                    <TextField fullWidth required error={errName} type="text" helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} value={name} onChange={changeName} className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`} />
                </div>
            </div>
            <div>
                <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                <div>
                    <TextField fullWidth required error={errEmail} type="text" helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} value={email} onChange={changeEmail} className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`} />
                </div>
            </div>
        </div>

        <div className="row">
            <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}<div className="req">*</div></div>
                <div>
                    <TextField fullWidth required error={errPhone} type="text" helperText={helperPhone} placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}  value={phone} onChange={changePhone} className={`${errPhone ? "error" : ""} ${phoneSuccess ? "success" : ""}`} />
                </div>
        </div>

        <div className="row">
            <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
            <div>
                <TextField fullWidth multiline placeholder={getLocalizeText("dialogs/main", "placeholderComment", getLanguage(location))} value={comment} onChange={changeComment} className={`textarea ${commentSuccess ? "success" : ""}`} />
            </div>
        </div>

        <div className="row grid-1-1">
            <div className="rules">
                <FormControlLabel required error={errRule} helperText={helperRule} control={<Checkbox checked={rule} onChange={changeRule} />} label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)} className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`} />
            </div>
            <div className="button">
                <Button className="btn" variant="contained" onClick={onSend}>{getLocalizeText("dialogs/main", "linkToUs", getLanguage(location))}</Button>
            </div>
        </div>
    </div>
}

export default FeedbackForm;