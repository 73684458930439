export const ROUTES_SEARCH = "ROUTES_SEARCH";
export const ROUTES_CLEAR = "ROUTES_CLEAR";
export const ROUTES_FETCHING = "ROUTES_FETCHING";
export const ROUTES_FETCHED = "ROUTES_FETCHED";

export const COMPANIES_DATA = "COMPANIES_DATA";
export const COMPANIES_FETCHING = "COMPANIES_FETCHING";
export const COMPANIES_FETCHED = "COMPANIES_FETCHED";

export const PORTS_DATA = "PORTS_DATA";
export const PORTS_FETCHING = "PORTS_FETCHING";
export const PORTS_FETCHED = "PORTS_FETCHED";


export const CITIES_DATA = "CITIES_DATA";
export const CITIES_FETCHING = "CITIES_FETCHING";
export const CITIES_FETCHED = "CITIES_FETCHED";

export const SEARCH_INIT_POL = "SEARCH_INIT_POL";
export const SEARCH_INIT_POD = "SEARCH_INIT_POD";

export const SETTINGS_DATA = "SETTINGS_DATA";
export const SETTINGS_FETCHED = "SETTINGS_FETCHED";
export const SETTINGS_FETCHING = "SETTINGS_FETCHING";

export const TAGS_DATA = "TAGS_DATA";
export const TAGS_FETCHED = "TAGS_FETCHED";
export const TAGS_FETCHING = "TAGS_FETCHING";

export const TAGTYPES_DATA = "TAGTYPES_DATA";
export const TAGTYPES_FETCHED = "TAGTYPES_FETCHED";
export const TAGTYPES_FETCHING = "TAGTYPES_FETCHING";