import {combineReducers} from "redux";
import cityReducer from "./reducers/city";
import companyReducer from "./reducers/company";
import portReducer from "./reducers/port";
import routeReducer from "./reducers/route";
import searchReducer from "./reducers/search";
import settingsReducer from "./reducers/settings";
import tagReducer from "./reducers/tag";
import tagTypeReducer from "./reducers/tagType";

export default combineReducers({
    route: routeReducer,
    company: companyReducer,
    port: portReducer,
    city: cityReducer,
    search: searchReducer,
    settings: settingsReducer,
    tag: tagReducer,
    tagType: tagTypeReducer
});