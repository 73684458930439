import React, { useEffect, useState } from "react";
import "./style.scss";
import { ReactComponent as IcnRu } from "../../assets/icons/flags/ru.svg";
import { ReactComponent as IcnUs } from "../../assets/icons/flags/us.svg";
import { ReactComponent as IcnTr } from "../../assets/icons/flags/tr.svg";
import { getLanguage } from "../../misc/localization";
import {ReactComponent as KeyboardArrowUpIcon} from '../../assets/icons/arrowUp.svg';
import { MenuItem, Select } from "@mui/material";
import { useLocation } from "react-router-dom";

const languages = [
    {
        title: "Русский",
        img: <IcnRu className="flagIcon" />,
        code: "ru"
    },
    {
        title: "English",
        img: <IcnUs className="flagIcon" />,
        code: "en"
    },
    {
        title: "Türkçe",
        img: <IcnTr className="flagIcon" />,
        code: "tr"
    }
];

const LangSwitcher2 = () => {
    const location = useLocation();

    const [lang, setLang] = useState(getLanguage(location));
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setLang(getLanguage(location));
    }, [location]);

    const changeLang = (e) => {
        const lang = e.target.value;
        const currentLang = getLanguage(location);
        if (currentLang !== lang) {
            let path = location.pathname + location.search;
            if (currentLang !== "ru") {
                path = path.replace(`/${currentLang}`, "");
            }
            if (lang !== "ru") {
                path = `/${lang}${path}`;
            }
            window.location.href = path;
        }
    }

    return <div className="LangSwitcher2">
        <Select
            open={isOpen}
            className="select"
            size="small"
            value={lang}
            onChange={changeLang}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            MenuProps={{className:"LangSwitcher2-Items"}}
            IconComponent={() => <KeyboardArrowUpIcon className={`selectIcon ${isOpen ? "open" : ""}`} />}
        >
            {languages.map(x => <MenuItem key={x.code} value={x.code}>{x.img}{x.title}</MenuItem>)}
        </Select>
    </div>
}

export default LangSwitcher2;
