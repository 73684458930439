import axios from "axios";
import { getApiHost } from "../../misc/api";
import { SETTINGS_DATA, SETTINGS_FETCHED, SETTINGS_FETCHING } from "../types";

const apiHost = getApiHost();

export const api_getDomsContent = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/domContents`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_getDomContentByCode = async (code) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/domContents/code/${code}`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_createDomContent = async (code, title, value) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/domContents`;
        const data = (await axios.post(`${apiHost}${api}`, {code, title, value}, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveDomContentByCode = async (code, newCode, descriptions, value) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/domContents/code/${code}`;
        const data = (await axios.put(`${apiHost}${api}`, {
            code: newCode, 
            description: descriptions.description,
            descriptionEn: descriptions.descriptionEn, 
            descriptionTr: descriptions.descriptionTr, 
            value
        }, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_deleteDomContentByCode = async (code) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/domContents/code/${code}`;
        const data = (await axios.delete(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}