import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getUsers = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/user`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_createUser = async (data) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/user`;
        const r = (await axios.post(`${apiHost}${api}`, data, {headers})).data;
        return r;
    }
    catch(e) {
        return null;
    }
}

export const api_saveUser = async (id, data) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/user/${id}`;
        const r = (await axios.put(`${apiHost}${api}`, data, {headers})).data;
        return r;
    }
    catch(e) {
        return null;
    }
}

export const api_deleteUser = async (id) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/user/${id}`;
        const data = (await axios.delete(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}