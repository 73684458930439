/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { isValidEmail } from "../../../misc/mail";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';

const DialogRequestSubscription = (props) => {
    const location = useLocation();
    const [periods, setPeriods] = useState([]);
    const [period, setPeriod] = useState("");

    useEffect(() => {
         setPeriod(props.tariff.period);
         setPeriods(props.periods);
    }, [props.tariff.period]);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperEmail, setHelperEmail] = useState("");
    const [helperName, setHelperName] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const changeTariff = (event) => {
         setPeriod(event.target.value);
    };

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeComment = (e) => {
        setComment(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperEmail("");
        setHelperRule("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            handleClose();
            handleSuccess();

            return false;
        }
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setComment("");
        setRule(false);
    }

    const handleSuccess = () => {
        const data = {
            tariffId: props.tariff.id,
            tariffName: props.tariff.name,
            period,
            name,
            email,
            phone,
            comment,
        }
        if (typeof props.onSuccess === "function") props.onSuccess(data);
    }

    const handleClose = () => {
        resetErrors();
        resetForm();
        setPeriod(props.tariff.period);
        if (typeof props.onClose === "function")
            props.onClose();
    }


    return <Dialog
        className="dialog DialogRequestSubscription"
        open={props.open}
        onClose={handleClose}
        onSuccess={() => handleSuccess()}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/tariffs", "title", getLanguage(location))}
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <div className="DialogRequestSubscriptionForm form">
                <div className="row DialogRequestSubscriptionGrid">
                    <div>
                        <div className="label">{getLocalizeText("dialogs/tariffs", "tariff", getLanguage(location))}<div className="req">*</div></div>
                        <Select
                            value={period}
                            onChange={changeTariff}
                            className="DialogRequestSubscriptionSelect"
                            MenuProps={{className: "menuContainer"}}
                        >
                            {periods.map((item, idx) => (
                                <MenuItem key={idx} className="Dialog-request_menu" value={item}>
                                    {props.tariff.name} ({item} мес.)
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                        <div>
                            <TextField fullWidth required error={errName} type="text" helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} value={name} onChange={changeName} className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                    <div>
                        <TextField fullWidth required error={errEmail} type="text" helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} value={email} onChange={changeEmail} className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`} />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                    <div>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                            value={phone}
                            onChange={changePhone}
                            className={`${phoneSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            placeholder={getLocalizeText("dialogs/main", "placeholderWishes", getLanguage(location))}
                            value={comment} onChange={changeComment}
                            className={`DialogRequestSubscriptionTextarea textarea ${commentSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className="DialogRequestSubscriptionFooter row DialogRequestSubscriptionGrid">
                    <div className="rules">
                        <FormControlLabel
                            required
                            error={errRule}
                            helperText={helperRule}
                            control={<Checkbox checked={rule} onChange={changeRule} />}
                            label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)}
                            className={`DialogRequestSubscriptionLabel ${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`}
                        />
                    </div>
                    <Button className="btn" variant="contained" onClick={onSend}>
                        {getLocalizeText("dialogs/tariffs", "select", getLanguage(location))}
                    </Button>
                </div>
            </div>
        </DialogContent>
    </Dialog>
}

export default DialogRequestSubscription;
