import axios from "axios";

export const getApiHost = () => {
    let apiHost = "http://localhost:10000";
    try  {
        apiHost = process.env.REACT_APP_API_HOST;
    }
    catch(err) {
        console.log(err);
    }
    return apiHost;
}

export const getHost = () => {
    let apiHost = "http://localhost:3000";
    try  {
        apiHost = process.env.REACT_APP_HOST;
    }
    catch(err) {
        console.log(err);
    }
    return apiHost;
}

export const combinePortsAndCities = (allPorts, allCities) => {
    return allPorts.concat(allCities.map(x => {
        return {
            _id: x._id,
            country_en: x.country_en,
            country_ru: x.country_ru,
            POL_code: x.city_en,
            POL_en: x.city_en,
            POL_ru: x.city_ru
        };
    }));
}

export const getFreaightHost = () => {
    let host = "";
    try  {
        host = process.env.REACT_APP_FREIGHT;
    }
    catch(err) {
        console.log(err);
    }
    return host;
}

export const getUserIp = async () => {
    let ip = localStorage.getItem("ip");
    if (!ip) {
        try {
            const rIp = (await axios.get("https://api.seeip.org/jsonip", {
                timeout: 5000,
                withCredentials: false,
                headers: {
                    'Content-Type': 'text/plain'
                }
            })).data;
            ip = rIp.ip;
            localStorage.setItem("ip", ip);
        }
        catch(err){
            ip = "";
        }
    }
    return ip;
}