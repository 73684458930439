/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import {
    Button, Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api_mailSend } from "../../../redux/actions";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import MailRequestTariffToCustomer from "../../../mails/MailRequestTariffToCustomer";
import MailRequestTariffToCustomerEn from "../../../mails/MailRequestTariffToCustomerEn";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import MailRequestTariff from "../../../mails/MailRequestTariff";

const sbj = "Выбор плана подписки";

const DialogRequestTariff = (props) => {
    const location = useLocation();
    const [periods, setPeriods] = useState([]);
    const [period, setPeriod] = useState("");

    useEffect(() => {
         setPeriod(props.tariff.period);
         setPeriods(props.periods);
    }, [props.tariff.period]);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperEmail, setHelperEmail] = useState("");
    const [helperName, setHelperName] = useState("");
    const [helperPhone, setHelperPhone] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const changeTariff = (event) => {
         setPeriod(event.target.value);
    };

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeComment = (e) => {
        setComment(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!phone.trim().length) {
            ok = false;
            setHelperPhone(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrPhone(true);
        }
        else {
            setPhoneSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrPhone(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperPhone("");
        setHelperEmail("");
        setHelperRule("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','tariff');

            // Save to logs
            const reqLog = {
                type: "tariff",
                data: {
                    tariffId: props.tariff.id,
                    tariffName: props.tariff.name,
                    period: `${period} мес.`,
                    name,
                    email,
                    phone,
                    comment
                }
            };
            await api_addReqLog(reqLog);

            // Send to sea-logic
            const msg = getMailTemplate(<MailRequestTariff
                tariffId={props.tariff.id}
                tariffName={props.tariff.name}
                period={`${period} мес.`}
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
            api_mailSend(sbj, msg, null, "html");

            // Send to client
            let msgClient;
            if (getLanguage(location) === "ru")
                msgClient = getMailTemplate(<MailRequestTariffToCustomer />);
            else
                msgClient = getMailTemplate(<MailRequestTariffToCustomerEn />);

            api_mailSend(sbj, msgClient, email, "html");

            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            setRule(false);
            resetErrors();

            handleClose();
            handleSuccess();

            return false;
        }
    }

    const handleSuccess = () => {
        if (typeof props.onSuccess === "function") props.onSuccess();
    }

    const handleClose = () => {
        setPeriod(props.tariff.period);
        if (typeof props.onClose === "function")
            props.onClose();
    }


    return <Dialog
        className="dialog DialogRequestTariff"
        open={props.open}
        onClose={handleClose}
        onSuccess={() => handleSuccess()}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/tariffs", "title", getLanguage(location))}
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <div className="DialogRequestTariffForm form">
                <div className="row DialogRequestTariffGrid">
                    <div>
                        <div className="label">{getLocalizeText("dialogs/tariffs", "tariff", getLanguage(location))}<div className="req">*</div></div>
                        <Select
                            value={period}
                            onChange={changeTariff}
                            className="DialogRequestTariffSelect"
                            MenuProps={{className: "menuContainer"}}
                        >
                            {periods.map((item, idx) => (
                                <MenuItem key={idx} className="Dialog-request_menu" value={item}>
                                    {props.tariff.name} ({item} мес.)
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                        <div>
                            <TextField fullWidth required error={errName} type="text" helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} value={name} onChange={changeName} className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                    <div>
                        <TextField fullWidth required error={errEmail} type="text" helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} value={email} onChange={changeEmail} className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`} />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}<div className="req">*</div></div>
                    <div>
                        <TextField
                            fullWidth
                            required
                            error={errPhone}
                            type="text"
                            helperText={helperPhone}
                            placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                            value={phone}
                            onChange={changePhone}
                            className={`${errPhone ? "error" : ""} ${phoneSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                    <div>
                        <TextField
                            fullWidth
                            multiline
                            placeholder={getLocalizeText("dialogs/main", "placeholderWishes", getLanguage(location))}
                            value={comment} onChange={changeComment}
                            className={`DialogRequestTariffTextarea textarea ${commentSuccess ? "success" : ""}`}
                        />
                    </div>
                </div>

                <div className="DialogRequestTariffFooter row DialogRequestTariffGrid">
                    <div className="rules">
                        <FormControlLabel
                            required
                            error={errRule}
                            helperText={helperRule}
                            control={<Checkbox checked={rule} onChange={changeRule} />}
                            label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)}
                            className={`DialogRequestTariffLabel ${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`}
                        />
                    </div>
                    <Button className="btn" variant="contained" onClick={onSend}>
                        {getLocalizeText("dialogs/tariffs", "select", getLanguage(location))}
                    </Button>
                </div>
            </div>
        </DialogContent>
    </Dialog>
}

export default DialogRequestTariff;
