import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getSubscribeTariffs = async (locale) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/subscribePlan/country/${locale}`;
        return (await axios.get(`${apiHost}${api}`, {headers})).data;
    }
    catch(e) {
        return [];
    }
}
