import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";

const EditSettingDialog = (props) => {
    const [code, setCode] = useState("");
    const [title, setTitle] = useState("");
    const [value, setValue] = useState("");

    const [isCodeError, setIsCodeError] = useState(false);
    const [isTitleError, setIsTitleError] = useState(false);
    const [isValueError, setIsValueError] = useState(false);

    useEffect(() => {
        if (props.data) {
            setCode(props.data.code);
            setTitle(props.data.title);
            setValue(props.data.value)
        }
    }, [props.data]);

    const changeCode = (e) => {
        setCode(e.target.value);
    }

    const changeTitle = (e) => {
        setTitle(e.target.value);
    }

    const changeValue = (e) => {
        setValue(e.target.value);
    }

    const clear = () => {
        setTimeout(() => {
            setCode("");
            setTitle("");
            setValue("");
        }, 1000);
    }

    const save = () => {
        setIsCodeError(!code.length);
        setIsTitleError(!title.length);
        setIsValueError(!value.length);

        if (code.length && title.length && value.length) {
            props.onSave({code, title, value, oldCode: props.data ? props.data.code : null});
            clear();
        }
    }

    const close = () => {
        clear();
        props.onClose();
    }

    const getValueEditor = (code) => {
        switch (code) {
            case "showCarrierPageBanner":
            case "showContactsPageBanner":
            case "showSearchPageBanner":
            case "partners_showSite":
            case "subscribeDialog_active":
                return <FormControl fullWidth>
                    <InputLabel>{title}</InputLabel>
                    <Select
                        size="small"
                        value={value}
                        label={title}
                        onChange={changeValue}
                    >
                        <MenuItem value={"Y"}>Да</MenuItem>
                        <MenuItem value={"N"}>Нет</MenuItem>
                </Select>
            </FormControl>
            default:
                return <TextField size="small" required label="Value" fullWidth value={value} onChange={changeValue} error={isValueError} />
        }
    }

    return <Dialog className="EditSettingDialog" open={props.isOpen}>
        <DialogTitle>{props.data ? "Редактирование" : "Создание"} настройки</DialogTitle>
        <DialogContent>
            <div className="row">
                <TextField autoFocus size="small" required label="Код" fullWidth value={code} onChange={changeCode} error={isCodeError} />
            </div>
            <div className="row">
                <TextField size="small" required label="Наименование" fullWidth value={title} onChange={changeTitle} error={isTitleError} />
            </div>
            <div className="row">
                {getValueEditor(code, value)}
            </div>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={save}>Сохранить</Button>
            <Button onClick={close}>Отмена</Button>
        </DialogActions>
    </Dialog>
}

export default EditSettingDialog;