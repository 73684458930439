/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs, Button, ButtonGroup, Menu, MenuItem, Select, Typography, capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import MainLayout from "../../layouts/MainLayout";
import { getApiHost } from "../../misc/api";
import { api_getLineRateTable } from "../../redux/actions/lineRate";
import "./style.scss";
import icon_new from "../../assets/icons/new.svg";
import img_map from "../../assets/icons/world_map.svg";
import { useSelector } from "react-redux";
import pdf from "../../assets/pdf/lineRates.pdf";
import pdf2 from "../../assets/pdf/lineRates2.pdf";
import { useLocation, useNavigate } from "react-router-dom";
import {
    getLanguage,
    getLocalizeText,
    getLocalizeUrl,
    getRedirectFromLineRate,
} from "../../misc/localization";
import { Helmet } from "react-helmet";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";
import {ReactComponent as KeyboardArrowUpIcon} from '../../assets/icons/arrowUp.svg';
import {ReactComponent as IconShield} from "../../assets/icons/shieldBlue.svg";
import {ReactComponent as IconCloud} from "../../assets/icons/cloud.svg";
import { ReactComponent as IconShip } from "../../assets/icons/ship-outline.svg";
import { ReactComponent as IconTrending } from "../../assets/icons/trending-up.svg";
import { ReactComponent as IconCalendar } from "../../assets/icons/calendar2.svg";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";
import DialogRequestAdvancedRating from "../../components/dialogs/RequestAdvancedRating";

const LineRatePage = () => {
    const location = useLocation();

    const breadcrumbs = [
        <a key="1" href={getRedirectFromLineRate(location)}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</a>,
        <Typography className="current" key="2">{getLocalizeText("breadcrumbs/main", "lineRate", getLanguage(location))}</Typography>
    ];

    const companies = useSelector(state => state.company.data);

    const [isLoading, setIsLoading] = useState(false);
    const [rateTables, setRateTables] = useState([]);
    const [locations, setLocations] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [locationPeriods, setLocationPeriods] = useState([]);
    const [locationKey, setLocationKey] = useState("");
    const [periodKey, setPeriodKey] = useState("");
    const [activeLocation, setActiveLocation] = useState("");
    const [activePeriod, setActivePeriod] = useState("");
    const [isSortOpen, setIsSortOpen] = useState(false);
    const [isLocationOpen, setIsLocationOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [scrollY, setScrollY] = useState(0);

    const [openDialog, setOpenDialog] = useState(false);

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    let navigate = useNavigate();
    const successTitle = getLocalizeText("pages/lineRate", "success.title", getLanguage(location));
    const successText = getLocalizeText("pages/lineRate", "success.text", getLanguage(location));

    const onFormSuccess = () => {
        navigate(
            getLocalizeUrl(location, "/success"),
            {
                state: {
                    title: successTitle,
                    text: successText
                }
            }
        );
    }

    const isDownloadOpen = Boolean(anchorEl);

    useEffect(() => {
        getRateTables();

        const onScroll = () => {
            setScrollY(window.scrollY);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    useEffect(() => {
        const lang = getLanguage(location);
        setLocationKey(`location${lang === "ru" ? "" : capitalize(lang)}`);
        setPeriodKey(`period${lang === "ru" ? "" : capitalize(lang)}`);
    }, [location]);

    useEffect(() => {
        if (Array.isArray(rateTables) && rateTables.length) {
            const _locations = [];
            const _periods = [];
            for (const x of rateTables) {
                if (x[locationKey]?.trim().length && !_locations.some(y => y[locationKey] === x[locationKey]))
                    _locations.push(x);

                _periods.push(x);
            }
            setActiveLocation(_locations.length ? _locations[0][locationKey] : "");

            setLocations(_locations);
            setPeriods(_periods);
        }
    }, [rateTables]);

    useEffect(() => {
        const _periods = periods.filter(x => x[locationKey] === activeLocation);
        _periods.sort((a, b) => b.period.slice(0, 4) - a.period.slice(0, 4));
        setActivePeriod(_periods.length ? _periods[0][periodKey] : "");
        setLocationPeriods(_periods);
    }, [activeLocation]);

    useEffect(() => {
        if (isSortOpen)
            setIsSortOpen(false);
        if (isLocationOpen)
            setIsLocationOpen(false);
        if (isDownloadOpen)
            setAnchorEl(null);
      }, [scrollY]);

    const getRateTables = async () => {
        setIsLoading(true);
        setRateTables(await api_getLineRateTable());
        setIsLoading(false);
    }

    const changeActivePeriod = (e) => {
        setActivePeriod(e.target.value);
    }

    const openDownloadMenu = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeDownloadMenu = () => {
        setAnchorEl(null);
    }

    return <div className="LineRatePage">
        <MainLayout fixedHeader={true}>
            <Helmet>
                <title>{getLocalizeText("pages/lineRate", "meta.title", getLanguage(location))}</title>
                <meta name="description" content={getLocalizeText("pages/lineRate", "meta.description", getLanguage(location))} />
            </Helmet>
            <div className="content">
                <div className="pageContent">
                    <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                        {breadcrumbs}
                    </Breadcrumbs>

                    <h1>{getLocalizeText("pages/lineRate", "title", getLanguage(location))}</h1>

                    <section className="gridType1 blockSection1">
                        <div className="blockContainer1">
                            <h2 className="blockTitle">
                                {getLocalizeText("pages/lineRate", "subtitle1", getLanguage(location))}
                                <span className="brandColor">{getLocalizeText("pages/lineRate", "subtitle2", getLanguage(location))}</span>
                                {getLocalizeText("pages/lineRate", "subtitle3", getLanguage(location))}
                            </h2>
                            <div className="blockDescription">
                                <p className="text">
                                    {getLocalizeText("pages/lineRate", "description", getLanguage(location))}
                                </p>
                                <button className="linkBtn" onClick={() => setOpenDialog(true)}>
                                    {getLocalizeText("pages/lineRate", "getAccessLink", getLanguage(location))}
                                    <IconLinkBtnArrow className="icon"/>
                                </button>
                            </div>
                        </div>
                        <DialogRequestAdvancedRating open={openDialog} onClose={onCloseDialog} onSuccess={onFormSuccess} />

                        <ul className="cardList1">
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconCalendar />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/lineRate", "blocks1.1.title", getLanguage(location))}
                                </h3>
                            </li>
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconShip />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/lineRate", "blocks1.2.title", getLanguage(location))}
                                </h3>
                            </li>
                            <li className="cardListItem1">
                                <div className="cardListImg1">
                                    <IconTrending />
                                </div>
                                <h3 className="cardListTitle1">
                                    {getLocalizeText("pages/lineRate", "blocks1.3.title", getLanguage(location))}
                                </h3>
                            </li>
                        </ul>
                    </section>

                    <div className="locations">
                        <div>
                            <ButtonGroup size="small" className="buttonGroup">
                                {locations.map(x => <Button className={activeLocation === x[locationKey] ? "active" : ""} onClick={() => setActiveLocation(x[locationKey])}>{x[locationKey]}</Button>)}
                            </ButtonGroup>
                            <div className="locationsMobile select">
                                <div className="title">{getLocalizeText("pages/lineRate", "port", getLanguage(location))}</div>
                                <div>
                                    <Select
                                        open={isLocationOpen}
                                        size="small"
                                        value={activeLocation}
                                        onChange={(e) => setActiveLocation(e.target.value)}
                                        onOpen={() => setIsLocationOpen(true)}
                                        onClose={() => setIsLocationOpen(false)}
                                        MenuProps={{className:"LineRatePage-sortItems"}}
                                        IconComponent={() => <KeyboardArrowUpIcon className={`selectIcon ${isLocationOpen ? "open" : ""}`} />}
                                    >
                                        {locations.map(x => <MenuItem value={x[locationKey]}>{x[locationKey]}</MenuItem>)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="sort select">
                            <div className="title">{getLocalizeText("pages/lineRate", "sort", getLanguage(location))}</div>
                            <div>
                                <Select
                                    open={isSortOpen}
                                    size="small"
                                    value={activePeriod}
                                    onChange={changeActivePeriod}
                                    onOpen={() => setIsSortOpen(true)}
                                    onClose={() => setIsSortOpen(false)}
                                    MenuProps={{className:"LineRatePage-sortItems"}}
                                    IconComponent={() => <KeyboardArrowUpIcon className={`selectIcon ${isSortOpen ? "open" : ""}`} />}

                                >
                                    {locationPeriods.map(x => <MenuItem value={x[periodKey]}>{x[periodKey]}</MenuItem>)}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className="tableWrapper">
                        <LineRateTable data={locationPeriods.find(x => x[locationKey] === activeLocation && x[periodKey] === activePeriod) || null} companies={companies} />

                        <img className="map" src={img_map} alt="map" />
                    </div>
                    {
                    activePeriod.includes("2024")
                        ? null
                        : <div className="description">
                            <IconShield />
                            {getLanguage(location) !== "tr"
                                ? <div>
                                    {getLocalizeText("pages/lineRate", "footer", getLanguage(location))} <a href="https://infranews.ru" target="_blank" rel="noreferrer">{getLocalizeText("pages/lineRate", "infraProjects", getLanguage(location))}</a>
                                </div>
                                : <div>
                                    {getLocalizeText("pages/lineRate", "footer1", getLanguage(location))} <a href="https://infranews.ru" target="_blank" rel="noreferrer">{getLocalizeText("pages/lineRate", "infraProjects", getLanguage(location))}</a> {getLocalizeText("pages/lineRate", "footer2", getLanguage(location))}
                                </div>
                            }
                        </div>
                    }

                    {getLanguage(location) === "ru"
                        ? <>
                            <div className="download" onClick={openDownloadMenu}>
                                {getLocalizeText("pages/lineRate", "link", getLanguage(location))} <IconCloud />
                            </div>
                            <Menu
                                open={isDownloadOpen}
                                onClose={closeDownloadMenu}
                                anchorEl={anchorEl}
                                className="LineRatePage-download"
                            >
                                <MenuItem onClick={closeDownloadMenu}>
                                    <a target="_blank" href={pdf} className="download" rel="noreferrer">Отчет за 2023 (I кв)</a>
                                </MenuItem>
                                <MenuItem onClick={closeDownloadMenu}>
                                    <a target="_blank" href={pdf2} className="download" rel="noreferrer">Отчет за 2023 (II кв)</a>
                                </MenuItem>
                            </Menu>
                        </>
                        : null
                    }
                </div>
            </div>

            <Loader active={isLoading} />
        </MainLayout>
    </div>
}

const LineRateTable = (props) => {
    const location = useLocation();

    const getWidth = (lines, value) => {
        const max = lines[0].value;
        return Math.round(value * 100 / max);
    }

    return <div className="LineRateTable">
         <div className="rateTableLine header">
            <div className="title">№</div>
            <div className="title">{getLocalizeText("pages/lineRate", "company", getLanguage(location))}</div>
            <div className="title">
                <div className="notMobile">
                    {getLocalizeText("pages/lineRate", "percents", getLanguage(location))}
                </div>
                <div className="mobile">
                    {getLocalizeText("pages/lineRate", "percentsMobile", getLanguage(location))}
                </div>
            </div>
            <div className="title dataValueTitle">{getLocalizeText("pages/lineRate", "value", getLanguage(location))}</div>
        </div>
        {props.data?.lines.map(y => <div>
            <div className="rateTableLine">
                <div>{y.position}</div>
                <div className="company">
                    <div className="logo">
                        <img src={`${getApiHost()}/static/${props.companies?.find(x => x.company === y.line)?.code || "undefined"}.png`} alt="company_logo" />
                    </div>
                </div>
                <div className="percent">
                    <div className="percentValue">{y.percent} %</div>
                    {y.changedPlace === "new"
                        ? <div className={`arrow`}><img src={icon_new} alt="new" /></div>
                        : <div className={`arrow ${y.changedPlace === 0 ? "hide" : ""} ${y.changedPlace > 0 ? "green" : "red"}`}>{y.changedPlace !== 0 ? (y.changedPlace > 0 ? "+" : "") + y.changedPlace : ""}{y.changedPlace > 0 ? "↑" : (y.changedPlace < 0 ? "↓" : "")}</div>
                    }
                    <div className="dataValueMobile">{y.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                </div>
                <div className="dataValue">
                    <div className="value">{y.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</div>
                    <div className="line">
                        <div className="bg" style={{width: getWidth(props.data.lines, y.value) + "%"}} />
                    </div>
                </div>
            </div>
            <div className="dataBarMobile">
                <div className="bg" style={{width: getWidth(props.data.lines, y.value) + "%"}} />
            </div>
        </div>)}
    </div>
}

export default LineRatePage;
