import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import { api_getReqLogs } from "../../../../../redux/actions/reqLog";

const TabCarriers = (props) => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        {
            field: "reqType",
            headerName: "Тип",
            width: 140,
            renderCell: (props) => {
                return <div x-data-id={props.row.reqType}>Сотрудничество</div>
            }
        },
        {
            field: "name",
            headerName: "Имя",
            flex: 1
        },
        {
            field: "email",
            headerName: "Емэйл",
            flex: 1
        },
        {
            field: "phone",
            headerName: "Телефон",
            flex: 1
        },
        {
            field: "comment",
            headerName: "Комментарий",
            flex: 1,
            renderCell: (props) => {
                return <div title={props.row.comment}>{props.row.comment}</div>
            }
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return <div className="datetime">
                    <div>{moment(params.row.createdAt).format("DD.MM.YYYY")}</div>
                    <div>{moment(params.row.createdAt).format("HH:mm:ss")}</div>
                </div>;
            },
            sortComparator: (v1, v2) => {
                if (moment(v1).isAfter(moment(v2)))
                    return 1;
                if (moment(v1).isBefore(moment(v2)))
                    return -1;
                return 0
            }
        },
    ];

    const allPorts = useSelector(state => state.port.data);

    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.active)
            init();
    }, [props.active]);

    useEffect(() => {
        setCsvData([columns.map(x => x.headerName)].concat(data.map(x => {
            return [
                x.id,
                x.reqType,
                x.name,
                x.email,
                x.phone,
                x.comment,
                moment(x.createdAt).format("DD.MM.YYYY HH:mm:ss")
            ];
        })));
    }, [data]);

    const init = async () => {
        setIsLoading(true);
        setData((await api_getReqLogs()).filter(x => x.type === "carrier").map(x => {
            x.id = x._id;
            x.reqType = x.type;
            for (const key in x.data) {
                x[key] = x.data[key];
            }
            return x;
        }));
        setIsLoading(false);
    }

    return <div className={`TabCarriers ${props.active ? "" : "hide"}`}>
        <div className="buttons">
            <CSVLink data={csvData} filename={`carrier-request-logs_${moment().format("YYYY-MM-DD_HH-mm-ss")}`}><Button className="btn" variant="contained">Выгрузить</Button></CSVLink>
        </div>
        <DataGrid
            className="table"
            rows={data}
            columns={columns}
            rowHeight={80}
            rowsPerPageOptions={[20, 50, 100]}
        />
        <Loader active={isLoading} />
    </div>
}

export default TabCarriers;