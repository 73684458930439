import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_getSearchTags } from "../../redux/actions/tag";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { getLocalizeSearchTagName } from "../../misc/localization";

const SearchTags = (props) => {
    const location = useLocation();

    const allPorts = useSelector(state => state.port.data);

    const [tags, setTags] = useState([]);

    useEffect(() => {
        getSearchTags();
    }, []);

    const getSearchTags = async () => {
        setTags((await api_getSearchTags()).filter(x => x.active));
    }

    const click = (tag) => {
        if (typeof props.onClick === "function") {
            const pol = allPorts.find(x => x.POL_en === tag.POL);
            const pod = allPorts.find(x => x.POL_en === tag.POD);
            if (pol && pod)
                props.onClick({label: pol.POL_ru}, {label: pod.POL_ru});
        }
    }

    return <div className="SearchTags">
        {tags.map(x => <div key={x._id} className="searchTag" onClick={() => click(x)}>{getLocalizeSearchTagName(x, location)}</div>)}
    </div>
}

export default SearchTags;