import React, { useRef, useState } from "react";
import "./style.scss";
import { ReactComponent as IconChevronD } from "../../assets/icons/chevron-down.svg";
const ExpansionPanelItem = ({ question, answer, isExpanded, onClick }) => {
    const contentHeight = useRef();
    return (
        <div className={`expansionPanel ${isExpanded ? "expanded" : ""}`}>
            <button className="expansionPanelHeader" aria-expanded={isExpanded} onClick={onClick}>
                <h4>{question}</h4>
                <div className="expansionPanelExpandIcon"><IconChevronD/></div>
            </button>
            <div
                ref={contentHeight}
                className="expansionPanelContent"
                style={
                    isExpanded
                        ? { height: contentHeight.current.scrollHeight }
                        : { height: "0px" }
                }
            >
                {answer.map((item, idx) => (
                    <p key={idx}>{item}</p>
                ))}
            </div>
        </div>
    );
};

const ExpansionPanel = ({ data }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="expansionPanelWrapper">
            {data.map((item, idx) => (
                <ExpansionPanelItem
                    key={idx}
                    question={item.title}
                    answer={item.description}
                    isExpanded={activeIndex === idx}
                    onClick={() => handleItemClick(idx)}
                />
            ))}
        </div>
    );
};

export default ExpansionPanel;
