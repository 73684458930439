/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { api_mailSend } from "../../../redux/actions";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from "moment";
import InputMask from "react-input-mask";
import {ReactComponent as SvgBulk} from "../../../assets/icons/bulk.svg";
import {ReactComponent as SvgFcl} from "../../../assets/icons/fcl.svg";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import MailRequest from "../../../mails/MailRequest";
import MailToCustomer from "../../../mails/MailToCustomer";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../../misc/localization";
import MailToCustomerEn from "../../../mails/MailToCustomerEn";
import CloseIcon from '@mui/icons-material/Close';
import MailToCustomerTr from "../../../mails/MailToCustomerTr";

const DialogRequestFree = (props) => {
    const location = useLocation();

    const [sbj, setSbj] = useState("Оформление заявки");
    const [type, setType] = useState("Не выбрано");
    const [count, setCount] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState(true);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperEmail, setHelperEmail] = useState("");

    const [POL, setPol] = useState("");
    const [POD, setPod] = useState("");

    useEffect(() => {
        setDisabled(!name.length || !email.length);
        setErrEmail(errEmail && !email.length);
        if (email.length && helperEmail.length)
            setHelperEmail("");
    }, [name, email]);

    const handleClose = () => {
        resetForm();
        resetErrors();

        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    
    const onChangePOL = (event) => {
        setPol(event.target.value);
    }

    const onChangePOD = (event) => {
        setPod(event.target.value);
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangePhone= (event) => {
        setPhone(event.target.value);
    }

    const onChangeComment = (event) => {
        setComment(event.target.value);
    }

    const onTypeChange = (event) => {
        setType(event.target.value);
    };

    const onCountChange = (event) => {
        setCount(event.target.value);
    };

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);

        setHelperName("");
        setHelperEmail("");
    }

    const onSend = async () => {
        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','send_request');
            
            handleClose();

            const code = "00";
            const number = `${code}-${moment().format("YYYYMMDD")}-${Math.round(Math.random() * 1000)}`;

            const _pol = POL.trim() !== "" ? POL.trim() : "- Не задан -";
            const _pod = POD.trim() !== "" ? POD.trim() : "- Не задан -";
            

            // Save to logs
            const reqLog = {
                type: "request",
                data: {
                    number,
                    POL: _pol,
                    POD: _pod,
                    type,
                    count,
                    name,
                    email,
                    phone,
                    comment
                }
            };

            api_addReqLog(reqLog);

            const msg = getMailTemplate(<MailRequest
                number={number}
                route={`${_pol} - ${_pod}`}
                type={type}
                count={count}
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);

            const fullUrl = localStorage.getItem("fullUrl");
            if (fullUrl) {
                const fullUrl = localStorage.getItem("fullUrl");
                const url = localStorage.getItem("url");
                const source = localStorage.getItem("utm_source");
                const medium = localStorage.getItem("utm_medium");
                const campaign = localStorage.getItem("utm_campaign");
                const content = localStorage.getItem("utm_content");
                const term = localStorage.getItem("utm_term");

                msg.utm = {
                    fullUrl,
                    url,
                    source,
                    medium,
                    campaign,
                    content,
                    term
                }
            }
            
            api_mailSend(sbj, msg, null, "html");

            // Send to client
            const sbjClient = getLocalizeText("dialogs/request", "sbj", getLanguage(location));
            let msgClient;
            if (getLanguage(location) === "ru")
                msgClient = getMailTemplate(<MailToCustomer />);
            else if (getLanguage(location) === "tr")
                msgClient = getMailTemplate(<MailToCustomerTr />);
            else
                msgClient = getMailTemplate(<MailToCustomerEn />);

            api_mailSend(sbjClient, msgClient, email, "html");

            setType("Не выбрано");
            setCount(1);
            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            setPol("");
            setPod("")

            resetErrors();
            
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  {getLocalizeText("dialogs/main", "weWillMailYou", getLanguage(location))}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  

            resetForm();
        }
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setComment("");
    }


    return <Dialog
        className="dialog DialogRequestFree"
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/request", "title", getLanguage(location))}
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="route">
                    <div className="routeContent">
                        <TextField label={getLocalizeText("components/searchBar", "from", getLanguage(location))} className="full" value={POL} onChange={onChangePOL} autoComplete="do-not-autofill" />
                        <div>&nbsp;</div>
                        <TextField label={getLocalizeText("components/searchBar", "to", getLanguage(location))} className="full" value={POD} onChange={onChangePOD} autoComplete="do-not-autofill" />
                    </div>

                    <div className="grid_1-1">
                        <FormControl fullWidth className="Dialog-request_select">
                            <div className="label">{getLocalizeText("dialogs/main", "containerType", getLanguage(location))}</div>
                            <Select
                                labelId="label-type"
                                value={type}
                                onChange={onTypeChange}
                            >
                                <MenuItem className="Dialog-request_menu" value={"Не выбрано"}>{getLocalizeText("dialogs/main", "notChoosen", getLanguage(location))}</MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"Разные варианты"}>{getLocalizeText("dialogs/main", "allVariants", getLanguage(location))}</MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"BULK"}>
                                    <SvgBulk className="iconType" />BULK
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"20'"}>
                                    <SvgFcl className="iconType" />20'
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"40'"}>
                                    <SvgFcl className="iconType" />40'
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "containersCount", getLanguage(location))}</div>
                            <InputMask
                                fullWidth
                                className="full"
                                mask="99999999999999999999"
                                maskChar=""
                                value={count}
                                onChange={onCountChange}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}*</div>
                        <div>
                            <TextField required error={errName} helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} className={`full ${nameSuccess ? "success" : ""}`} value={name} onChange={onChangeName} autoComplete="do-not-autofill" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}*</div>
                        <div>
                            <TextField required error={errEmail} helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} className={`full ${emailSuccess ? "success" : ""}`} value={email} onChange={onChangeEmail} autoComplete="do-not-autofill" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                        <div>
                            <InputMask
                                placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                                className={`full ${phoneSuccess ? "success" : ""}`}
                                mask="99999999999999999999"
                                maskChar=""
                                value={phone}
                                onChange={onChangePhone}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                        <div>
                            <TextField placeholder={getLocalizeText("dialogs/main", "commentPlaceholder", getLanguage(location))} className={`full textarea ${commentSuccess ? "success" : ""}`} InputLabelProps={{ shrink: true }} multiline value={comment} onChange={onChangeComment} />
                        </div>
                    </div>
                    <Button className="btn" onClick={onSend} disabled={disabled}>{getLocalizeText("dialogs/main", "send", getLanguage(location))}</Button>

                    <div className="formFooter">
                        {getLocalizeText("dialogs/main", "privacyPolicyNote", getLanguage(location))} {getLocalizeText("dialogs/main", "with", getLanguage(location))} <Link to={getLocalizeUrl(location, "/privacyPolicy")} target="_blank">{getLocalizeText("dialogs/main", "privacyPolicy", getLanguage(location))}</Link>
                    </div>                    
                </div>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}

export default DialogRequestFree;