import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { api_getCompaniesPartners } from "../../redux/actions";
import { getCompanyLogoPath } from "../../misc/file";
import "./style.scss";
import { api_getSettingByCode } from "../../redux/actions/settings";

const replayCount = 10;
const k = 5;
let isStartedLeftAnimation = false;
let isStartedRightAnimation = false;

const Partners = () => {
    const [partners, setPartners] = useState([]);
    const [showSite, setShowSite] = useState(false);

    const ref_left = useRef(null);
    const ref_right = useRef(null);

    useEffect(() => {
        getPartners();
        getSettings();

        
        const resizeObserverLeft = new ResizeObserver(() => {
            if (ref_left.current)
                ref_left.current.style.top = `-${ref_left.current.clientHeight - 630}px`;
        });

        const resizeObserverRight = new ResizeObserver(() => {
            if (ref_right.current)
                ref_right.current.style.bottom = `-${ref_right.current.clientHeight - 630}px`;
        });

        resizeObserverLeft.observe(ref_left.current);
        resizeObserverRight.observe(ref_right.current);
    }, []);

    useEffect(() => {
        if (partners.filter(x => !x.partnerCarouselPosition || x.partnerCarouselPosition === "left").length)
            startLeftAnimate(partners.filter(x => !x.partnerCarouselPosition || x.partnerCarouselPosition === "left").length);
        
        if (partners.filter(x => x.partnerCarouselPosition === "right").length)
            startRightAnimate(partners.filter(x => x.partnerCarouselPosition === "right").length);
    }, [partners]);

    const getPartners = async () => {
        setPartners(await api_getCompaniesPartners());
    }

    const getSettings = async () => {
        const setting_showSite = await api_getSettingByCode("partners_showSite");
        if (setting_showSite)
            setShowSite(setting_showSite.value === "Y");
    }

    const startLeftAnimate = (count) => {
        if (!count || isStartedLeftAnimation) return;
        isStartedLeftAnimation = true;
        const time = count * replayCount * k;
        ref_left.current.classList.add("animate");
        ref_left.current.style.top = `-${ref_left.current.clientHeight - 630}px`;
        ref_left.current.style.transitionDuration = `${time}s`;
        setTimeout(() => {
            resetLeftAnimation(count);
        }, time * 1000);
    }

    const resetLeftAnimation = (count) => {
        isStartedLeftAnimation = false;
        ref_left.current.style.transitionDuration = `0s`;
        ref_left.current.classList.remove("animate");
        ref_left.current.style.top = `0px`;
        startLeftAnimate(count);
    }

    const startRightAnimate = (count) => {
        if (!count || isStartedRightAnimation) return;
        isStartedRightAnimation = true;
        const time = count * replayCount * k;
        ref_right.current.classList.add("animate");
        ref_right.current.style.bottom = `-${ref_right.current.clientHeight - 630}px`;
        ref_right.current.style.transitionDuration = `${time}s`;
        setTimeout(() => {
            resetRightAnimation(count);
        }, time * 1000);
    }

    const resetRightAnimation = (count) => {
        isStartedRightAnimation = false;
        ref_right.current.style.transitionDuration = `0s`;
        ref_right.current.classList.remove("animate");
        ref_right.current.style.bottom = `0px`;
        startRightAnimate(count);
    }

    return <div className="Partners">
        <div ref={ref_left} className="left block">
            {([...Array(replayCount)]).map(a => partners.filter(x => !x.partnerCarouselPosition || x.partnerCarouselPosition === "left").map(x => <div className="partner">
                <div className="partnerLogo">
                    <a href={x.site} target="_blank">
                        <img src={getCompanyLogoPath(x)} alt={x.code}/>
                    </a>
                </div>
                {showSite
                    ? <div className="partnerSite">
                            <a href={x.site} target="_blank">{x.siteTitle || x.site}</a>
                        </div>
                    : null
                }
            </div>))}
        </div>

        <div ref={ref_right} className="right block">
            {([...Array(replayCount)]).map(a => partners.filter(x => x.partnerCarouselPosition === "right").map(x => <div className="partner">
                <div className="partnerLogo">
                    <a href={x.site} target="_blank">
                        <img src={getCompanyLogoPath(x)} alt={x.code}/>
                    </a>
                </div>
                {showSite
                    ? <div className="partnerSite">
                            <a href={x.site} target="_blank">{x.siteTitle || x.site}</a>
                        </div>
                    : null
                }
            </div>))}
        </div>
        
    </div>
}

export default Partners;