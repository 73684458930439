import {
    SEARCH_INIT_POL,
    SEARCH_INIT_POD,
} from "../types";

const initState = {
    initPOL: [],
    initPOD:[]
};

const searchReducer = (state = initState, action) => {
    const {type, payload} = action;

    switch(type) {
        case SEARCH_INIT_POL: 
            state.initPOL = payload
            return {...state};
        case SEARCH_INIT_POD: 
            state.initPOD = payload
            return {...state};
        
        default:
            return state;
    }
}

export default searchReducer;