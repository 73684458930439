import { Link } from "react-router-dom";
import pdf_ppRu from "../assets/pdf/privacyPolicy.pdf";
import pdf_ppEn from "../assets/pdf/privacyPolicyEn.pdf";
import pdf_ppTr from "../assets/pdf/privacyPolicyTr.pdf";

export const noAutocomplete = (selector) => {
    const doms = document.querySelectorAll(selector);
    for (const dom of doms)
        dom.setAttribute("autocomplete", "off");
}

export const getAnimationNumberDelay = (value, animationTime) => {
    if (animationTime / value > 10) {
        return {
            delay: Math.round(animationTime / value),
            added: 1
        };
    }
    else {
        const parts = animationTime / 10;
        const added = Math.ceil(value / parts);
        return {
            delay: 10,
            added
        };
    }
}

export const noAutofill = () => {
    return `no-autofill_${Math.random()}_${Math.random()}`;
}

export const getRuleCheckboxLabel = (getLocalizeText, getLanguage, location) => {
    let pdf = null;
    const lang = getLanguage(location);
    switch (lang) {
        case "ru":
            pdf = pdf_ppRu;
            break;
        case "en":
            pdf = pdf_ppEn;
            break;
        case "tr":
            pdf = pdf_ppTr;
            break;
        default:
            pdf = pdf_ppRu;
            break;
    }

    return <div className="ruleLabel">
        {getLocalizeText("dialogs/main", "iAgree", getLanguage(location))} <a target="_blank" href={pdf}>{getLocalizeText("dialogs/main", "political", getLanguage(location))}</a> {getLocalizeText("dialogs/main", "and", getLanguage(location))} <a target="_blank" href={pdf}>{getLocalizeText("dialogs/main", "dataProcess", getLanguage(location))}</a> {getLocalizeText("dialogs/main", "and", getLanguage(location))} <a target="_blank" href={pdf}>{getLocalizeText("dialogs/main", "userAgreement", getLanguage(location))}</a>
    </div>
}

export const isMobile = window.innerWidth <= 395;
export const isTablet = window.innerWidth < 1440 && window.innerWidth >= 768;
export const isDesktop = window.innerWidth >= 1440;

export const getHEXColorWithAlpha = (color) => {
    const alpha = color.rgb.a;
    return `${color.hex}${Math.round(alpha * 255).toString(16)}`;
}