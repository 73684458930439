import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getBanners = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/banners`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_addBanner = async (banner) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.post(`${apiHost}/api/banners`, banner, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_deleteBanner = async (id) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.delete(`${apiHost}/api/banners/${id}`, {headers})).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_editBanner = async (id, banner) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.put(`${apiHost}/api/banners/${id}`, banner, {headers})).data;
        return data;
    }
    catch(e) {
        return false;
    }
}