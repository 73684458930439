import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_getPortsOnlyByQuery } from "../../../../../redux/actions";
import img_an_b from "../../../../../assets/imgs/anchor_b.svg";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import dayjs from "dayjs";
import { api_addRoute, api_saveRoute } from "../../../../../redux/actions/routes";
import { api_addTag, api_saveTag } from "../../../../../redux/actions/tag";

const AdminRouteEditDialog = (props) => {
    const allPorts = useSelector(state => state.port.data);
    const allTags = useSelector(state => state.tag.data);

    const [type, setType] = useState("direct");
    const [line, setLine] = useState("");
    const [vessel, setVessel] = useState("");
    const [voyage, setVoyage] = useState("");
    const [pol, setPOL] = useState("");
    const [optionsPOL, setOptionsPOL] = useState([]);
    const [loadingPOL, setLoadingPOL] = useState(false);
    const [pod, setPOD] = useState("");
    const [optionsPOD, setOptionsPOD] = useState([]);
    const [loadingPOD, setLoadingPOD] = useState(false);
    const [etd, setEtd] = useState("");
    const [eta, setEta] = useState("");
    const [transport1, setTransport1] = useState("");
    const [transport2, setTransport2] = useState("");
    const [loadingTL, setLoadingTL] = useState(false);
    const [transshipmentLocation, settransshipmentLocation] = useState("");
    const [optionsTL, setOptionsTL] = useState([]);
    const [priority, setPriority] = useState("");
    const [rate1, setRate1] = useState("");
    const [rate2, setRate2] = useState("");
    const [rate3, setRate3] = useState("");
    const [rate4, setRate4] = useState("");
    const [allowToBooking, setAllowToBooking] = useState(false);
    const [cutOffDate, setCutOffDate] = useState("");
    
    const [errLine, setErrLine] = useState(false);
    const [errVessel, setErrVessel] = useState(false);
    const [errVoyage, setErrVoyage] = useState(false);

    const [hLine, setHLine] = useState("");
    const [hVessel, setHVessel] = useState("");
    const [hVoyage, setHVoyage] = useState("");

    useEffect(() => {
        setOptionsPOL(allPorts.filter((x, i) => i <= 10));
        setOptionsPOD(allPorts.filter((x, i) => i <= 10));
        setOptionsTL(allPorts.filter((x, i) => i <= 10));
    }, [allPorts]);

    useEffect(() => {
        if (optionsPOL.length && typeof optionsPOL[0]._id != "undefined")
            setOptionsPOL(optionsPOL.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: x.POL_ru,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            }));
    }, [optionsPOL]);

    useEffect(() => {
        if (optionsPOD.length && typeof optionsPOD[0]._id != "undefined")
            setOptionsPOD(optionsPOD.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: x.POL_ru,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            }));
    }, [optionsPOD]);

    useEffect(() => {
        if (optionsTL.length && typeof optionsTL[0]._id != "undefined")
            setOptionsTL(optionsTL.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: x.POL_ru,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            }));
    }, [optionsTL]);

    useEffect(() => {
        if (props.item) {
            setType(props.item.type || "direct");
            setLine(props.item.line);
            setVessel(props.item.vessel);
            setVoyage(props.item.voyage);
            setPOL(allPorts.find(x => x.POL_en === props.item.POL) ? [allPorts.find(x => x.POL_en === props.item.POL)].map(x => {
                return {
                    id: x._id,
                    label: x.POL_ru,
                    value: x.POL_en,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            })[0] : null);
            setPOD(allPorts.find(x => x.POL_en === props.item.POD) ? [allPorts.find(x => x.POL_en === props.item.POD)].map(x => {
                return {
                    id: x._id,
                    label: x.POL_ru,
                    value: x.POL_en,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            })[0] : null);            
            setEtd(dayjs(props.item.ETD));
            setEta(dayjs(props.item.ETA));
            setTransport1(props.item.transport1);
            setTransport2(props.item.transport2);
            settransshipmentLocation(allPorts.find(x => x.POL_en === props.item.transshipmentLocation) ? [allPorts.find(x => x.POL_en === props.item.transshipmentLocation)].map(x => {
                return {
                    id: x._id,
                    label: x.POL_ru,
                    value: x.POL_en,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            })[0] : null);
            setPriority(props.item.priority);

            setRate1(allTags.find(x => props.item.tags && props.item.tags.length >= 1 && x._id === props.item.tags[0])?.value || "");
            setRate2(allTags.find(x => props.item.tags && props.item.tags.length >= 2 && x._id === props.item.tags[1])?.value || "");
            setRate3(allTags.find(x => props.item.tags && props.item.tags.length >= 3 && x._id === props.item.tags[2])?.value || "");
            setRate4(allTags.find(x => props.item.tags && props.item.tags.length >= 4 && x._id === props.item.tags[3])?.value || "");
            setAllowToBooking(props.item.allowToBooking || false);
            setCutOffDate(dayjs(props.item.cutOffDate) || "");
        }
    }, [props.item]);

    const resetForm = () => {
        setTimeout(() => {
            clearError();

            setType("");
            setVessel("");
        }, 300);
    }

    const clearError = () => {
        setErrLine(false);
        setHLine("");

        setErrVessel(false);
        setHVessel("")
    }

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
        resetForm();
    }

    const onSave = async () => {
        // TAGS
        const tags = [];
        if (rate1.trim() !== "") {
            if (props.item && Array.isArray(props.item.tags) && typeof props.item.tags[0] === "string" && props.item.tags[0].trim() !== "") {
                const tag = allTags.find(x => x._id === props.item.tags[0]);
                if (tag) {
                    tag.value = rate1.trim();
                    const r = await api_saveTag(tag._id, tag);
                    tags.push(r && r._id ? r._id : "");
                }
            }
            else {
                const r = await api_addTag({
                    relatedTo: "route",
                    type: "rate1",
                    value: rate1.trim()
                });

                tags.push(r && r._id ? r._id : "");
            }
        }
        else {
            tags.push("");
        }

        if (rate2.trim() !== "") {
            if (props.item && Array.isArray(props.item.tags) && typeof props.item.tags[1] === "string" && props.item.tags[1].trim() !== "") {
                const tag = allTags.find(x => x._id === props.item.tags[1]);
                if (tag) {
                    tag.value = rate2.trim();
                    const r = await api_saveTag(tag._id, tag);
                    tags.push(r && r._id ? r._id : "");
                }
            }
            else {
                const r = await api_addTag({
                    relatedTo: "route",
                    type: "rate2",
                    value: rate2.trim()
                });

                tags.push(r && r._id ? r._id : "");
            }
        }
        else {
            tags.push("");
        }

        if (rate3.trim() !== "") {
            if (props.item && Array.isArray(props.item.tags) && typeof props.item.tags[2] === "string" && props.item.tags[2].trim() !== "") {
                const tag = allTags.find(x => x._id === props.item.tags[2]);
                if (tag) {
                    tag.value = rate3.trim();
                    const r = await api_saveTag(tag._id, tag);
                    tags.push(r && r._id ? r._id : "");
                }
            }
            else {
                const r = await api_addTag({
                    relatedTo: "route",
                    type: "rate3",
                    value: rate3.trim()
                });

                tags.push(r && r._id ? r._id : "");
            }
        }
        else {
            tags.push("");
        }

        if (rate4.trim() !== "") {
            if (props.item && Array.isArray(props.item.tags) && typeof props.item.tags[3] === "string" && props.item.tags[3].trim() !== "") {
                const tag = allTags.find(x => x._id === props.item.tags[3]);
                if (tag) {
                    tag.value = rate4.trim();
                    const r = await api_saveTag(tag._id, tag);
                    tags.push(r && r._id ? r._id : "");
                }
            }
            else {
                const r = await api_addTag({
                    relatedTo: "route",
                    type: "rate4",
                    value: rate4.trim()
                });

                tags.push(r && r._id ? r._id : "");
            }
        }
        else {
            tags.push("");
        }
        const data = {
            vessel,
            voyage,
            type,
            line,
            POL: pol.value,
            POD: pod.value,
            ETD: etd.toISOString(),
            ETA: eta.toISOString(),
            transport1,
            transport2,
            transshipmentLocation: transshipmentLocation ? transshipmentLocation.value : "",
            tags,
            priority: parseInt(priority) > 0 ? parseInt(priority) : 0,
            allowToBooking,
            cutOffDate: cutOffDate.isValid() ? cutOffDate.toISOString() : undefined,
        };

        let r = null;
        if (props.item?._id) {
            r = await api_saveRoute(props.item._id, data);
        }
        else {
            r = await api_addRoute(data);
        }

        if (r) {

            if (typeof props.onSave === "function")
                props.onSave();
                onClose();
        }
        else {

        }
    }

    const changeType = (e) => {
        setType(e.target.value);
    }
    
    const changeLine = (e) => {
        setLine(e.target.value);
    }

    const changeVessel = (e) => {
        setVessel(e.target.value);
    }

    const changeVoyage = (e) => {
        setVoyage(e.target.value);
    }

    const onChangePOL = (event, newValue) => {
        setPOL(newValue);
    }

    const onChangePOD = (event, newValue) => {
        setPOD(newValue);
    }

    const changetransshipmentLocation = (event, newValue) => {
        settransshipmentLocation(newValue);
    }

    const changeEtd = (newValue) => {
        setEtd(newValue);
    };

    const changeEta = (newValue) => {
        if (etd && moment(newValue.toDate()).isAfter(moment(etd.toDate())))
            setEta(newValue);
    };

    const changeTransport1 = (e) => {
        setTransport1(e.target.value);
    }

    const changeTransport2 = (e) => {
        setTransport2(e.target.value);
    }

    const changePriority = (e) => {
        setPriority(e.target.value);
    }

    const changeRate1 = (e) => {
        setRate1(e.target.value);
    }

    const changeRate2 = (e) => {
        setRate2(e.target.value);
    }
    
    const changeRate3 = (e) => {
        setRate3(e.target.value);
    }

    const changeRate4 = (e) => {
        setRate4(e.target.value);
    }

    const changeAllowToBooking = (e) => {
        setAllowToBooking(e.target.checked);
    }

    const changeCutOffDate = (newValue) => {
        setCutOffDate(newValue);
    };

    return <Dialog className="AdminRouteEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="row">
                        <FormControl fullWidth size="small">
                            <InputLabel>Тип</InputLabel>
                            <Select
                                required
                                size="small"
                                value={type}
                                label="Тип"
                                onChange={changeType}
                            >
                            <MenuItem value="direct">Direct</MenuItem>
                            <MenuItem value="transshipment">Transshipment</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="row">
                        <TextField required fullWidth size="small" label="Линия" variant="outlined" value={line} onChange={changeLine} error={errLine} helperText={hLine} />
                    </div>

                    <div className="row">
                        <TextField required fullWidth size="small" label="Судно" variant="outlined" value={vessel} onChange={changeVessel} error={errVessel} helperText={hVessel} />
                    </div>

                    <div className="row">
                        <TextField required fullWidth size="small" label="Рейс" variant="outlined" value={voyage} onChange={changeVoyage} error={errVoyage} helperText={hVoyage} />
                    </div>

                    <div className="row">
                        <Autocomplete
                            required
                            size="small"
                            disablePortal
                            value={pol}
                            onChange={onChangePOL}
                            options={optionsPOL}
                            renderInput={(params) => <>
                                <TextField {...params} 
                                    label="Отправка" 
                                    onChange={async (e) => {
                                        if (e.target.value.trim().length) {
                                            setLoadingPOL(true);
                                            const r = await api_getPortsOnlyByQuery(e.target.value);
                                            setLoadingPOL(false);
                                            setOptionsPOL(r);
                                        }
                                        else {
                                            setOptionsPOL(allPorts.filter((x, i) => i <= 10));
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={img_an_b} alt="anchor"/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                            {loadingPOL ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </>}
                            renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                                <div className="label">{option.label}</div>
                                <div className="description">{option.description}</div>
                            </div>}
                            filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}
                        />
                    </div>

                    <div className="row">
                        <Autocomplete
                            required
                            size="small"
                            disablePortal
                            value={pod}
                            onChange={onChangePOD}
                            options={optionsPOD}
                            renderInput={(params) => <>
                                <TextField {...params} 
                                    label="Прибытие" 
                                    onChange={async (e) => {
                                        if (e.target.value.trim().length) {
                                            setLoadingPOD(true);
                                            const r = await api_getPortsOnlyByQuery(e.target.value);
                                            setLoadingPOD(false);
                                            setOptionsPOD(r);
                                        }
                                        else {
                                            setOptionsPOD(allPorts.filter((x, i) => i <= 10));
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={img_an_b} alt="anchor"/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                            {loadingPOD ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </>}
                            renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                                <div className="label">{option.label}</div>
                                <div className="description">{option.description}</div>
                            </div>}
                            filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}
                        />
                    </div>

                    <div className="row grid-1-1" style={{gap: "20px"}}>
                        <DesktopDatePicker
                            label="Дата отправки"
                            inputFormat="DD.MM.YYYY"
                            value={etd}
                            maxDate={eta}
                            onChange={changeEtd}
                            renderInput={(params) => <TextField required size="small" {...params} />}
                        />

                        <DesktopDatePicker
                            label="Дата прибытия"
                            inputFormat="DD.MM.YYYY"
                            value={eta}
                            minDate={etd}
                            onChange={changeEta}
                            renderInput={(params) => <TextField required size="small" {...params} />}
                        />
                    </div>

                    <div className={`row transshipment ${type === "direct" ? "hide" : ""}`}>
                        <FormControl fullWidth size="small">
                            <InputLabel>Транспорт 1</InputLabel>
                            <Select
                                required
                                size="small"
                                value={transport1}
                                label="Транспорт 1"
                                onChange={changeTransport1}
                            >
                            <MenuItem value="sea">Морской</MenuItem>
                            <MenuItem value="rail">Железнодорожный</MenuItem>
                            </Select>
                        </FormControl>

                        <Autocomplete
                            required
                            size="small"
                            disablePortal
                            value={transshipmentLocation}
                            onChange={changetransshipmentLocation}
                            options={optionsTL}
                            renderInput={(params) => <>
                                <TextField {...params} 
                                    label="Перегрузка" 
                                    onChange={async (e) => {
                                        if (e.target.value.trim().length) {
                                            setLoadingTL(true);
                                            const r = await api_getPortsOnlyByQuery(e.target.value);
                                            setLoadingTL(false);
                                            setOptionsTL(r);
                                        }
                                        else {
                                            setOptionsTL(allPorts.filter((x, i) => i <= 10));
                                        }
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={img_an_b} alt="anchor"/>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                            {loadingTL ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </>}
                            renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                                <div className="label">{option.label}</div>
                                <div className="description">{option.description}</div>
                            </div>}
                            filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}
                        />

                        <FormControl fullWidth size="small">
                            <InputLabel>Транспорт 2</InputLabel>
                            <Select
                                required
                                size="small"
                                value={transport2}
                                label="Транспорт 2"
                                onChange={changeTransport2}
                            >
                            <MenuItem value="sea">Морской</MenuItem>
                            <MenuItem value="rail">Железнодорожный</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="row">
                        <TextField fullWidth size="small" label="Приоритет" variant="outlined" value={priority} onChange={changePriority}/>
                    </div>

                    <div className="row tags">
                        <TextField fullWidth size="small" label="40' SOC" variant="outlined" value={rate1} onChange={changeRate1}/>
                        <TextField fullWidth size="small" label="40' COC" variant="outlined" value={rate2} onChange={changeRate2}/>
                        <TextField fullWidth size="small" label="20' SOC" variant="outlined" value={rate3} onChange={changeRate3}/>
                        <TextField fullWidth size="small" label="20' COC" variant="outlined" value={rate4} onChange={changeRate4}/>                    
                    </div>

                    <div className="row">
                        <FormControlLabel control={<Checkbox checked={allowToBooking} onChange={changeAllowToBooking} />} label="Доступен к букингу" />
                    </div>

                    <div className="row">
                        <DesktopDatePicker
                            className="cutOffDate"
                            label="Дата cut off"
                            inputFormat="DD.MM.YYYY"
                            value={cutOffDate}
                            onChange={changeCutOffDate}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </div>

                </LocalizationProvider>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminRouteEditDialog;