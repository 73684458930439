/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";
import img_an from "../../assets/icons/anchor.svg";
import img_arrow from "../../assets/icons/switch.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { api_getPortsByQuery, api_searchRoutes } from "../../redux/actions";
import { Store as notification } from 'react-notifications-component';
import { Error } from "@mui/icons-material";
import lodash from "lodash";
import { isCorrectDate } from "../../misc/date";
import { combinePortsAndCities } from "../../misc/api";
import { getLanguage, getLocalizePort, getLocalizePortDescription, getLocalizeText } from "../../misc/localization";
import { useLocation } from "react-router-dom";

const SearchBar = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [POL, setPOL] = useState(null);
    const [POD, setPOD] = useState(null);
    const [accent, setAccent] = useState(false);
    const [ports, setPorts] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(true);
    
    const initPOL = useSelector(state => state.search.initPOL);
    const initPOD = useSelector(state => state.search.initPOD);

    const [optionsPOL, setOptionsPOL] = useState(initPOL);
    const [optionsPOD, setOptionsPOD] = useState(initPOD);

    const allPorts = useSelector(state => state.port.data);
    const allCities = useSelector(state => state.city.data);

    const portsFetched = useSelector(state => state.port.fetched);
    const citiesFetched = useSelector(state => state.city.fetched);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setOptionsPOL(initPOL);
    }, [initPOL]);

    useEffect(() => {
        setOptionsPOD(initPOD);
    }, [initPOD]);

    useEffect(() => {
        if (optionsPOL.length && typeof optionsPOL[0]._id != "undefined")
            setOptionsPOL(optionsPOL.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: getLocalizePort(x, getLanguage(location)),
                    description: getLocalizePortDescription(x, getLanguage(location)),
                }
            }));
    }, [optionsPOL]);

    useEffect(() => {
        if (optionsPOD.length && typeof optionsPOD[0]._id != "undefined")
            setOptionsPOD(optionsPOD.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: getLocalizePort(x, getLanguage(location)),
                    description: getLocalizePortDescription(x, getLanguage(location)),
                }
            }));
    }, [optionsPOD]);

    useEffect(() => {
        if (portsFetched && citiesFetched) {
            const all = combinePortsAndCities(allPorts, allCities);
            setPorts(all.sort((a, b) => {
                if (a.POL_en > b.POL_en)
                    return 1;
                if (a.POL_en < b.POL_en)
                    return -1;
                return 0;

            }).map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: getLocalizePort(x, getLanguage(location)),
                    description: getLocalizePortDescription(x, getLanguage(location)),
                }
            }));
        }
    }, [portsFetched, citiesFetched]);

    useEffect(() => {
        if (typeof accent === "boolean")
            setAccent(accent);
    }, [props.accent]);

    useEffect(() => {
        fill();
    }, [props.data, ports]);

    useEffect(() => {
        setBtnDisabled(POL === null || POD === null);
    }, [POL, POD]);

    const fill = () => {
        let pol = null;
        let pod = null;

        if (props.data && typeof props.data.POL === "object" && props.data.POL && !POL) {
            pol = ports.find(x => x.value === props.data.POL.POL_en);
            if (pol) {
                setPOL(pol);
            }
            else
                pol = null;
        }

        if (props.data && typeof props.data.POD === "object" && props.data.POD && !POD && ports.length) {
            pod = ports.find(x => x.value === props.data.POD.POL_en);
            if (pod) {
                setPOD(pod);
            }
            else
                pod = null;
        }

        if (pol !== null && pod !== null && !(typeof props.noSearchButton === "boolean" && props.noSearchButton)) {
            onSearch(pol, pod);
        }
    }

    const onChangePOL = (event, newValue) => {
        setPOL(newValue);
    }

    const onChangePOD = (event, newValue) => {
        setPOD(newValue);
    }

    const onSearch = async (prePol = null, prePod = null) => {
        let _pol = POL;
        let _pod = POD;
        let _date = moment().toDate();
        if (prePol && prePod ) {
            _pol = prePol;
            _pod = prePod;
        }
        if (!isCorrectDate(_date)) {
            try {
                const realDate = document.querySelector("input[x-data-component='SearchBar']").value;
                if (realDate === "")
                    _date = moment().toDate();
            }
            catch(err) {

            }
        }

        if (_pol && _pod) {
            if (typeof props.onSearch === "function") {
                props.onSearch(_pol, _pod);
                if (typeof props.noSearch === "boolean" && props.noSearch)
                    return;
            }
                
            setIsLoading(true);
            try {
                await dispatch(api_searchRoutes(_pol.value, _pod.value, moment(_date).format("YYYY-MM-DD")));
            }
            catch (err) {
                console.log(err);
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> {getLocalizeText("components/searchBar", "error1", getLanguage(location))}</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });  
            }
            
            setIsLoading(false);
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackWarningText"> {getLocalizeText("components/searchBar", "error2", getLanguage(location))}</span></div>,
                type: "warning",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  
        }
    }

    const onSwitch = () => {
        if (props.disabled) return false;
        const pod = lodash.cloneDeep(POD);
        const pol = lodash.cloneDeep(POL);
        setPOD(pol);
        setPOL(pod);
    }

    return <div className={`SearchBar${typeof props.noSearchButton === "boolean" && props.noSearchButton ? " noSearchButton" : ""}`}>

        <div className="from">
            <Autocomplete
                disabled={props.disabled || false}
                disableClearable={typeof props.canClear === "boolean" && !props.canClear ? true : false}
                x-data-component="SearchBar"
                className="noBorders"
                value={POL}
                onChange={onChangePOL}
                disablePortal
                options={optionsPOL}
                loading={false}
                loadingText=""
                noOptionsText=""
                renderInput={(params) => <>
                    <TextField {...params} 
                        placeholder={getLocalizeText("components/searchBar", "from", getLanguage(location))}
                        onChange={async (e) => {
                            if (e.target.value.trim().length) {
                                const r = await api_getPortsByQuery(e.target.value);
                                setOptionsPOL(r);
                            }
                            else {
                                setOptionsPOL(initPOL);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            name: "from",
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={img_an} alt="anchor" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                        }}
                    />
                </>}
                renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                    <div className="label">{option.label}</div>
                    <div className="description">{option.description}</div>
                </div>}
                filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}

            />
        </div>
        <div className={`switchContainer${props.disabled ? " disabled" : ""}`}>
            <img src={img_arrow} alt="arrow" className="switch" onClick={onSwitch} />
        </div>
        <div className="to">
            <Autocomplete
                disabled={props.disabled || false}
                disableClearable={typeof props.canClear === "boolean" && !props.canClear ? true : false}
                x-data-component="SearchBar"
                className="noBorders"
                disablePortal
                value={POD}
                onChange={onChangePOD}
                options={optionsPOD}
                loading={false}
                loadingText=""
                noOptionsText=""
                renderInput={(params) => <>
                    <TextField {...params} 
                        placeholder={getLocalizeText("components/searchBar", "to", getLanguage(location))}
                        onChange={async (e) => {
                            if (e.target.value.trim().length) {
                                const r = await api_getPortsByQuery(e.target.value);
                                setOptionsPOD(r);
                            }
                            else {
                                setOptionsPOD(initPOD);
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            name: "to",
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img src={img_an} alt="anchor" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <React.Fragment>
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                        }}
                    />
                </>}
                renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                    <div className="label">{option.label}</div>
                    <div className="description">{option.description}</div>
                </div>}
                filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}

            />
        </div>
        
        <div className={`buttonContainer${typeof props.noSearchButton === "boolean" && props.noSearchButton ? " hide" : ""}`}>
            <Button className="btn" variant="contained" onClick={onSearch} disabled={btnDisabled}>{getLocalizeText("components/searchBar", "find", getLanguage(location))}</Button>
        </div>
        <div className={`accentBorder${!accent ? " hide" : ""}`}></div>
    </div>
}

export default SearchBar;