import moment from "moment"

export const getDaysCount = (dateFrom, dateTo) => {
    const diff = moment(dateTo).diff(moment(dateFrom), "days");
    return diff === 0 ? 1 : diff;
}

export const daysTitle = (count, lang = "ru") => {
    if (lang === "ru") {
        if (count % 10 === 0 || count % 10 >= 5 || (count > 10 && count < 20))
            return "дней";
        if (count % 10 === 1)
            return "день";
        if (count % 10 >= 2)
            return "дня";
    }
    if (lang === "en") {
        return count === 1 ? "day" : "days";
    }
    if (lang === "tr") {
        return "gün";
    }
}

export const isCorrectDate = (date) => {
    if (Object.prototype.toString.call(date) === "[object Date]") {
        if (isNaN(date))
            return false;
        else
            return true;
    }
    return false
}

export const tryToModifyYear = (dateString) => {
    const arr = dateString.split(".");
    if (typeof arr[2] === "string" && arr[2].length === 2)
        return `${arr[0]}.${arr[1]}.20${arr[2]}`;
    return dateString;
}

export const beforeThanToday = (date) => {
    const today = moment().format("YYYY-MM-DDT00:00:00") + "Z";
    return moment(date).isSameOrBefore(moment(today));
}