import l_mainPage from "../data/localization/ru/pages/main.json";

export const getLanguage = (location) => {
    if (location.pathname.indexOf("/en/") === 0) {
        return "en";
    }
    else if (location.pathname.indexOf("/tr/") === 0) {
        return "tr";
    }
    return "ru"
}

export const getLocalizeText = (path, code, lang = "ru") => {
    let text = code;
    try {
        const jsonFile = require(`../data/localization/${lang}/${path}.json`);
        text = getObjProperty(jsonFile, code);
    }
    catch(e) {
        console.log(e);
        console.log(`Can't find localization for language = "${lang}", path = "${path}", path = "${code}"`);
    }
    return text;
}

export const getObjProperty = (obj, path) => {
    const segments = path.split('.');
    const segments_len = segments.length;
    let currently_at = obj;
    for(let idx = 0; idx < segments_len; idx ++) {
       currently_at = currently_at[segments[idx]];
    }
    return currently_at;
}

export const getLocalizePort = (port, lang) => {
    if (typeof port === "string") return port;
    switch(lang) {
        case "en":
            return port.POL_en;
        case "tr":
            return port.POL_en;
        default:
            return port.POL_ru;
    }
}

export const getLocalizePortAndCode = (port, lang) => {
    if (typeof port === "string") return port;
    switch(lang) {
        case "en":
            return port.POL_en + `, ${port.POL_code}`;
        case "tr":
            return port.POL_en + `, ${port.POL_code}`;
        default:
            return port.POL_ru + `, ${port.POL_code}`;
    }
}

export const getLocalizePortDescription = (port, lang) => {
    switch(lang) {
        case "en":
            return `${port.country_en}, ${port.POL_code}`;
        case "tr":
            return `${port.country_en}, ${port.POL_code}`;
        default:
            return `${port.country_ru}, ${port.POL_code}`;
    }
}

export const getLocalizeUrl = (location, url) => {
    const curLang = getLanguage(location);
    return curLang === "ru" ? url : `/${curLang}${url}`;
}

export const getRedirectFromLineRate = (location, prefix = "") => {
    const curLang = getLanguage(location);
    const protocol = window.location.protocol
    const host = prefix + window.location.host.toLowerCase().replace("top.", "");
    return curLang === "ru"
        ? `${protocol}//${host}`
        : `${protocol}//${host}/${curLang}/`;
}

export const getLocalizeDomContent = (domContent, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return domContent.descriptionEn || "";
        case "tr":
            return domContent.descriptionTr || "";
        default:
            return domContent.description || "";
    }
}

export const getLocalizeSearchTagName = (tag, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return tag.nameEn || "<unset name>";
        case "tr":
            return tag.nameTr || "<unset name>";
        default:
            return tag.name;
    }
}

export const getLocalizeCompanyName = (company, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "ru":
            return typeof company.companyRu === "string" ? company.companyRu : "<unset name>";
        default:
            return company.company;
    }
}

export const getLocalizeLineRatePeriod = (tab, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return typeof tab.periodEn === "string" ? tab.periodEn : "<unset name>";
        case "tr":
            return typeof tab.periodTr === "string" ? tab.periodTr : "<unset name>";
        default:
            return tab.period;
    }
}

export const getLocalizeLineRateLocation = (tab, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return typeof tab.locationEn === "string" ? tab.locationEn : "<unset name>";
        case "tr":
            return typeof tab.locationTr === "string" ? tab.locationTr : "<unset name>";
        default:
            return tab.location;
    }
}

export const getLocalizeSliderSetting = (name, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return `${name}_en`;
        case "tr":
            return `${name}_tr`;
        default:
            return `${name}`;
    }
}

export const getLocalizeTagInfo = (tag, location) => {
    const curLang = getLanguage(location);
    switch(curLang) {
        case "en":
            return tag.iTextEn || "";
        case "tr":
            return tag.iTextTr || "";
        default:
            return tag.iText || "";
    }
}
