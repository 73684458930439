import {
    SETTINGS_DATA,
    SETTINGS_FETCHED,
    SETTINGS_FETCHING
} from "../types";

const initState = {
    fetching: false,
    fetched: false,
    data: []
};

const settingsReducer = (state = initState, action) => {
    const {type, payload} = action;

    switch(type) {
        case SETTINGS_DATA: 
            state.data = payload
            return {...state};
        case SETTINGS_FETCHING: 
            state.fetching = payload
            return {...state};
        case SETTINGS_FETCHED: 
            state.fetched = payload
            return {...state};

        default:
            return state;
    }
}

export default settingsReducer;