import React, { useEffect, useState } from "react";
import {ReactComponent as IconInfo} from "../../../assets/icons/info.svg";
import { useSelector } from "react-redux";
import InfoIcon from "../../InfoIcon";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeTagInfo, getLocalizeText } from "../../../misc/localization";

const RouteServices = (props) => {
    const location = useLocation();

    const allTags = useSelector(state => state.tag.data);
    const tagTypes = useSelector(state => state.tagType.data);

    const [tags, setTags] = useState([]);

    useEffect(() => {
        if (props.company) {
            getTags(props.company);
        }
    }, [props.company, allTags, tagTypes]);

    const getTags = (company) => {
        const _tags = [];
        const rand = Math.random().toFixed(8);

        for (const tagId of company.tags) {
            const i = Math.random().toFixed(4);
            const tag = allTags.find(x => x._id === tagId);
            if (tag) {
                const tagType = tagTypes.find(x => x.code.toLowerCase() === tag.type.toLowerCase());
                if (tagType)
                    _tags.push(<div key={`company${company._id}-tagtype_${tagType._id}_${i}_${rand}`} className={`block ${tag.value.toLowerCase()}`} style={{backgroundColor: tagType.background, color: tagType.color}}>
                                {tag.value} 
                                <Tooltip key={`tooltip_company${company._id}-tagtype_${tagType._id}_${i}`} title={getLocalizeTagInfo(tagType, location)} className="iContainer" placement="top" arrow>
                                    <div><InfoIcon key={`icon_company${company._id}-tagtype_${tagType._id}_${i}`} color={tagType.iColor || "#000"} /></div>
                                </Tooltip>
                            </div>);
            }
        }
        setTags(_tags);
    }

    return <>
        {tags.length
            ? <div className="RouteService">
                <div className="title">
                    {getLocalizeText("components/services", "title", getLanguage(location))}
                </div>
                <div className="blocks">
                    {tags}
                </div>
            </div>
            : null
        }
    </>
}

export default RouteServices;