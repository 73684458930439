import { Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { api_mailSend } from "../../../redux/actions";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InputMask from "react-input-mask";
import {ReactComponent as SvgBulk} from "../../../assets/icons/bulk.svg";
import {ReactComponent as SvgFcl} from "../../../assets/icons/fcl.svg";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import MailMoreInformaton from "../../../mails/MailMoreInformaton";
import MailToCustomer from "../../../mails/MailToCustomer";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../../misc/localization";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import MailToCustomerTr from "../../../mails/MailToCustomerTr";
import MailToCustomerEn from "../../../mails/MailToCustomerEn";

const sbj = "Узнать подробности";

const GetMeMoreInformation = (props) => {
    const location = useLocation();

    const [type, setType] = useState("Не выбран");
    const [count, setCount] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRoute, setErrRoute] = useState("");
    const [errRule, setErrRule] = useState(false);

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperEmail, setHelperEmail] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const handleClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
            
        resetForm();
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangePhone= (event) => {
        setPhone(event.target.value);
    }

    const onChangeComment = (event) => {
        setComment(event.target.value);
    }

    const onTypeChange = (event) => {
        setType(event.target.value);
    };

    const onCountChange = (event) => {
        setCount(event.target.value);
    };

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);
        
        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperEmail("");
        setErrRoute("");
        setHelperRule("");
    }

    const resetForm = () => {
        setType("Не выбран");
        setCount(1);
        setName("");
        setEmail("");
        setPhone("");
        setComment("");
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const onSend = async () => {
        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','more information');
            handleClose();

            // Send to sea-logic
            const msg = getMailTemplate(<MailMoreInformaton
                banner={props.banner?.name}
                type={type}
                count={count}
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
            api_mailSend(sbj, msg, null, "html");

            // Send to client
            const sbjClient = "SeaLogic - ваша заявка принята в работу";
            let msgClient;
            if (getLanguage(location) === "ru")
                msgClient = getMailTemplate(<MailToCustomer />);
            else if (getLanguage(location) === "tr")
                msgClient = getMailTemplate(<MailToCustomerTr />);
            else
                msgClient = getMailTemplate(<MailToCustomerEn />);

            api_mailSend(sbjClient, msgClient, email, "html");
            
            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            resetErrors();
            
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Мы скоро с вами свяжемся.</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  
        }
    }

    return <Dialog
        className="dialog GetMeMoreInformation"
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/getMoreInfo", "title", getLanguage(location))}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="form">
                <div className="grid_1-1">
                        <FormControl fullWidth className="Dialog-request_select">
                            <div className="label">{getLocalizeText("dialogs/main", "containerType", getLanguage(location))}<div className="req">*</div></div>
                            <Select
                                value={type}
                                onChange={onTypeChange}
                                MenuProps={{className: "menuContainer"}}
                            >
                                <MenuItem className="Dialog-request_menu" value={"Не выбран"}>{getLocalizeText("dialogs/main", "notChoosen", getLanguage(location))}</MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"BULK"}>
                                    <SvgBulk className="iconType" />BULK
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"20'"}>
                                    <SvgFcl className="iconType" />20'
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"40'"}>
                                    <SvgFcl className="iconType" />40'
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"Разные варианты"}>{getLocalizeText("dialogs/main", "allVariants", getLanguage(location))}</MenuItem>
                            </Select>
                        </FormControl>

                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "containersCount", getLanguage(location))}<div className="req">*</div></div>
                            <InputMask
                                fullWidth
                                className="full"
                                mask="99999999999999999999"
                                maskChar=""
                                value={count}
                                onChange={onCountChange}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>

                    <div className="row grid_1-1">
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                            <div>
                                <TextField required error={errName} helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} className={`full ${nameSuccess ? "success" : ""}`} value={name} onChange={onChangeName} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                            <div>
                                <TextField required error={errEmail} helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} className={`full ${emailSuccess ? "success" : ""}`} value={email} onChange={onChangeEmail} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                        <div>
                            <InputMask
                                placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                                className={`full ${phoneSuccess ? "success" : ""}`}
                                mask="99999999999999999999"
                                maskChar=""
                                value={phone}
                                onChange={onChangePhone}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                        <div>
                            <TextField placeholder={getLocalizeText("dialogs/main", "commentPlaceholder", getLanguage(location))} className={`full textarea ${commentSuccess ? "success" : ""}`} InputLabelProps={{ shrink: true }} multiline value={comment} onChange={onChangeComment} />
                        </div>
                    </div>
                    <div className="row grid_1-1">
                        <div className="rules">
                            <FormControlLabel required error={errRule} helperText={helperRule} control={<Checkbox checked={rule} onChange={changeRule} />} label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)} className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`} />
                        </div>
                        <div className="button">
                            <Button fullWidth className="btn" variant="contained" onClick={onSend}>
                                {props.routeDisabled 
                                    ? getLocalizeText("dialogs/main", "send", getLanguage(location))
                                    : getLocalizeText("dialogs/main", "send2", getLanguage(location))
                                }
                            </Button>
                        </div>
                    </div>                
                </div>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}

export default GetMeMoreInformation;