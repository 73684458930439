import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getFreightValidTill = async (polEn, polRu, podEn, podRu) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/freight/validTillDate?polEn=${polEn}&polRu=${polRu}&podEn=${podEn}&podRu=${podRu}`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}