import React, { memo } from "react";
import { renderToString } from "react-dom/server";
import { getHost } from "../../misc/api";

const MailCarrier = memo((props) => {

    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <div style={{display: "flex", flexDirection: "row", gap: "0px", padding: "27px 60px", borderBottom: "1px solid #E2E8F0", height: "80px", boxSizing: "border-box"}}>
            <div style={{width: "30px", marginRight: "8px"}}>
                <a style={{textDecoration: "none !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    <img style={{width: "100%"}} src={`${getHost()}/images/logo.png`} alt="logo" />
                </a>
            </div>
            <div style={{fontFamily: "Arial", fontWeight: "700", fontSize: "30px", lineHeight: "14px", marginTop: "4px"}}>
                <a style={{textDecoration: "none !important", color: "#000000 !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    sealogic
                </a>
            </div>
        </div>

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: "Arial", textTransform: "uppercase", fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "center", marginBottom: "66px"}}>
                Стать партнером:
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Имя: <b>{props.name}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                e-mail: <b>{props.email}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Телефон: <b>{props.phone}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Комментарий: <br/><b style={{marginLeft: "20px", display: "block"}} dangerouslySetInnerHTML={{__html: props.comment?.replace(/\n/, renderToString(<br />))}} />
            </div>
            
            <div style={{marginTop: "44px", fontFamily: "Arial", fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
                *Данное письмо сформировано автоматически. Отвечать на него не нужно.
            </div>
        </div>
        <div style={{backgroundColor: "#E9F3FD", height: "75px"}}>&nbsp;</div>
    </div>
});

export default MailCarrier;