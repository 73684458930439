import "./style.scss";
import React from "react";
import { ReactComponent as ShipFront } from "../../assets/icons/ship-port-front.svg";
import { getLanguage, getLocalizeText, getRedirectFromLineRate } from "../../misc/localization";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import MainLayout from "../../layouts/MainLayout";

const SuccessPage = () => {
    const location = useLocation();

    return <div className="SuccessPage">
        <Helmet>
            <title>{getLocalizeText("pages/analytics", "meta.title", getLanguage(location))}</title>
            <meta name="description" content={getLocalizeText("pages/analytics", "meta.description", getLanguage(location))} />
        </Helmet>

        <MainLayout fixedHeader={true} footer="no-form">
            <div className="pageContent">
                <div className="img">
                    <ShipFront/>
                </div>
                <article>
                    <h1 className="SuccessBlockTitle">
                        {
                            location?.state?.title
                            || getLocalizeText("pages/success", "title", getLanguage(location))
                        }
                    </h1>
                    <p className="SuccessBlockText">
                        {
                            location?.state?.text
                            || getLocalizeText("pages/success", "text", getLanguage(location))
                        }
                    </p>
                    <Button className="SuccessBlockBtn btn" variant="contained" href={getRedirectFromLineRate(location)}>
                        {getLocalizeText("pages/success", "return", getLanguage(location))}
                    </Button>
                </article>
            </div>
        </MainLayout>
    </div>
}

export default SuccessPage
