import React, { useLayoutEffect, useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import { api_createSetting, api_deleteSettingByCode, api_getSettings, api_saveSettingByCode } from "../../../redux/actions/settings";
import Loader from "../../../components/Loader";
import { Button } from "@mui/material";
import "./style.scss";
import EditSettingDialog from "../../../components/dialogs/admin/EditSettingDialog";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Error from "@mui/icons-material/Error";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../../components/dialogs/Confirm";

const AdminSettingsPage = () => {
    const [settings, setSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);

    useLayoutEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        setIsLoading(true);
        const r = await api_getSettings();
        setSettings(r);
        setIsLoading(false);
    }

    const openDialog = () => {
        setIsOpenDialog(true);
    }

    const closeDialog = () => {
        setSelectedItem(null);
        setIsOpenDialog(false);
    }

    const edit = (item) => {
        setSelectedItem(item);
        openDialog();
    }

    const confirmDelete = (item) => {
        setIsOpenConfirm(true);
        setSelectedItem(item);
    }

    const closeConfirm = () => {
        setSelectedItem(null);
        setIsOpenConfirm(false);
    }

    const remove = async () => {
        const r = await api_deleteSettingByCode(selectedItem.code);
        if (r) {
            setSelectedItem(null);
            setIsOpenConfirm(false);
            getSettings();

            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Успешно сохранено!</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не удается сохранить.</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  
        }
    } 

    const save = async ({code, title, value, oldCode}) => {
        const r = !oldCode ? await api_createSetting(code, title, value) : await api_saveSettingByCode(oldCode, code, title, value);
        if (r) {
            getSettings();

            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Успешно сохранено!</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не удается сохранить.</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  
        }
        closeDialog();
    }

    const getValue = (code, value) => {
        switch (code) {
            case "showCarrierPageBanner":
            case "showContactsPageBanner":
            case "showSearchPageBanner":
            case "partners_showSite":
            case "subscribeDialog_active":
                return value === "Y" ? "Да" : "Нет";
            default:
                return value;
        }
    }

    return <div className="AdminSettingsPage">
        <AdminLayout>
            <h1>Настройки</h1>

            <div className="buttons">
                <Button variant="contained" onClick={openDialog}>Добавить</Button>
            </div>

            <div className="settings">
                <table width="100%" className="settingsTable">
                    <thead className="tableHeader">
                        <td className="actions"></td>
                        <td className="code">Code</td>
                        <td>Title</td>
                        <td>Value</td>
                    </thead>
                    {settings.map(x => <tbody key={`setting_${x._id}`} className="row">
                        <td>
                            <Button variant="outlined"className="btnWithIcon mr" onClick={() => edit(x)}><EditIcon className="btnIcon"/></Button>
                            <Button variant="outlined"className="btnWithIcon" color="error" onClick={() => confirmDelete(x)}><DeleteIcon className="btnIcon"/></Button>
                        </td>
                        <td>{x.code}</td>
                        <td>{x.title}</td>
                        <td>{getValue(x.code, x.value)}</td>
                    </tbody>)}
                </table>
            </div>

            <EditSettingDialog isOpen={isOpenDialog} onClose={closeDialog} onSave={save} data={selectedItem} />
            <ConfirmDialog title="Внимание" content="Вы действительно хотите удалить настройку?" open={isOpenConfirm} onClose={closeConfirm} onConfirm={remove}/>
            <Loader active={isLoading} />
        </AdminLayout>
    </div>
}

export default AdminSettingsPage;