import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SketchPicker } from 'react-color';
import { api_saveTagTypeByCode } from "../../../../redux/actions/tag";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";
import InfoIcon from "../../../../components/InfoIcon";
import { getHEXColorWithAlpha } from "../../../../misc/dom";

const AdminTagsEditDialog = (props) => {
    const [background, setBackground] = useState("#FFFFFF");
    const [color, setColor] = useState("#000000");
    const [iColor, setIColor] = useState("#000000");
    const [iText, setIText] = useState("");
    const [iTextEn, setITextEn] = useState("");
    const [iTextTr, setITextTr] = useState("");

    useEffect(() => {
        if (props.item && props.item.background)
            setBackground(props.item.background);
    }, [props.item?.background]);

    useEffect(() => {
        if (props.item && props.item.color)
            setColor(props.item.color);
    }, [props.item?.color]);

    useEffect(() => {
        if (props.item && props.item.iColor)
            setIColor(props.item.iColor);
    }, [props.item?.iColor]);

    useEffect(() => {
        if (props.item && props.item.iText)
            setIText(props.item.iText);
    }, [props.item?.iText]);

    useEffect(() => {
        if (props.item && props.item.iTextEn)
            setITextEn(props.item.iTextEn);
    }, [props.item?.iTextEn]);

    useEffect(() => {
        if (props.item && props.item.iTextTr)
            setITextTr(props.item.iTextTr);
    }, [props.item?.iTextTr]);

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();

        setBackground("#000");
        setColor("#000");
        setIColor("#000");
        setIText("");
        setITextEn("");
        setITextTr("");
    }

    const onSave = async () => {
        const r = await api_saveTagTypeByCode(props.item.code, color, background, iColor, iText, iTextEn, iTextTr);
        if (r) {
            onClose();
            if (typeof props.onSave === "function")
                props.onSave();

            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Тэг был успешно сохранен</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не удалось сохранить тэг</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
    }

    const changeBackground = (color) => {
        setBackground(getHEXColorWithAlpha(color));
    }

    const changeColor = (color) => {
        setColor(color.hex);
    }

    const changeIColor = (color) => {
        setIColor(color.hex);
    }

    const changeIText = (e) => {
        setIText(e.target.value);
    }

    const changeITextEn = (e) => {
        setITextEn(e.target.value);
    }

    const changeITextTr = (e) => {
        setITextTr(e.target.value);
    }

    return <Dialog className="AdminTagsEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div style={{backgroundColor: background, color, borderRadius: "6px", width: 160, height: "30px", margin: "0px auto 20px", display: "flex", alignItems: "center", justifyContent: "center"}}>{props.item?.code?.toUpperCase() || ""} &nbsp;<InfoIcon color={iColor} /></div>

                <div className="row grid-1-1-1">
                    <div>
                        Фон:<br />
                        <SketchPicker
                            color={ background }
                            onChangeComplete={ changeBackground }
                        />
                    </div>
                
                    <div>
                        Цвет:<br />
                        <SketchPicker
                            color={ color }
                            onChangeComplete={ changeColor }
                        />
                    </div>

                    <div>
                        Цвет i:<br />
                        <SketchPicker

                            color={ iColor }
                            onChangeComplete={ changeIColor }
                        />
                    </div>
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Доп. инфо (i)" value={iText} onChange={changeIText} />
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Доп. инфо (i), en" value={iTextEn} onChange={changeITextEn} />
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Доп. инфо (i), tr" value={iTextTr} onChange={changeITextTr} />
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminTagsEditDialog;