import React, { useEffect, useState } from "react";
import "./style.scss";
import AdminLayout from "../../../layouts/AdminLayout";
import TabRequests from "./tabs/TabRequests";
import { Tab, Tabs } from "@mui/material";
import TabCarriers from "./tabs/TabCarriers";
import TabSearchLog from "./tabs/TabSearchLog";
import TabFeedbacks from "./tabs/TabFeedbacks";
import TabSubscribe from "./tabs/TabSubscribe";
import TabBookingLog from "./tabs/TabBookingLog";

const RequestLogPage = () => {
    const [activeTab, setActiveTab] = useState(0);

    const changeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    return <div className="RequestLogPage">
        <AdminLayout>
            <h1>Статистика</h1>

            <div className="content">
                <div className="tabs">
                    <Tabs value={activeTab} onChange={changeTab}>
                        <Tab label="Статистика поиска" />
                        <Tab label="Заявки по маршрутам" />
                        <Tab label="Заявки на сотрудничество" />
                        <Tab label="Связаться с нами" />
                        <Tab label="Подписка на рассылку" />
                        <Tab label="Статистика букинга" />
                    </Tabs>
                </div>
                <div className="tabContent">
                    <TabSearchLog active={activeTab === 0} />
                    <TabRequests active={activeTab === 1} />
                    <TabCarriers active={activeTab === 2} />
                    <TabFeedbacks active={activeTab === 3} />
                    <TabSubscribe active={activeTab === 4} />
                    <TabBookingLog active={activeTab === 5} />
                </div>
            </div>
        </AdminLayout>
    </div>
}

export default RequestLogPage;