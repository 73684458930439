/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { api_mailSend } from "../../../redux/actions";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchBar from "../../SearchBar";
import { useSelector } from "react-redux";
import moment from "moment";
import InputMask from "react-input-mask";
import { combinePortsAndCities } from "../../../misc/api";
import {ReactComponent as SvgBulk} from "../../../assets/icons/bulk.svg";
import {ReactComponent as SvgFcl} from "../../../assets/icons/fcl.svg";
import { getMailTemplate, isValidEmail } from "../../../misc/mail";
import MailRequest from "../../../mails/MailRequest";
import MailToCustomer from "../../../mails/MailToCustomer";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import { getLanguage, getLocalizePort, getLocalizeText, getLocalizeUrl } from "../../../misc/localization";
import MailToCustomerEn from "../../../mails/MailToCustomerEn";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import { getRuleCheckboxLabel } from "../../../misc/dom";
import MailToCustomerTr from "../../../mails/MailToCustomerTr";

const DialogRequest = (props) => {
    const location = useLocation();

    const allPorts = useSelector(state => state.port.data);
    const allCities = useSelector(state => state.city.data);
    const companies = useSelector(state => state.company.data);
    const tags = useSelector(state => state.tag.data);

    const [ports, setPorts] = useState([]);
    const [sbj, setSbj] = useState("SEALOGIC");
    const [type, setType] = useState("Не выбран");
    const [count, setCount] = useState(1);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRoute, setErrRoute] = useState("");
    const [errRule, setErrRule] = useState(false);

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperEmail, setHelperEmail] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [POL, setPol] = useState(null);
    const [POD, setPod] = useState(null);

    useEffect(() => {
        setPorts(combinePortsAndCities(allPorts, allCities));
    }, [allPorts, allCities]);

    useEffect(() => {
        if (typeof props.route === "object" && props.route) {
            resetErrors();
            setPol(ports.find(x => x.POL_en === props.route.POL));
            setPod(ports.find(x => x.POL_en === props.route.POD));

            if (typeof props.route.sbj === "string" && props.route.sbj.trim().length)
                setSbj(props.route.sbj);
        }
    }, [props.route, ports]);

    useEffect(() => {
        setDisabled(!name.length || !email.length);
        setErrEmail(errEmail && !email.length);
        if (email.length && helperEmail.length)
            setHelperEmail("");
    }, [name, email]);

    const handleClose = () => {
        resetErrors();
        resetForm();

        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangePhone= (event) => {
        setPhone(event.target.value);
    }

    const onChangeComment = (event) => {
        setComment(event.target.value);
    }

    const onTypeChange = (event) => {
        setType(event.target.value);
    };

    const onCountChange = (event) => {
        setCount(event.target.value);
    };

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);
        
        if (comment.length)
            setCommentSuccess(true);

        let _pol = null;
        let _pod = null;
        try {
            _pol = document.querySelectorAll(".DialogRequest [x-data-component = 'SearchBar'] input")[0].value;
            _pod = document.querySelectorAll(".DialogRequest [x-data-component = 'SearchBar'] input")[1].value;
        }
        catch(e){}

        if (!_pol || !_pod) {
            ok = false;
            setErrRoute(getLocalizeText("dialogs/main", "wrongRoute", getLanguage(location)));
        }
        else {
            setErrRoute("");
        }

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperEmail("");
        setErrRoute("");
        setHelperRule("");
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setPhone("");
        setComment("");
        setCount(1);
        setType("Не выбран");
    }

    const onSend = async () => {
        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','send_request');
            
            const _pol = document.querySelectorAll(".DialogRequest [x-data-component = 'SearchBar'] input")[0].value;
            const _pod = document.querySelectorAll(".DialogRequest [x-data-component = 'SearchBar'] input")[1].value;
            
            handleClose();

            const route = props.route;

            const code = sbj === "Заявка на подбор маршрута" ? "00" : "01";
            const number = `${code}-${moment().format("YYYYMMDD")}-${Math.round(Math.random() * 1000)}`;
            
            let msg = "";
            let reqLog = null;

            let sbjClient = `SEALOGIC || ${_pol} - ${_pod}`;
            let sbjUs = `SEALOGIC || ${_pol} - ${_pod}`;

            let utm = null;
            const fullUrl = localStorage.getItem("fullUrl");
            if (fullUrl) {
                const fullUrl = localStorage.getItem("fullUrl");
                const url = localStorage.getItem("url");
                const source = localStorage.getItem("utm_source");
                const medium = localStorage.getItem("utm_medium");
                const campaign = localStorage.getItem("utm_campaign");
                const content = localStorage.getItem("utm_content");
                const term = localStorage.getItem("utm_term");

                utm = {
                    fullUrl,
                    url,
                    source,
                    medium,
                    campaign,
                    content,
                    term
                }
            }

            if (route?._id) {
                // Save to logs
                const company = companies.find(x => x.line === route.line);
                const prices = [];
                for (const tagId of route.tags) {
                    if (tagId) {
                        const tag = tags.find(x => x._id === tagId);
                        if (tag && tag.type && tag.value) {
                            let name = tag.type;
                            switch(tag.type) {
                                case "rate1":
                                    name = "SOC 20’";
                                    break;
                                case "rate2":
                                    name = "COC 20’";
                                    break;
                                case "rate3":
                                    name = "SOC 40’";
                                    break;
                                case "rate4":
                                    name = "COC 40’";
                                    break;
                            }

                            prices.push({
                                name,
                                value: tag.value
                            });
                        }
                    }
                }

                reqLog = {
                    type: "request",
                    data: {
                        number,
                        line: route.line,
                        vessel: route.vessel,
                        voyage: route.voyage,
                        POL: route.POL,
                        POD: route.POD,
                        ETD: moment(route.ETD).toDate(),
                        ETA: moment(route.ETA).toDate(),
                        type,
                        count,
                        name,
                        email,
                        phone,
                        comment,
                        company: company?.company || "",
                        prices
                    }
                };

                sbjClient = `SEALOGIC || ${route.POL} (${_pol}) - ${route.POD} (${_pod})`;
                sbjUs = `SEALOGIC || ${route.POL} (${_pol}) - ${route.POD} (${_pod})`;

                // Send to sea-logic
                msg = getMailTemplate(<MailRequest
                    number={number}
                    line={route.line}
                    vessel={route.vessel}
                    voyage={route.voyage}
                    pol={`${route.POL} (${_pol})`}
                    pod={`${route.POD} (${_pod})`}
                    etd={moment(route.ETD).format("DD.MM.YYYY")}
                    eta={moment(route.ETA).format("DD.MM.YYYY")}
                    type={type}
                    count={count}
                    name={name}
                    email={email}
                    phone={phone}
                    comment={comment}
                    utm={utm}
                />);
            }
            else {
                // Save to logs
                reqLog = {
                    type: "request",
                    data: {
                        number,
                        POL: _pol,
                        POD: _pod,
                        type,
                        count,
                        name,
                        email,
                        phone,
                        comment
                    }
                };

                // Send to sea-logic
                msg = getMailTemplate(<MailRequest
                    number={number}
                    route={`${_pol} - ${_pod}`}
                    type={type}
                    count={count}
                    name={name}
                    email={email}
                    phone={phone}
                    comment={comment}
                    utm={utm}
                />);
            }
            
            api_addReqLog(reqLog);
            
            api_mailSend(sbjUs, msg, null, "html");

            // Send to client
            let msgClient;
            if (getLanguage(location) === "ru")
                msgClient = getMailTemplate(<MailToCustomer />);
            else if (getLanguage(location) === "tr")
                msgClient = getMailTemplate(<MailToCustomerTr />);
            else
                msgClient = getMailTemplate(<MailToCustomerEn />);

            api_mailSend(sbjClient, msgClient, email, "html");

            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            setRule(false);
            resetErrors();
            
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  {getLocalizeText("dialogs/main", "weWillMailYou", getLanguage(location))}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  

            resetForm();
        }
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    return <Dialog
        className="dialog DialogRequest"
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle>
            {props.routeDisabled 
                ? getLocalizeText("dialogs/request", "title", getLanguage(location))
                : getLocalizeText("dialogs/request", "title2", getLanguage(location))
            }
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="route">
                    <div className="grid_1-1" x-data-component = 'SearchBar'>
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "pol", getLanguage(location))}</div>
                            <TextField fullWidth value={POL ? `${getLocalizePort(POL, getLanguage(location))}` : ""} onChange={e => {setPol(e.target.value)}} disabled={props.routeDisabled} autoComplete="do-not-autofill" />
                        </div>
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "pod", getLanguage(location))}</div>
                            <TextField fullWidth value={POD ? `${getLocalizePort(POD, getLanguage(location))}` : ""} onChange={e => {setPod(e.target.value)}} disabled={props.routeDisabled} autoComplete="do-not-autofill" />
                        </div>
                    </div>
                    <div className={`routeError${!errRoute.length ? " hide" : ""}`}>{errRoute}</div>

                    <div className="grid_1-1">
                        <FormControl fullWidth className="Dialog-request_select">
                            <div className="label">{getLocalizeText("dialogs/main", "containerType", getLanguage(location))}<div className="req">*</div></div>
                            <Select
                                value={type}
                                onChange={onTypeChange}
                                MenuProps={{className: "menuContainer"}}
                            >
                                <MenuItem className="Dialog-request_menu" value={"Не выбран"}>{getLocalizeText("dialogs/main", "notChoosen", getLanguage(location))}</MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"BULK"}>
                                    <SvgBulk className="iconType" />BULK
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"20'"}>
                                    <SvgFcl className="iconType" />20'
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"40'"}>
                                    <SvgFcl className="iconType" />40'
                                </MenuItem>
                                <MenuItem className="Dialog-request_menu" value={"Разные варианты"}>{getLocalizeText("dialogs/main", "allVariants", getLanguage(location))}</MenuItem>
                            </Select>
                        </FormControl>

                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "containersCount", getLanguage(location))}<div className="req">*</div></div>
                            <InputMask
                                fullWidth
                                className="full"
                                mask="99999999999999999999"
                                maskChar=""
                                value={count}
                                onChange={onCountChange}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>
                </div>
                <div className="form">
                    <div className="row grid_1-1">
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                            <div>
                                <TextField required error={errName} helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} className={`full ${nameSuccess ? "success" : ""}`} value={name} onChange={onChangeName} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                        <div>
                            <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                            <div>
                                <TextField required error={errEmail} helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} className={`full ${emailSuccess ? "success" : ""}`} value={email} onChange={onChangeEmail} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                        <div>
                            <InputMask
                                placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                                className={`full ${phoneSuccess ? "success" : ""}`}
                                mask="99999999999999999999"
                                maskChar=""
                                value={phone}
                                onChange={onChangePhone}
                                autoComplete="do-not-autofill"
                                >
                                {(props) => <TextField {...props} />}
                            </InputMask>
                        </div>
                    </div>
                    <div className="row">
                        <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                        <div>
                            <TextField placeholder={getLocalizeText("dialogs/main", "commentPlaceholder", getLanguage(location))} className={`full textarea ${commentSuccess ? "success" : ""}`} InputLabelProps={{ shrink: true }} multiline value={comment} onChange={onChangeComment} />
                        </div>
                    </div>
                    <div className="row grid_1-1">
                        <div className="rules">
                            <FormControlLabel required error={errRule} helperText={helperRule} control={<Checkbox checked={rule} onChange={changeRule} />} label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)} className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`} />
                        </div>
                        <div className="button">
                            <Button fullWidth className="btn" variant="contained" onClick={onSend}>
                                {props.routeDisabled 
                                    ? getLocalizeText("dialogs/main", "send", getLanguage(location))
                                    : getLocalizeText("dialogs/main", "send2", getLanguage(location))
                                }
                            </Button>
                        </div>
                    </div>              
                </div>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}

export default DialogRequest;