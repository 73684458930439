import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import "./style.scss";


const ConfirmDialog = (props) => {
    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onConfirm = () => {
        if (typeof props.onConfirm === "function")
            props.onConfirm();
    }

    return <Dialog className="ConfirmDialog" open={props.open || false} onClose={onClose}>
        <DialogTitle>
            {props.title || ""}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.content || ""}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Нет</Button>
            <Button variant="contained" onClick={onConfirm}>Да</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmDialog;