import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../misc/localization";

const Loader = (props) => {
    const location = useLocation();

    const [isActive, setIsActive] = useState(false);
    const [text, setText] = useState("");
    const [width, setWidth] = useState(220);
    const [height, setHeight] = useState(160);

    useEffect(() => {
        if (typeof props.active === "boolean") {
            setIsActive(props.active);
            if (isActive && !props.active) {
                setTimeout(() => {setText("")}, 1000);
            }
        }
    }, [props.active]);

    useEffect(() => {
        if (typeof props.text === "string") {
            setText(props.text.length ? props.text : getLocalizeText("components/loader", "loading", getLanguage(location)));
        }
        if (Array.isArray(props.text) && text === "") {
            setText(props.text[0]);
        }
        if (typeof props.text === "undefined") {
            setText(getLocalizeText("components/loader", "loading", getLanguage(location)));
        }
    }, [props.text]);

    useEffect(() => {
        if (Array.isArray(props.text) && isActive) {
            const delay = props.delay || 1000;
            setTimeout(() => {
                changeText();
            }, delay);
        }
    }, [text, isActive]);

    useEffect(() => {
        if (typeof props.width === "number")
            setWidth(props.width);
    }, [props.width]);

    useEffect(() => {
        if (typeof props.height === "number")
            setHeight(props.height);
    }, [props.height]);

    const changeText = () => {
        let index = props.text.findIndex(x => x === text);
        if (index !== -1) {
            index ++;
            index = index < props.text.length ? index : 0;
            setText(props.text[index]);
        }
    }

    return <div className={`Loader${isActive ? "" : " hide"}`}>
        <div className="loaderContent" style={{width, height}}>
            <CircularProgress size={60} sx={{color: "#125BCA"}} />
            <div className="text">{text}</div>
        </div>
    </div>
}

export default Loader;