import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import { api_getReqLogs } from "../../../../../redux/actions/reqLog";
import "./style.scss";

const TabRequests = (props) => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        {
            field: "reqType",
            headerName: "Тип",
            width: 80,
            renderCell: (props) => {
                return <div x-data-id={props.row.reqType}>Маршруты</div>
            }
        },
        {
            field: "number",
            headerName: "Номер",
            flex: 1
        },
        {
            field: "company",
            headerName: "Компания",
            flex: 1
        },
        {
            field: "line",
            headerName: "Линия",
            flex: 1
        },
        {
            field: "vessel",
            headerName: "Судно",
            flex: 1
        },
        {
            field: "voyage",
            headerName: "Рейс",
            flex: 1
        },
        {
            field: "POL",
            headerName: "Отправка",
            flex: 1,
            valueGetter: (props) => allPorts.find(x => x.POL_en === props.row.POL)?.POL_ru || props.row.POL
        },
        {
            field: "POD",
            headerName: "Прибытие",
            flex: 1,
            valueGetter: (props) => allPorts.find(x => x.POL_en === props.row.POD)?.POL_ru || props.row.POD
        },
        {
            field: "ETD",
            headerName: "Д. отпр.",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return moment(params.row.ETD).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "ETA",
            headerName: "Д. приб.",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return moment(params.row.ETA).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "type",
            headerName: "Тип конт.",
            flex: 1
        },
        {
            field: "count",
            headerName: "Кол-во",
            width: 100
        },
        {
            field: "name",
            headerName: "Имя",
            flex: 1
        },
        {
            field: "email",
            headerName: "Емэйл",
            flex: 1
        },
        {
            field: "phone",
            headerName: "Телефон",
            flex: 1
        },
        {
            field: "prices",
            headerName: "Цены",
            cellClassName: "prices",
            flex: 1,
            renderCell: (props) => {
                return Array.isArray(props.row.prices) && props.row.prices.map(x => <div title={`${x.name}: ${x.value}`}>{x.value}</div>);
            }
        },
        {
            field: "comment",
            headerName: "Комментарий",
            flex: 1,
            renderCell: (props) => {
                return <div title={props.row.comment}>{props.row.comment}</div>
            }
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return <div className="datetime">
                    <div>{moment(params.row.createdAt).format("DD.MM.YYYY")}</div>
                    <div>{moment(params.row.createdAt).format("HH:mm:ss")}</div>
                </div>;
            },
            sortComparator: (v1, v2) => {
                if (moment(v1).isAfter(moment(v2)))
                    return 1;
                if (moment(v1).isBefore(moment(v2)))
                    return -1;
                return 0
            }
        },
    ];

    const allPorts = useSelector(state => state.port.data);

    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.active)
            init();
    }, [props.active]);

    useEffect(() => {
        setCsvData([columns.map(x => x.headerName)].concat(data.map(x => {
            return [
                x.id,
                x.reqType,
                x.number,
                x.company,
                x.line,
                x.vessel,
                x.voyage,
                allPorts.find(y => y.POL_en === x.POL)?.POL_ru || x.POL,
                allPorts.find(y => y.POL_en === x.POD)?.POL_ru || x.POD,
                moment(x.ETD).format("DD.MM.YYYY"),
                moment(x.ETA).format("DD.MM.YYYY"),
                x.type,
                x.count,
                x.name,
                x.email,
                x.phone,
                Array.isArray(x.prices) ? x.prices.map(y => `${y.name}: ${y.value}`) : "",
                x.comment,
                moment(x.createdAt).format("DD.MM.YYYY HH:mm:ss")
            ];
        })));
    }, [data]);

    const init = async () => {
        setIsLoading(true);
        setData((await api_getReqLogs()).filter(x => x.type === "request").map(x => {
            x.id = x._id;
            x.reqType = x.type;
            for (const key in x.data) {
                x[key] = x.data[key];
            }
            return x;
        }));
        setIsLoading(false);
    }

    return <div className={`TabRequests ${props.active ? "" : "hide"}`}>
        <div className="buttons">
            <CSVLink data={csvData} filename={`route-request-logs_${moment().format("YYYY-MM-DD_HH-mm-ss")}`}><Button className="btn" variant="contained">Выгрузить</Button></CSVLink>
        </div>
        <DataGrid
            className="table"
            rows={data}
            columns={columns}
            rowHeight={80}
            rowsPerPageOptions={[20, 50, 100]}
        />
        <Loader active={isLoading} />
    </div>
}

export default TabRequests;