import { Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./style.scss";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import icon_sky from "../../assets/icons/sky.svg";
import icon_ship from "../../assets/icons/ship2.svg";
import icon_sea from "../../assets/icons/sea.svg";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";
import icon_map from "../../assets/icons/map.svg";
import icon_people from "../../assets/icons/people.svg";
import icon_vector from "../../assets/icons/vector.svg";
import img_ship from "../../assets/imgs/ship3.png";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";

const AboutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const ref_container = useRef(null);
    const ref_sky = useRef(null);
    const ref_ship = useRef(null);

    useEffect(() => {
        startAnimation();
    }, []);

    const startAnimation = () => {
        startSkyAnimation();    
        startShipAnimation();
    }

    const startSkyAnimation = () => {
        ref_sky.current.classList.add("animate");
        ref_sky.current.style.left = `-${2322 - ref_container.current.clientWidth}px`;

        setTimeout(() => {
            resetSkyAnimation();
        }, 30000);
    }

    const startShipAnimation = () => {
        ref_ship.current.classList.add("animate");
        ref_ship.current.style.left = `${ref_container.current.clientWidth}px`;

        setTimeout(() => {
            resetShipAnimation();
        }, 30000);
    }

    const resetSkyAnimation = () => {
        ref_sky.current.classList.remove("animate");
        ref_sky.current.style.left = `0px`;
        setTimeout(() => {
            startSkyAnimation();
        }, 100);
    }

    const resetShipAnimation = () => {
        ref_ship.current.classList.remove("animate");
        ref_ship.current.style.left = `-202px`;
        
        setTimeout(() => {
            startShipAnimation();
        }, 100);    }

    const breadcrumbs = [
        <Link key="1" to={getLocalizeUrl(location, "/")}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</Link>,
        <Typography className="current" key="2">{getLocalizeText("breadcrumbs/main", "about", getLanguage(location))}</Typography>
    ];    

    const goToCarrier = () => {
        navigate(getLocalizeUrl(location, "/carrier"));
    }

    return <div className="AboutPage">
         <MainLayout fixedHeader={true}>
            <Helmet>
                <title>{getLocalizeText("pages/about", "meta.title", getLanguage(location))}</title>
                <meta name="description" content={getLocalizeText("pages/about", "meta.description", getLanguage(location))} />
            </Helmet>
            <div className="pageContent">
                <div ref={ref_container} className="shipAnimation">
                    <img ref={ref_sky} src={icon_sky} alt="sky" className="sky" />
                    <img src={icon_sea} alt="sea" className="sea" />
                    <img ref={ref_ship} src={icon_ship} alt="ship" className="ship" />
                </div>

                <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                    {breadcrumbs}
                </Breadcrumbs>

                <h1>{getLocalizeText("pages/about", "title", getLanguage(location))}</h1>
                
                <div className="blockContainer1">
                    <div className="block1">{getLocalizeText("pages/about", "blocks1.block1", getLanguage(location))}</div>
                    <div className="block2">
                        <div>{getLocalizeText("pages/about", "blocks1.block2", getLanguage(location))}</div>
                        <div>{getLocalizeText("pages/about", "blocks1.block3", getLanguage(location))}</div>
                    </div>
                </div>

                <div className="blockContainer2">
                    <div className="block">
                        <div className="img">
                            <img src={icon_map} alt="map"/>
                        </div>
                        <div className="title">
                            {getLocalizeText("pages/about", "blocks2.1.title", getLanguage(location))}
                        </div>
                        <div className="description">
                            {getLocalizeText("pages/about", "blocks2.1.description", getLanguage(location))}
                        </div>
                    </div>

                    <div className="block">
                        <div className="img">
                            <img src={icon_people} alt="map"/>
                        </div>
                        <div className="title">
                            {getLocalizeText("pages/about", "blocks2.2.title", getLanguage(location))}
                        </div>
                        <div className="description">
                            {getLocalizeText("pages/about", "blocks2.2.description", getLanguage(location))}
                        </div>
                    </div>

                    <div className="block">
                        <div className="img">
                            <img src={icon_vector} alt="map"/>
                        </div>
                        <div className="title">
                            {getLocalizeText("pages/about", "blocks2.3.title", getLanguage(location))}
                        </div>
                        <div className="description">
                            {getLocalizeText("pages/about", "blocks2.3.description", getLanguage(location))}
                        </div>
                    </div>
                </div>

                <div className="blockContainer3">
                    <div className="title">
                        {getLocalizeText("pages/about", "blocks3.title", getLanguage(location))}
                    </div>
                    <div className="blocks">
                        <div>
                            <div className="description">
                                {getLocalizeText("pages/about", "blocks3.description.1", getLanguage(location))}
                                <br /><br />
                                {getLocalizeText("pages/about", "blocks3.description.2", getLanguage(location))}
                            </div>
                            <div className="buttonContainer">
                                <div className="linkBtn" onClick={goToCarrier}>{getLocalizeText("pages/about", "blocks3.descriptionLink", getLanguage(location))} 
                                <IconLinkBtnArrow className="icon"/>
                            </div>
                            </div>
                        </div>
                        <div className="img">
                            <img src={img_ship} alt="ship" />
                        </div>
                    </div>
                </div>
            </div>
         </MainLayout>
    </div>
}

export default AboutPage;