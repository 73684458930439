import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from "@mui/material";
import { api_getSearchLogs } from "../../../../../redux/actions";
import moment from "moment";
import { CSVLink } from "react-csv";
import Loader from "../../../../../components/Loader";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/ru";

const TabSearchLog = (props) => {
    const columns = [
        { 
            field: 'id',
            hide: true
        },
        { 
            field: 'countryPol',
            flex: 1
        },
        {
          field: 'pol',
          flex: 1
        },
        { 
            field: 'countryPod',
            flex: 1
        },
        {
          field: 'pod',
          flex: 1
        },
        {
          field: 'ip',
          flex: 1
        },
        {
            field: 'createdAt',
            type: "date",
            width: 160,
            renderCell: (params) => {
                    return <div className="datetime">
                        <div>{moment(params.row.createdAt).format("DD.MM.YYYY")}</div>
                        <div>{moment(params.row.createdAt).format("HH:mm:ss")}</div>
                    </div>;
            },
            sortComparator: (v1, v2) => {
                if (moment(v1).isAfter(moment(v2)))
                    return 1;
                if (moment(v1).isBefore(moment(v2)))
                    return -1;
                return 0
            }
        }
    ];

    const [from, setFrom] = useState(moment().add(-1, "month"));
    const [to, setTo] = useState(moment());
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countUniqUser, setCountUniqUser] = useState(0);
    const [countFor1User, setCountFor1User] = useState(0);
    const [top5Routes, setTop5Routes] = useState([]);
    const [top5Directions, setTop5Directions] = useState([]);

    useEffect(() => {
        filter();
    }, []);

    const getData = async(filter) => {
        setIsLoading(true);
        const r = await api_getSearchLogs(filter);

        const uniqUserArr = [];
        const uniqRoutes = [];
        const uniqDirections = [];
        for (const d of r) {
            // uniq users
            if (d.ip && !uniqUserArr.some(x => x.ip === d.ip && x.date === moment(d.createdAt).format("YYYY-MM-DD"))) {
                uniqUserArr.push({
                    ip: d.ip,
                    date: moment(d.createdAt).format("YYYY-MM-DD")
                });
            }

            // uniq routes
            const route = uniqRoutes.find(x => x.pod === d.pod && x.pol === d.pol);
            if (!route) {
                uniqRoutes.push({
                    pol: d.pol,
                    pod: d.pod,
                    count: 1
                });
            }
            else {
                route.count ++;
            }

            // uniq directions
            const direction = uniqDirections.find(x => x.countryPol === d.countryPol && x.countryPod === d.countryPod);
            if (!direction) {
                uniqDirections.push({
                    countryPol: d.countryPol,
                    countryPod: d.countryPod,
                    count: 1
                });
            }
            else {
                direction.count ++;
            }
        }

        setCountUniqUser(uniqUserArr.length);
        setCountFor1User(uniqUserArr.length ? (r.length / uniqUserArr.length).toFixed(2) : 0);
        setTop5Routes(uniqRoutes.sort((a, b) => {
            if (a.count > b.count) return -1;
            if (a.count < b.count) return 1;
            return 0;
        }));
        setTop5Directions(uniqDirections.sort((a, b) => {
            if (a.count > b.count) return -1;
            if (a.count < b.count) return 1;
            return 0;
        }));


        setData(r.map(x => {
            return {
                id: x._id.toString(),
                pol: x.pol,
                countryPol: x.countryPol,
                countryPod: x.countryPod,
                pod: x.pod,
                ip: x.ip,
                createdAt: x.createdAt
            }
        }));
        setCsvData([["countryPol", "pol", "countryPod", "pod","ip","date"]].concat(r.map(x => {
            return [x.countryPol, x.pol, x.countryPod, x.pod, x.ip, moment(x.createdAt).utcOffset(3).format("DD.MM.YYYY HH:mm:ss")];
        })));
        setIsLoading(false);
    }

    const changeFrom = (newValue) => {
        if (moment(newValue).isValid())
            setFrom(newValue);
    }

    const changeTo = (newValue) => {
        if (moment(newValue).isValid())
            setTo(newValue);
    }

    const filter = () => {
        const f = {};
        if (from)
            f.from = from.format("YYYY-MM-DDT00:00:00") + "Z";
        if (to)
            f.to = to.format("YYYY-MM-DDT23:59:59") + "Z";
        getData(f);
    }

    return <div className={`TabSearchLog ${props.active ? "" : "hide"}`}>  
        <div className="dashboard">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <div className="filter">
                    <div>Период с</div>
                    <DesktopDatePicker
                        required={false}
                        className="date"
                        inputFormat="DD.MM.YYYY"
                        value={from}
                        onChange={changeFrom}
                        renderInput={(params) => <TextField required size="small" {...params} />}
                    />            
                    <div>по</div>
                    <DesktopDatePicker
                        className="date"
                        inputFormat="DD.MM.YYYY"
                        value={to}
                        onChange={changeTo}
                        renderInput={(params) => <TextField required size="small" {...params} />}
                    /> 
                    <Button className="btn-np" onClick={filter}>Применить</Button>
                </div>
            </LocalizationProvider>

            <div className="result">
                <div className="filterPanels">
                    <div className="filterPanel filterPanel1">
                        <div className="text">Количество запросов</div>
                        <div className="number">{data.length}</div>
                    </div>  
                    <div className="filterPanel filterPanel2">
                        <div className="text">Кол-во уник. пользователей</div>
                        <div className="number">{countUniqUser}</div>
                    </div>  
                    <div className="filterPanel">
                        <div className="number">{countFor1User}</div>
                        <div className="text">Количество поисковых запросов на 1 пользователя</div>
                    </div>  
                </div>
                <div>
                    <h4>Топ-5 маршрутов в поиске</h4>
                    <table>
                        <thead>
                            <td>Маршрут</td>
                            <td>Число запросов</td>
                            <td>% от всех запросов</td>
                        </thead>
                        {top5Routes.map((x, i) => {
                            if (i < 5)
                                return <tbody>
                                    <td>{x.pol} - {x.pod}</td>
                                    <td className="right">{x.count}</td>
                                    <td className="right">= {Math.round(x.count / data.length * 100)}%</td>
                                </tbody>;
                            else return null
                            })
                        }
                    </table>
                </div>
                <div>
                    <h4>Топ-5 направлений в поиске</h4>
                    <table>
                        <thead>
                            <td>Направления</td>
                            <td>Число запросов</td>
                            <td>% от всех запросов</td>
                        </thead>
                        {top5Directions.map((x, i) => {
                            if (i < 5)
                                return <tbody>
                                    <td>{x.countryPol} - {x.countryPod}</td>
                                    <td className="right">{x.count}</td>
                                    <td className="right">= {Math.round(x.count / data.length * 100)}%</td>
                                </tbody>;
                            else return null
                            })
                        }
                    </table>
                </div>
            </div>
        </div>         
        <div className="buttons">
            <CSVLink data={csvData} filename={`seach-logs_${moment().format("YYYY-MM-DD_HH-mm-ss")}`}><Button className="btn" variant="contained">Выгрузить</Button></CSVLink>
        </div>
        <DataGrid
            className="table"
            rows={data}
            columns={columns}
            pageSize={20}
            disableSelectionOnClick
        />
        <Loader active={isLoading} />
    </div>
}

export default TabSearchLog;