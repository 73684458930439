import { Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InputMask from "react-input-mask";
import { isValidEmail } from "../../../misc/mail";
import { api_addReqLog } from "../../../redux/actions/reqLog";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../../misc/localization";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';
import { getRuleCheckboxLabel } from "../../../misc/dom";

const DialogSubscribeRouteMail = (props) => {
    const location = useLocation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperEmail, setHelperEmail] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const ref_submit = useRef(null);

    useEffect(() => {
        setDisabled(!name.length || !email.length);
        setErrEmail(errEmail && !email.length);
        if (email.length && helperEmail.length)
            setHelperEmail("");
    }, [name, email]);

    const handleClose = () => {
        resetForm();

        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onChangeName = (event) => {
        setName(event.target.value);
    }

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onChangePhone= (event) => {
        setPhone(event.target.value);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }
        
        if (phone.length)
            setPhoneSuccess(true);

        if (ok)
            ref_submit.current.click();
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);

        setHelperName("");
        setHelperEmail("");
    }

    const onSend = async (e) => {
        e.preventDefault();
        //window.ym(89710989,'reachGoal','become a partner');
        handleClose();

        // Save to logs
        const reqLog = {
            type: "subscribe",
            data: {
                name,
                email,
                phone
            }
        };
        await api_addReqLog(reqLog);

        
        setName("");
        setEmail("");
        setPhone("");
        resetErrors();
        
        notification.addNotification({
            message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Мы скоро с вами свяжемся.</span></div>,
            type: "success",
            insert: "top",
            container: "bottom-right",
            dismiss: {
                duration: 5000
                }
        }); 
        
        resetForm();

        return false;
    }

    const resetForm = () => {
        setName("");
        setEmail("");
        setPhone("");
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    return <Dialog
        className="dialog DialogSubscribeRouteMail"
        open={props.open}
        onClose={handleClose}
    >
        <DialogTitle>
            {getLocalizeText("dialogs/subscribe", "title", getLanguage(location))}
            <CloseIcon className="close" onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <form onSubmit={(e) => onSend(e)}>
                    <div className="description hide">
                        {getLocalizeText("dialogs/subscribe", "description", getLanguage(location))}
                    </div>
                    <div className="form">
                        <div className="row">
                            <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}*</div>
                            <div>
                                <TextField required error={errName} helperText={helperName} placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} className={`full ${nameSuccess ? "success" : ""}`} value={name} onChange={onChangeName} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}*</div>
                            <div>
                                <TextField required error={errEmail} helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} className={`full ${emailSuccess ? "success" : ""}`} value={email} onChange={onChangeEmail} autoComplete="do-not-autofill" />
                            </div>
                        </div>
                        <div className="row hide">
                            <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                            <div>
                                <InputMask
                                    placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}
                                    className={`full ${phoneSuccess ? "success" : ""}`}
                                    mask="99999999999999999999"
                                    maskChar=""
                                    value={phone}
                                    onChange={onChangePhone}
                                    autoComplete="do-not-autofill"
                                    >
                                    {(props) => <TextField {...props} />}
                                </InputMask>
                            </div>
                        </div>

                        <div className="row grid_1-1">
                            <div className="rules">
                                <FormControlLabel required error={errRule} helperText={helperRule} control={<Checkbox checked={rule} onChange={changeRule} />} label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)} className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`} />
                            </div>
                            <div className="button">
                                <Button fullWidth className="btn" variant="contained" onClick={validate}>
                                    {getLocalizeText("dialogs/main", "send", getLanguage(location))}
                                </Button>
                            </div>
                        </div>    

                        <button ref={ref_submit} className="hide" type="submit"></button>                
                    </div>
                </form>
            </DialogContentText>
        </DialogContent>
    </Dialog>
}

export default DialogSubscribeRouteMail;