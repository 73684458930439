import React, { useEffect, useState } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import img_ship from "../../assets/icons/anchor2.svg";
import img_rail from "../../assets/icons/_train.svg";
import moment from "moment";
import { beforeThanToday, daysTitle, getDaysCount } from "../../misc/date";
import CircleIcon from '@mui/icons-material/Circle';
import RouteTags from "../RouteTags";
import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./style.scss";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchResultRouteVertical from "../SearchResultRouteVertical";
import RouteTagsNearCompany from "../RouteTagsNearCompany";
import { getCompanyLogoPath } from "../../misc/file";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeCompanyName, getLocalizePortAndCode, getLocalizeText } from "../../misc/localization";
import { api_addBookingLog } from "../../redux/actions/bookingLog";
import { getFreaightHost } from "../../misc/api";
import RouteServices from "./RouteServices";
import RouteTariffType from "./RouteTariffTypes";
import SocCocPrice from "./SocCocPrice";
import {ReactComponent as IconClosed} from "../../assets/icons/closed.svg";

const SearchResultRouteNear = (props) => {
    const location = useLocation();

    moment.locale(getLanguage(location));

    const [openedDetails, setOpenedDetails] = useState(false);
    const [tariff, setTariff] = useState(null);

    useEffect(() => {
        if (props.route) {
            if (props.route.POLDistance || props.route.PODDistance)
                setOpenedDetails(true);
        }
    }, [props.route]);

    const sendRequest = (route) => {
        if (typeof props.sendRequest === "function")
            props.sendRequest(route);
    }

    const openDetails = () => {
        setOpenedDetails(current => !current);
    }

    const goToBooking = (route, hidden = false) => {
        if (typeof window.ym === "function")
            window.ym(89710989,'reachGoal','addbooking');
        const polPort = props.ports.find(x => x.POL_en === route.POL);
        const podPort = props.ports.find(x => x.POL_en === route.POD);
        const company = props.companies.find(x => x.line === route.line);

        if (polPort && podPort && company) {
            const number = `03-${moment().format("YYYYMMDD")}-${Math.round(Math.random() * 1000)}`;

            const data = {
                companyRu: company.companyRu,
                companyEn: company.company,
                lineRu: company.lineRu,
                lineEn: company.line,
                countryPolRu: polPort.country_ru,
                countryPolEn: polPort.country_en,
                polRu: polPort.POL_ru,
                polEn: polPort.POL_en,
                countryPodRu: podPort.country_ru,
                countryPodEn: podPort.country_en,
                podRu: podPort.POL_ru,
                podEn: podPort.POL_en,
                etd: route.ETD,
                eta: route.ETA,
                cutOff: route.cutOffDate || "",
                days: getDaysCount(moment(route.ETD).toDate(), moment(route.ETA).toDate()),
                voyage: route.voyage,
                vessel: route.vessel,
                orderId: number,
                companyLogo: getCompanyLogoPath(props.companies.find(x => x.line === route.line) || {code: "default"})
            };

            api_addBookingLog({data});

            const params = [
                `companyRu=${company.companyRu}`,
                `companyEn=${company.company}`,
                `lineRu=${company.lineRu}`,
                `lineEn=${company.line}`,
                `countryPolRu=${polPort.country_ru}`,
                `countryPolEn=${polPort.country_en}`,
                `polRu=${polPort.POL_ru}`,
                `polEn=${polPort.POL_en}`,
                `countryPodRu=${podPort.country_ru}`,
                `countryPodEn=${podPort.country_en}`,
                `podRu=${podPort.POL_ru}`,
                `podEn=${podPort.POL_en}`,
                `polPortCode=${polPort.POL_code}`,
                `podPortCode=${podPort.POL_code}`,
                `etd=${route.ETD}`,
                `eta=${route.ETA}`,
                `cutOff=${route.cutOffDate || ""}`,
                `days=${getDaysCount(moment(route.ETD).toDate(), moment(route.ETA).toDate())}`,
                `voyage=${route.voyage}`,
                `vessel=${route.vessel}`,
                `orderId=${number}`,
                `companyLogo=${getCompanyLogoPath(props.companies.find(x => x.line === route.line) || {code: "default"})}`
            ]

            window.open(`${getFreaightHost()}/new-booking?${params.join("&")}`);
        }
        else {
            console.error("Can't find pol port or pod port.");
        }
    }

    const onTariffChange = (value) => {
        setTariff(value);
    }

    const showPlaceBtn = (props) => {
        return !!(props.route.allowToBooking
            && props.route.cutOffDate
            && moment(props.route.cutOffDate).isValid()
            && moment(props.route.cutOffDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z")))
            && ((props.validTillDate && moment(props.validTillDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))))));
    }

    return <>
        <div x-data-validTill={props.route.validTill} x-data-id={props.route._id} className={`SearchResultRouteNear block ${openedDetails ? "hide" : ""}`} x-data-priority={props.route.priority || 0}>
            <div className={`left`}>
                <div className={`verticalWay${props.route.POL === props.filter?.POL.POL_en ? " pol" : ""}${props.route.POD === props.filter?.POD.POL_en ? " pod" : ""}`}></div>
                <div className="route near">
                    <div className="from near path">
                        {props.filter && props.route.POL !== props.filter.POL.POL_en
                            ?   <div>
                                    <div className="way land mt">
                                        <CircleIcon className="arrowFrom" />
                                        <CircleIcon className="circleTo"/>
                                    </div>
                                    <div className="city">
                                        <div className="mobileWayIcon">
                                            <ArrowRightIcon className="arrow"/>
                                            <div className="land" />
                                        </div>
                                        {props.ports.find(x => x.POL_en === props.filter.POL.POL_en)
                                            ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter.POL.POL_en), getLanguage(location))
                                            :   props.filter.POL.POL_en
                                        }
                                    </div>
                                    {props.route.POLDistance && Math.round(props.route.POLDistance / 1000) > 0
                                        ?   <div className="distance">{Math.round(props.route.POLDistance / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))} <ArrowForwardIcon className="arrow"/></div>
                                        :   <div className="distance">1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))} <ArrowForwardIcon className="arrow"/></div>
                                    }
                                </div>
                            : null
                        }
                    </div>
                    <div className={`from ${props.filter && props.route.POL !== props.filter.POL.POL_en ? "" : "noLeftNear"}`}>
                        {props.route.type === "transshipment" && props.route.transport1 === "rail"
                            ? <div className="mobileRailWay from" />
                            : null
                        }
                        <div className="date">
                            <div className="mobileWayIcon">
                                <CircleIcon className={`iconCircle from ${props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 ? "black" : ""}`}/>
                            </div>
                            <img src={props.route.type === "transshipment" && props.route.transport1 === "rail" ? img_rail : img_ship} alt="ship" />
                            <div className="text">{moment(props.route.ETD).format("DD MMMM YYYY")}</div>
                        </div>
                        <div className="station">
                            {props.ports.filter(port => port.POL_en === props.route.POL).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}
                        </div>
                    </div>
                    <div className="path">
                        <div className="countOfDay">{getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate())} {daysTitle(getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate()), getLanguage(location))}</div>
                        <div className="way">
                            <CircleIcon className={`circle from ${props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 ? "black" : ""}`} />
                            <CircleIcon className={`circle to ${props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 ? "black" : ""}`} />
                            {props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 && props.route.transport1 === "rail"
                                ? <div className={`rail`} />
                                : <div />
                            }
                            {props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 && props.route.transport2 === "rail"
                                ? <div className={`rail`} />
                                : <div />
                            }
                        </div>
                    </div>
                    <div className="to">
                        {props.route.type === "transshipment" && props.route.transport2 === "rail"
                            ? <div className="mobileRailWay to" />
                            : null
                        }
                        <div className="date">
                            <div className="mobileWayIcon">
                                <CircleIcon className={`iconCircle to ${props.route.type === "transshipment" && props.route.transport1 !== props.route.transport2 ? "black" : ""}`}/>
                            </div>
                            <img src={props.route.type === "transshipment" && props.route.transport2 === "rail" ? img_rail : img_ship} alt="ship" />
                            <div className="text">{moment(props.route.ETA).format("DD MMMM YYYY")}</div>
                        </div>
                        <div className="station">
                            {props.ports.filter(port => port.POL_en === props.route.POD).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}
                        </div>
                    </div>
                    <div className="to near path">
                        {props.filter && props.route.POD !== props.filter.POD.POL_en
                            ?   <div>
                                    <div className="way land mt">
                                        <ArrowRightIcon className="arrowFrom" />
                                        <CircleIcon className="circleTo"/>
                                    </div>
                                    <div className="city">
                                        <div className="mobileWayIcon">
                                            <CircleIcon className="iconCircle" />
                                            <div className="land" />
                                        </div>
                                        {props.ports.find(x => x.POL_en === props.filter?.POD.POL_en)
                                            ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter?.POD.POL_en), getLanguage(location))
                                            :   props.filter?.POL.POL_en
                                        }
                                    </div>
                                    {props.route.PODDistance && Math.round(props.route.PODDistance / 1000) > 0
                                        ?   <div className="distance"><ArrowForwardIcon className="arrow"/>{Math.round(props.route.PODDistance / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))}</div>
                                        :   <div className="distance"><ArrowForwardIcon className="arrow"/>1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))}</div>
                                    }
                                </div>
                            : null
                        }
                    </div>
                </div>

                <div className="servicesTariffContainer">
                    <div className="services">
                        <RouteServices company={props.companies.find(x => x.line === props.route.line)} />
                    </div>
                    <div className="tariffTypes">
                        <RouteTariffType validTill={props.validTill || null} onChange={onTariffChange} />
                    </div>
                </div>

                <div className={`detailsBtn ${typeof props.route?.extensions === "undefined" ? "hide" : ""}`} onClick={openDetails}>
                    {getLocalizeText("components/searchResult", "open", getLanguage(location))}
                    <ArrowDropDownIcon className="arrow" />
                </div>
            </div>
            <div className="right">
                <div className={`landingOverTablet ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                    <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                </div>
                <div className="rightBlock">
                    <div className="comapny">
                        <div className="companyContainer">
                            <img src={getCompanyLogoPath(props.companies.find(x => x.line === props.route.line) || {code: "default"})} alt="company_logo" />
                            <div className="name" title={props.companies.filter(x => x.line === props.route.line).filter((x, i) => i === 0).map(x => x.company)}>
                                {getLocalizeCompanyName(props.companies.find(x => x.line === props.route.line) || {company: "<unknown namw>"}, location)}
                            </div>
                        </div>
                    </div>
                    <div className={`landingOver ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                        <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                    </div>
                    <div className={`soccocContainer ${props.validTill?.prices.length ? "" : "empty"}`}>
                        <SocCocPrice validTill={props.validTill || null} tariff={tariff} />
                    </div>
                    <div className="application" x-data-validTill={props.validTillDate || "undefined"} x-data-allowToBooking={props.route.allowToBooking.toString()} x-data-cutOffDate={props.route.cutOffDate}>
                        {
                            showPlaceBtn(props)
                                ? <Button className={`btn booking`} variant="contained" onClick={() => goToBooking(props.route)}>{getLocalizeText("components/searchResult", "goToBooking", getLanguage(location))}</Button>
                                    : null
                        }

                        <Button variant="outlined" className={`request ${props.route.allowToBooking && props.route.cutOffDate && moment(props.route.cutOffDate).isValid() && moment(props.route.cutOffDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))) && ((props.validTillDate && moment(props.validTillDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))))) ? "hasBooking" : ""} ${beforeThanToday(props.route.ETD) ? "hide" : ""}`} onClick={() => sendRequest(props.route)}>{getLocalizeText("components/searchResult", "sendQuery", getLanguage(location))}</Button>
                    </div>
                </div>
            </div>
        </div>
        <div x-data-id={props.route._id} className={`verticalRoute ${openedDetails ? "open" : ""}`} x-data-priority={props.route.priority || 0}>
            <SearchResultRouteVertical route={props.route} ports={props.ports} companies={props.companies} isOpen={openedDetails} onClose={() => setOpenedDetails(false)} filter={props.filter} goToBooking={goToBooking} showPlaceBtn={showPlaceBtn} sendRequest={sendRequest} validTill={props.validTill} validTillDate={props.validTillDate} tariff={tariff} onTariffChange={onTariffChange} />
        </div>
    </>
}

export default SearchResultRouteNear;
