import {
    COMPANIES_DATA,
    COMPANIES_FETCHED,
    COMPANIES_FETCHING
} from "../types";

const initState = {
    fetching: false,
    fetched: false,
    data: []
};

const companyReducer = (state = initState, action) => {
    const {type, payload} = action;
    switch(type) {
        case COMPANIES_DATA: 
            state.data = payload
            return {...state};
        case COMPANIES_FETCHING: 
            state.fetching = payload
            return {...state};
        case COMPANIES_FETCHED: 
            state.fetched = payload
            return {...state};

        default:
            return state;
    }
}

export default companyReducer;