import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_addCompany, api_getPortsOnlyByQuery, api_saveCompany } from "../../../../../redux/actions";
import { api_addTag, api_deleteTag, api_saveTag } from "../../../../../redux/actions/tag";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";

const AdminCompanyEditDialog = (props) => {
    const allTags = useSelector(state => state.tag.data);

    const [code, setCode] = useState("");
    const [company, setCompany] = useState("");
    const [line, setLine] = useState("");
    const [address, setAddress] = useState("");
    const [email, setEmail] = useState("");    
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [phone2, setPhone2] = useState("");
    const [siteTitle, setSiteTitle] = useState("");
    const [site, setSite] = useState("");
    const [isPartner, setIsPartner] = useState("");
    const [partnerCarouselPosition, setPartnerCarouselPosition] = useState("");

    const [isBulk, setIsBulk] = useState(false);
    const [isDg, setIsDg] = useState(false);
    const [isOversize, setIsOversize] = useState(false);
    const [isRef, setIsRef] = useState(false);
    const [isRoro, setIsRoro] = useState(false);
    const [isLcl, setIsLcl] = useState(false);
    const [isDoor, setIsDoor] = useState(false);

    const [eCode, setECode] = useState(false);
    const [eCompany, setECompany] = useState(false);
    const [eLine, setELine] = useState(false);

    useEffect(() => {
        if (props.item) {
            //setRate1(allTags.find(x => props.item.tags && props.item.tags.length >= 1 && x._id === props.item.tags[0])?.value || "");
            setCode(props.item.code);
            setCompany(props.item.company);
            setLine(props.item.line);
            setAddress(props.item.address);
            setEmail(props.item.email);
            setPhone(props.item.phone);
            setPhone2(props.item.phone2);
            setFax(props.item.fax);
            setSite(props.item.site);
            setSiteTitle(props.item.siteTitle || "");
            setIsPartner(props.item.isPartner);
            setPartnerCarouselPosition(props.item.partnerCarouselPosition);

            if (Array.isArray(props.item.tags)) {
                for (const id of props.item.tags) {
                    const tag = allTags.find(x => x._id === id);
                    if (tag) {
                        switch (tag.type.toLowerCase()) {
                            case "bulk":
                                setIsBulk(true);
                                break;
                            case "dg":
                            case "og":
                                setIsDg(true);
                                break;
                            case "oversize":
                                setIsOversize(true);
                                break;
                            case "ro-ro":
                                setIsRoro(true);
                                break;
                            case "ref":
                                setIsRef(true);
                                break;
                            case "lcl":
                                setIsLcl(true);
                                break;
                            case "door":
                                setIsDoor(true);
                                break;
                        }
                    }
                }
            }      
        }
    }, [props.item]);

    const resetForm = () => {
        setAddress("");
        setCode("");
        setCompany("");
        setEmail("");
        setFax("");
        setIsBulk(false);
        setIsDg(false);
        setIsDoor(false);
        setIsLcl(false);
        setIsOversize(false);
        setIsPartner(false);
        setIsRef(false);
        setIsRoro(false);
        setLine("");
        setPhone("");
        setPhone2("");
        setSite("");
        setSiteTitle("");
        setPartnerCarouselPosition("");

        setTimeout(() => {
            clearError();
        }, 300);
    }

    const clearError = () => {
        setECode(false);
        setECompany(false);
        setELine(false);
    }

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
        resetForm();
    }

    const isValid = () => {
        let hasErrors = false;

        if (!code.length) {
            setECode(true);
            hasErrors = true;
        }

        if (!company.length) {
            setECompany(true);
            hasErrors = true;
        }

        if (!line.length) {
            setELine(true);
            hasErrors = true;
        }

        return !hasErrors;
    }

    const onSave = async () => {
        if (isValid()) {
            const tags = [];
            const _tags = [];
            if (Array.isArray(props.item.tags)) {
                for (const id of props.item.tags) {
                    const tag = allTags.find(x => x._id === id);
                    if (tag) {
                        _tags.push(tag);
                    }
                }
            }

            if (isBulk) {
                const tag = _tags.find(x => x.type === "bulk");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "bulk",
                            value: "BULK"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "bulk");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isDg){ 
                const tag = _tags.find(x => x.type === "dg" || x.type === "og");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "dg",
                            value: "DG"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "dg" || x.type === "og");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isDoor) {
                const tag = _tags.find(x => x.type === "door");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "door",
                            value: "DOOR"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "door");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isLcl) {
                const tag = _tags.find(x => x.type === "lcl");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "lcl",
                            value: "LCL"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "lcl");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isOversize) {
                const tag = _tags.find(x => x.type === "oversize");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "oversize",
                            value: "OVERSIZE"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "oversize");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isRef) {
                const tag = _tags.find(x => x.type === "ref");
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "ref",
                            value: "REF"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);
            }
            else {
                const tag = _tags.find(x => x.type === "ref");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            if (isRoro){
                const tag = _tags.find(x => x.type === "ro-ro"); 
                if (!tag) {
                    try {
                        const r = await api_addTag({
                            relatedTo: "company",
                            type: "ro-ro",
                            value: "RORO"
                        });
                        tags.push(r._id);
                    }
                    catch(e){}
                }
                else
                    tags.push(tag._id);                
            }
            else {
                const tag = _tags.find(x => x.type === "ro-ro");
                if (tag)
                    await api_deleteTag(tag._id);
            }

            const data = {
                code,
                company,
                line,
                address,
                email,
                phone,
                fax,
                phone2,
                site,
                siteTitle,
                isPartner,
                tags,
                partnerCarouselPosition
            };

            let r = null;
            if (props.item?._id) {
                r = await api_saveCompany(props.item._id, data);
            }
            else {
                r = await api_addCompany(data);
            }

            if (r) {
                notification.addNotification({
                    message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Компания была успешно сохранена</span></div>,
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
                if (typeof props.onSave === "function")
                    props.onSave();
                    onClose();
            }
            else {
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> Не удалось сохранить компанию</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
            }
        }
    }

    const changeCode = (e) => {
        setCode(e.target.value);
    }

    const changeCompany = (e) => {
        setCompany(e.target.value);
    }

    const changeLine = (e) => {
        setLine(e.target.value);
    }

    const changeAddress = (e) => {
        setAddress(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changePhone2 = (e) => {
        setPhone2(e.target.value);
    }

    const changeFax = (e) => {
        setFax(e.target.value);
    }

    const changeSite = (e) => {
        setSite(e.target.value);
    }
    
    const changeSiteTitle = (e) => {
        setSiteTitle(e.target.value);
    }

    const changeIsPartner = (e) => {
        setIsPartner(e.target.value);
    }

    const changeIsBulk = (e) => {
        setIsBulk(e.target.checked);
    }
    const changeIsDg = (e) => {
        setIsDg(e.target.checked);
    }
    const changeIsOversize = (e) => {
        setIsOversize(e.target.checked);
    }
    const changeIsRef = (e) => {
        setIsRef(e.target.checked);
    }
    const changeIsRoro = (e) => {
        setIsRoro(e.target.checked);
    }
    const changeIsLcl = (e) => {
        setIsLcl(e.target.checked);
    }
    const changeIsDoor = (e) => {
        setIsDoor(e.target.checked);
    }

    const changePartnerCarouselPosition = (e) => {
        setPartnerCarouselPosition(e.target.value);
    }

    return <Dialog className="AdminRouteEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="row">
                    <TextField required fullWidth size="small" label="Код" variant="outlined" value={code} onChange={changeCode} error={eCode} />
                </div>

                <div className="row">
                    <TextField required fullWidth size="small" label="Компания" variant="outlined" value={company} onChange={changeCompany} error={eCompany} />
                </div>

                <div className="row">
                    <TextField required fullWidth size="small" label="Линия" variant="outlined" value={line} onChange={changeLine} error={eLine} />
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Адрес" variant="outlined" value={address} onChange={changeAddress}/>
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Email" variant="outlined" value={email} onChange={changeEmail}/>
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Телефон" variant="outlined" value={phone} onChange={changePhone}/>
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Телефон2" variant="outlined" value={phone2} onChange={changePhone2}/>
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Факс" variant="outlined" value={fax} onChange={changeFax}/>
                </div>

                <div className="row grid-1-1">
                    <TextField fullWidth size="small" label="Сайт (название)" variant="outlined" value={siteTitle} onChange={changeSiteTitle}/>
                    <TextField fullWidth size="small" label="Сайт (ссылка)" variant="outlined" value={site} onChange={changeSite}/>
                </div>

                <div className="row grid-1-1">
                    <div>
                        <TextField fullWidth size="small" label="Партнер" variant="outlined" value={isPartner} onChange={changeIsPartner}/>
                    </div>
                    <div>
                        <FormControl fullWidth size="small">
                            <InputLabel>Сторона карусели</InputLabel>
                            <Select
                                value={partnerCarouselPosition}
                                label="Сторона карусели"
                                onChange={changePartnerCarouselPosition}
                            >
                                <MenuItem value="left">Левая сторона</MenuItem>
                                <MenuItem value="right">Правая сторона</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div className="row tags">
                    <FormControlLabel control={<Checkbox checked={isBulk} onChange={changeIsBulk} />} label="BULK" />
                    <FormControlLabel control={<Checkbox checked={isDg} onChange={changeIsDg} />} label="DG" />
                    <FormControlLabel control={<Checkbox checked={isOversize} onChange={changeIsOversize} />} label="OVERSIZE" />
                    <FormControlLabel control={<Checkbox checked={isRef} onChange={changeIsRef} />} label="REF" />
                    <FormControlLabel control={<Checkbox checked={isRoro} onChange={changeIsRoro} />} label="RORO" />
                    <FormControlLabel control={<Checkbox checked={isLcl} onChange={changeIsLcl} />} label="LCL" />
                    <FormControlLabel control={<Checkbox checked={isDoor} onChange={changeIsDoor} />} label="DOOR" />
                </div>
                
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminCompanyEditDialog;