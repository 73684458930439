import React, { useState } from "react";
import "./style.scss";
import BottomMenu from "../../menues/BottomMenu";
import { Link, useLocation } from "react-router-dom";
import DialogFeedback from "../dialogs/Feedback";
import icon_logo from "../../assets/icons/logo.svg";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import icon_logo_text from "../../assets/icons/logoText.svg";
import icon_tg from "../../assets/icons/tg.svg";
import icon_gmail from "../../assets/icons/gmail.svg";
import FeedbackForm from "../forms/FeedbackForm";

const Footer2 = () => {
    const location = useLocation();

    return <div className="Footer2">
        <div className="footerContent">
            <div className="blocks">
                <div className="logoContainer">
                    <img className="logo" src={icon_logo} alt="logo" />
                    <img src={icon_logo_text} alt="logo text" />
                </div>
               
                <div className="block">
                    <div className="menuContainer">
                        <BottomMenu />
                    </div>
                    
                    <div className="links">
                        <div className="linkContainer">
                            <a href="https://t.me/sealogic" target="_blank"><img className="imgLink" src={icon_tg} alt="telegram" /></a>
                            <a href="mailto:info@sealogic.io" target="_blank"><img className="imgLink" src={icon_gmail} alt="mail" /></a>
                        </div>

                        <div className="subLinks">
                            <a href="https://freepik.com/" target="_blank">{getLocalizeText("components/footer", "freepik", getLanguage(location))}</a>
                            <Link to={getLocalizeUrl(location, "/privacyPolicy")}>{getLocalizeText("components/footer", "privacyPolicy", getLanguage(location))}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Footer2;