import React, { memo } from "react";
import { renderToString } from "react-dom/server";
import { getHost } from "../../misc/api";

const MailRequestAdvancedRating = memo((props) => {

    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <div style={{display: "flex", flexDirection: "row", gap: "0px", padding: "27px 60px", borderBottom: "1px solid #E2E8F0", height: "80px", boxSizing: "border-box"}}>
            <div style={{width: "30px", marginRight: "8px"}}>
                <a style={{textDecoration: "none !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    <img style={{width: "100%"}} src={`${getHost()}/images/logo.png`} alt="logo" />
                </a>
            </div>
            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "700", fontSize: "30px", lineHeight: "14px", marginTop: "4px"}}>
                <a style={{textDecoration: "none !important", color: "#000000 !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    sealogic
                </a>
            </div>
        </div>

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "left", marginBottom: "44px"}}>
                Заявка на расширенный рейтинг:
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Имя</b> {props.name}
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Email</b> {props.email}
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Телефон</b> {props.phone}
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Комментарий</b> <p style={{marginTop: "12px", marginBottom: "0"}} dangerouslySetInnerHTML={{__html: props.comment?.replace(/\n/, renderToString(<br/>))}}></p>
            </div>

            <div style={{marginTop: "44px", fontFamily: ["Montserrat", "Arial"], fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
                *Данное письмо сформировано автоматически. Отвечать на него не нужно.
            </div>
        </div>
        <div style={{backgroundColor: "#E9F3FD", height: "75px"}}>&nbsp;</div>
    </div>
});

export default MailRequestAdvancedRating;
