import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { api_getCompaniesPartners } from "../../redux/actions";
import { getCompanyLogoPath } from "../../misc/file";
import "./style.scss";
import { api_getSettingByCode } from "../../redux/actions/settings";

const replayCount = 10;
const k = 5;
let isStartedAnimation = false;

const PartnersHorizontal = () => {
    const [partners, setPartners] = useState([]);
    const [showSite, setShowSite] = useState(false);

    const ref_block = useRef(null);

    useEffect(() => {
        getPartners();
        getSettings();

    }, []);

    useEffect(() => {
        if (partners.length)
            startAnimate(partners.length);
        
    }, [partners]);

    const getPartners = async () => {
        setPartners(await api_getCompaniesPartners());
    }

    const getSettings = async () => {
        const setting_showSite = await api_getSettingByCode("partners_showSite");
        if (setting_showSite)
            setShowSite(setting_showSite.value === "Y");
    }

    const startAnimate = (count) => {
        if (!count || isStartedAnimation) return;
        isStartedAnimation = true;
        const time = count * replayCount * k;
        ref_block.current.classList.add("animate");
        ref_block.current.style.left = `-${ref_block.current.clientWidth - 328}px`;
        ref_block.current.style.transitionDuration = `${time}s`;
        setTimeout(() => {
            resetAnimation(count);
        }, time * 1000);
    }

    const resetAnimation = (count) => {
        isStartedAnimation = false;
        ref_block.current.style.transitionDuration = `0s`;
        ref_block.current.classList.remove("animate");
        ref_block.current.style.left = `0px`;
        startAnimate(count);
    }

    return <div className="PartnersHorizontal">
        <div ref={ref_block} className="block">
            {([...Array(replayCount)]).map(a => partners.map(x => <div className="partner">
                <div className="partnerLogo">
                    <a href={x.site} target="_blank">
                        <img src={getCompanyLogoPath(x)} alt={x.code}/>
                    </a>
                </div>
                {showSite
                    ? <div className="partnerSite">
                            <a href={x.site} target="_blank">{x.siteTitle || x.site}</a>
                        </div>
                    : null
                }
            </div>))}
        </div>
    </div>
}

export default PartnersHorizontal;