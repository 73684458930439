import React, { memo } from "react";
import { getHost } from "../../misc/api";

const MailHeader = memo((props) => {
    return (
        <div
          style={{
            padding: "27px 60px",
            borderBottom: "1px solid #E2E8F0",
            height: "80px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: "8px",
              display: "inline-block",
            }}
          >
            <a
              style={{ textDecoration: "none !important" }}
              href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}
            >
              <img
                style={{ width: "100%" }}
                src={`${getHost()}/images/logo.png`}
                alt="logo"
              />
            </a>
          </div>
          <div
            style={{
                fontFamily: ["Montserrat", "Arial"],
              fontWeight: "700",
              fontSize: "30px",
              lineHeight: "14px",
              marginTop: "4px",
              display: "inline-block",
            }}
          >
            <a
              style={{
                textDecoration: "none !important",
                color: "#000000 !important",
              }}
              href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}
            >
              sealogic
            </a>
          </div>
        </div>
    );
});

export default MailHeader;
