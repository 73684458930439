import axios from "axios";
import { getApiHost } from "../../misc/api";
import { SETTINGS_DATA, SETTINGS_FETCHED, SETTINGS_FETCHING } from "../types";

const apiHost = getApiHost();

export const api_getSettings_store = () => async (dispatch) => {
    let data = [];
    try {
        dispatch({
            type: SETTINGS_FETCHING,
            payload: true
        });

        try {
            const headers = {
                token: localStorage.getItem("token")
            };
            const api = `/api/settings`;
            data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        }
        catch(err){}
    }
    catch(e) {
        dispatch({
            type: SETTINGS_FETCHING,
            payload: false
        });

        dispatch({
            type: SETTINGS_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: SETTINGS_DATA,
        payload: data
    });

    dispatch({
        type: SETTINGS_FETCHING,
        payload: false
    });

    dispatch({
        type: SETTINGS_FETCHED,
        payload: true
    });
    
    return Promise.resolve(data);
}

export const api_getSettings = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/settings`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_getSettingByCode = async (code) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/settings/code/${code}`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_createSetting = async (code, title, value) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/settings`;
        const data = (await axios.post(`${apiHost}${api}`, {code, title, value}, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveSettingByCode = async (code, newCode, title, value) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/settings/code/${code}`;
        const data = (await axios.put(`${apiHost}${api}`, {code: newCode, title, value}, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_deleteSettingByCode = async (code) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/settings/code/${code}`;
        const data = (await axios.delete(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}