import { Breadcrumbs, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./style.scss";
import { Helmet } from "react-helmet";
import { getMailTemplate, isValidEmail } from "../../misc/mail";
import { api_addReqLog } from "../../redux/actions/reqLog";
import { api_mailSend } from "../../redux/actions";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MailFeedback from "../../mails/MailFeedback";
import DialogRequestFree from "../../components/dialogs/RequestFree";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import { getRuleCheckboxLabel, isMobile } from "../../misc/dom";
import EastIcon from '@mui/icons-material/East';
import { api_getBanners } from "../../redux/actions/banners";
import Carousel from "react-material-ui-carousel";
import { getApiHost } from "../../misc/api";
import { api_getSettingByCode } from "../../redux/actions/settings";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";

const sbj = "Обратная связь";

const ContactPage = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");
    const [rule, setRule] = useState(false);
    const [openGetMeMoreInformation, setOpenGetMeMoreInformation] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperEmail, setHelperEmail] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [commentSuccess, setCommentSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const [showBanners, setShowBanners] = useState(false);

    const breadcrumbs = [
        <Link key="1" to={getLocalizeUrl(location, "/")}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</Link>,
        <Typography className="current" key="2">{getLocalizeText("breadcrumbs/main", "contact", getLanguage(location))}</Typography>
    ];

    useEffect(() => {
        getBanners();
    }, []);

    const getBanners = async () => {
        setShowBanners((await api_getSettingByCode("showContactsPageBanner"))?.value === "Y" || false);
        setBanners((await api_getBanners()).filter(x => x.type === "contacts_page"));
    }

    const validate = () => {
        resetErrors();

        let ok = true;

        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }
        
        if (!name.trim().length) {
            ok = false;
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        if (phone.length)
            setPhoneSuccess(true);

        if (comment.length)
            setCommentSuccess(true);

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrEmail(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setCommentSuccess(false);

        setHelperEmail("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','feedback');

            // Save to logs
            const reqLog = {
                type: "feedback",
                data: {
                    name,
                    email,
                    phone,
                    comment
                }
            };
            await api_addReqLog(reqLog);

            // Send to sea-logic
            const msg = getMailTemplate(<MailFeedback
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
            api_mailSend(sbj, msg, null, "html");

            setName("");
            setEmail("");
            setPhone("");
            setComment("");
            resetErrors();
            
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Мы скоро с вами свяжемся.</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });  

            return false;
        }
    }

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeComment = (e) => {
        setComment(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const goToCarrier = () => {
        navigate(getLocalizeUrl(location, "/carrier"));
    }

    const goToTg = () => {
        window.location.href = "https://t.me/sealogic";
    }

    const onSlideClick = (item) => {
        if (Array.isArray(item.onClick) && item.onClick.length) {
            for (const arrItem of item.onClick) {
                switch (arrItem.type) {
                    case "js":
                        switch (arrItem.value) {
                            case "moreInfoDialog":
                                if (item?.id > 0 && item?.id <= 5) {
                                    if (typeof window.ym === "function")    
                                        window.ym(89710989,'reachGoal','banner ' + item.id);
                                }
                                setBanner(item);
                                setOpenGetMeMoreInformation(true);
                                break;
                            default:
                        }
                        break;
                    case "link":
                        window.location.href = arrItem.value;
                        break;
                    default:
                }
            }
        }
    }

    const getImg = (banner) => {
        const dom = window.document.querySelector(".carousel");
        const width = dom?.clientWidth || 0;

        const lang = getLanguage(location);
        switch (width) {
            case 1216:
                return lang === "ru" ? banner.imgSrcDesktop : (lang === "tr" ? banner.imgSrcDesktopTr : banner.imgSrcDesktopEn);
            case 684:
                return lang === "ru" ? banner.imgSrcTablet : (lang === "tr" ? banner.imgSrcTabletTr : banner.imgSrcTabletEn);
            case 328:
                return lang === "ru" ? banner.imgSrcMobile : (lang === "tr" ? banner.imgSrcMobileTr : banner.imgSrcMobileEn);
            default:
                return lang === "ru" ? banner.imgSrcDesktop : (lang === "tr" ? banner.imgSrcDesktopTr : banner.imgSrcDesktopEn);
        }
    }

    return <div className="ContactPage">
         <MainLayout fixedHeader={true} footer="no-form">
            <Helmet>
                <title>{getLocalizeText("pages/contact", "meta.title", getLanguage(location))}</title>
                <meta name="description" content={getLocalizeText("pages/contact", "meta.description", getLanguage(location))} />
            </Helmet>
            <div className="pageContent">
                {showBanners
                    ? <div className="carouselContainer">
                        <Carousel 
                            className="carousel" 
                            bull
                            height={224} 
                            interval={7000} 
                            animation="slide" 
                            duration={700} 
                            stopAutoPlayOnHover={true} 
                            indicatorContainerProps={{className: "bullets"}}
                            navButtonsProps={{className: "navButtons"}}
                        >
                            {banners.map(x => <img key={`banner${x.img}`} x-data-img={x.img} className="banner" src={`${getApiHost()}/static/banners/${getImg(x)}`} alt={x.name} onClick={() => onSlideClick(x)} />)}
                        </Carousel>
                    </div>
                    : null
                }

                <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                    {breadcrumbs}
                </Breadcrumbs>

                <div className="blockContent">
                    <div className="pageTitle">
                        <h1>{getLocalizeText("pages/contact", "title", getLanguage(location))}</h1>
                        <div className="empty" />
                        <div className="empty" />
                        <div className="description">
                            {getLocalizeText("pages/contact", "description", getLanguage(location))}
                        </div>
                    </div>

                    <div className="profits">
                        <div className="blocks">
                            <div className="block">
                                <div className="title">
                                    {getLocalizeText("pages/contact", "blocks.1.title", getLanguage(location))}
                                </div>
                                <div className="description">
                                    {getLocalizeText("pages/contact", "blocks.1.description", getLanguage(location))}
                                </div>
                                <div className="linkBtn" onClick={goToCarrier}>
                                    {getLocalizeText("pages/contact", "blocks.1.descriptionLink", getLanguage(location))} <EastIcon className="icon"/>
                                </div>
                            </div>
                            <div className="block">
                                <div className="title">
                                    {getLocalizeText("pages/contact", "blocks.2.title", getLanguage(location))}
                                </div>
                                <div className="description">
                                    {getLocalizeText("pages/contact", "blocks.2.description", getLanguage(location))}
                                </div>
                                <div className="linkBtn" onClick={goToTg}>
                                    {getLocalizeText("pages/contact", "blocks.2.descriptionLink", getLanguage(location))} <EastIcon className="icon"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="formContainer">
                    <h2>{getLocalizeText("pages/contact", "form.title", getLanguage(location))}</h2>
                    <div className="blocks">
                        <div className="block">
                            <div className="description">
                                {getLocalizeText("pages/contact", "form.description", getLanguage(location))}
                            </div>
                            <div className="descriptionMobile">
                                {getLocalizeText("pages/contact", "form.descriptionMobile.1", getLanguage(location))}
                                <br />
                                {getLocalizeText("pages/contact", "form.descriptionMobile.2", getLanguage(location))}
                                <br />
                                {getLocalizeText("pages/contact", "form.descriptionMobile.3", getLanguage(location))}
                                <br />
                                {getLocalizeText("pages/contact", "form.descriptionMobile.4", getLanguage(location))}
                            </div>
                        </div>
                        <div className="block">
                            <form onSubmit={(e) => onSend(e)}>
                                <div className="row grid-1-1">
                                    <div>
                                        <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
                                        <div>
                                            <TextField fullWidth required error={errName} type="text" placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))} value={name} onChange={changeName} className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}</div>
                                        <div>
                                            <TextField fullWidth type="text" placeholder={getLocalizeText("dialogs/main", "placeholderPhone", getLanguage(location))}  value={phone} onChange={changePhone} className={`${phoneSuccess ? "success" : ""}`} />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
                                    <div>
                                        <TextField fullWidth required error={errEmail} type="text" helperText={helperEmail} placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))} value={email} onChange={changeEmail} className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`} />
                                    </div>
                                </div>
                               
                                <div className="row">
                                    <div className="label">{getLocalizeText("dialogs/main", "comment", getLanguage(location))}</div>
                                    <div>
                                        <TextField fullWidth className={`textarea ${commentSuccess ? "success" : ""}`} placeholder={getLocalizeText("dialogs/main", "placeholderComment", getLanguage(location))} value={comment} onChange={changeComment} multiline={!isMobile} />
                                    </div>
                                </div>
                                
                                <div className="row last">
                                    <div className="rules">
                                        <FormControlLabel required error={errRule} helperText={helperRule} control={<Checkbox checked={rule} onChange={changeRule} />} label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)} className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`} />
                                    </div>
                                    <div className="button">
                                        <Button fullWidth className="btn" variant="contained" onClick={onSend}>{getLocalizeText("dialogs/main", "linkToUs", getLanguage(location))}</Button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                 </div>
            </div>
         </MainLayout>
    </div>
}

export default ContactPage;