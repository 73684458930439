import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment";
import AdminLayout from "../../../layouts/AdminLayout";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { api_getDomsContent } from "../../../redux/actions/domContent";
import Loader from "../../../components/Loader";
import AdminDomContentEditDialog from "./AdminDomContentEditDialog";

const AdminDomContentPage = () => {
    const columns = [
        { 
            field: "id",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            sortable: false,
            filterable: false,
            width: 60,
            renderCell: (props) => {
                return <div>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => openEditDialog(props.row)}><EditIcon className="icon" /></Button>
                </div>
            }
        },
        { 
            field: "code",
            headerName: "Код",
            width: 160
        },
        { 
            field: "description",
            headerName: "Описание",
            flex: 1
        },
        { 
            field: "value",
            headerName: "Значение",
            width: 200
        },
        
        {
            field: "createdAt",
            headerName: "Создан",
            width: 100,
            type: "datetime",
            valueGetter: (props) => {
                return moment(props.row.ETA).format("DD.MM.YYYY");
            }
        }
    ];

    const [data, setData] = useState([]);
    const [isEditDialogOpen, setIsEditDialogOpen]= useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setIsLoading(true);
        setData((await api_getDomsContent()).map(x => {
            x.id = x._id;
            return x;
        }));
        setIsLoading(false);
    }

    const update = () => {
        getData();
    }

    const openEditDialog = (item) => {
        setSelectedItem(item);
        setIsEditDialogOpen(true);
    }

    const closeEditDialog = () => {
        setIsEditDialogOpen(false);
        setSelectedItem(null);
    }

    const onSave = () => {
        update();
    }

    return <div className="AdminDomContentPage">
        <AdminLayout>
            <h1>Содержимое Dom блоков</h1>

            <div className="content">
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminDomContentEditDialog title="Редактирование контента" open={isEditDialogOpen} onClose={closeEditDialog} onSave={onSave} item={selectedItem} />
            <Loader active={isLoading} />
        </AdminLayout>
    </div>
}

export default AdminDomContentPage;