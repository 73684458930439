import { Autocomplete, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_getPortsOnlyByQuery } from "../../../../redux/actions";
import img_an_b from "../../../../assets/imgs/anchor_b.svg";
import { api_addSearchTag, api_saveSearchTag } from "../../../../redux/actions/tag";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";

const errTextFillField = "Заполните поле";

const AdminSearchTagEditDialog = (props) => {
    const allPorts = useSelector(state => state.port.data);

    const [name, setName] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [nameTr, setNameTr] = useState("");
    const [pol, setPOL] = useState("");
    const [optionsPOL, setOptionsPOL] = useState([]);
    const [loadingPOL, setLoadingPOL] = useState(false);
    const [pod, setPOD] = useState("");
    const [optionsPOD, setOptionsPOD] = useState([]);
    const [loadingPOD, setLoadingPOD] = useState(false);
    const [active, setActive] = useState(true);

    const [errName, setErrName] = useState(false);
    const [hName, setHName] = useState("");
    const [errPOL, setErrPOL] = useState(false);
    const [hPOL, setHPOL] = useState("");
    const [errPOD, setErrPOD] = useState(false);
    const [hPOD, setHPOD] = useState("");

    useEffect(() => {
        if (props.item) {
            const _pol = allPorts.find(x => x.POL_en === props.item.POL);
            const _pod = allPorts.find(x => x.POL_en === props.item.POD);
            if (_pol && _pod) {
                setName(props.item.name);
                setNameEn(props.item.nameEn);
                setNameTr(props.item.nameTr);
                setPOL(_pol.POL_ru);
                setPOD(_pod.POL_ru);
                setActive(props.item.active);
            }
        }
    }, [props.item, allPorts]);

    useEffect(() => {
        setOptionsPOL(allPorts.filter((x, i) => i <= 10));
        setOptionsPOD(allPorts.filter((x, i) => i <= 10));
    }, [allPorts]);

    useEffect(() => {
        if (optionsPOL.length && typeof optionsPOL[0]._id != "undefined")
            setOptionsPOL(optionsPOL.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: x.POL_ru,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            }));
    }, [optionsPOL]);

    useEffect(() => {
        if (optionsPOD.length && typeof optionsPOD[0]._id != "undefined")
            setOptionsPOD(optionsPOD.map(x => {
                return {
                    id: x._id.toString(),
                    value: x.POL_en,
                    label: x.POL_ru,
                    description: `${x.country_ru}, ${x.POL_code}`
                }
            }));
    }, [optionsPOD]);

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changeNameEn = (e) => {
        setNameEn(e.target.value);
    }

    const changeNameTr = (e) => {
        setNameTr(e.target.value);
    }

    const onChangePOL = (event, newValue) => {
        setPOL(newValue);
    }

    const onChangePOD = (event, newValue) => {
        setPOD(newValue);
    }

    const changeActive = (e) => {
        setActive(e.target.checked);
    }

    const resetForm = () => {
        clearErrors();

        setName("");
        setNameEn("");
        setNameTr("");
        setPOL("");
        setPOD("");
        setActive(true);
    }

    const clearErrors = () => {
        setErrName(false);
        setHName("");
        setErrPOL(false);
        setHPOL("");
        setErrPOD(false);
        setHPOD("");
    }

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
        resetForm();
    }

    const onSave = async () => {
        clearErrors();

        let hasErrors = false;

        if (!name.trim().length) {
            hasErrors = true;
            setErrName(true);
            setHName(errTextFillField);
        }

        if (!pol) {
            hasErrors = true;
            setErrPOL(true);
            setHPOL(errTextFillField);
        }

        if (!pod) {
            hasErrors = true;
            setErrPOD(true);
            setHPOD(errTextFillField);
        }

        if (!hasErrors) {
            const data = {
                name,
                nameEn,
                nameTr,
                POL: pol.value,
                POD: pod.value,
                active
            }

            let r = null;
            if (props.item?._id) {
                r = await api_saveSearchTag(props.item._id, data);
            }
            else {
                r = await api_addSearchTag(data);
            }

            if (r) {
                if (typeof props.onSave === "function")
                    props.onSave();
                    onClose();

                    notification.addNotification({
                        message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Тэг был успешно сохранен</span></div>,
                        type: "success",
                        insert: "top",
                        container: "bottom-right",
                        dismiss: {
                            duration: 5000
                            }
                    });
            }
            else {
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> Не удалось сохранить тэг</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
            }
        }
    }

    return <Dialog className="AdminSearchTagEditDialog" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="row">
                    <TextField required fullWidth size="small" label="Наименование, ru" variant="outlined" value={name} onChange={changeName} error={errName} helperText={hName} />
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Наименование, en" variant="outlined" value={nameEn} onChange={changeNameEn} />
                </div>

                <div className="row">
                    <TextField fullWidth size="small" label="Наименование, tr" variant="outlined" value={nameTr} onChange={changeNameTr} />
                </div>

                <div className="row">
                    <Autocomplete
                        required
                        size="small"
                        disablePortal
                        value={pol}
                        onChange={onChangePOL}
                        options={optionsPOL}
                        renderInput={(params) => <>
                            <TextField {...params} 
                                error={errPOL}
                                helperText={hPOL}
                                label="Отправка" 
                                onChange={async (e) => {
                                    if (e.target.value.trim().length) {
                                        setLoadingPOL(true);
                                        const r = await api_getPortsOnlyByQuery(e.target.value);
                                        setLoadingPOL(false);
                                        setOptionsPOL(r);
                                    }
                                    else {
                                        setOptionsPOL(allPorts.filter((x, i) => i <= 10));
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={img_an_b} alt="anchor"/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                        {loadingPOL ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </>}
                        renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                            <div className="label">{option.label}</div>
                            <div className="description">{option.description}</div>
                        </div>}
                        filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}
                    />
                </div>

                <div className="row">
                    <Autocomplete
                        required
                        size="small"
                        disablePortal
                        value={pod}
                        onChange={onChangePOD}
                        options={optionsPOD}
                        renderInput={(params) => <>
                            <TextField {...params} 
                                error={errPOD}
                                helperText={hPOD}
                                label="Прибытие" 
                                onChange={async (e) => {
                                    if (e.target.value.trim().length) {
                                        setLoadingPOD(true);
                                        const r = await api_getPortsOnlyByQuery(e.target.value);
                                        setLoadingPOD(false);
                                        setOptionsPOD(r);
                                    }
                                    else {
                                        setOptionsPOD(allPorts.filter((x, i) => i <= 10));
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={img_an_b} alt="anchor"/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                        {loadingPOD ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        </>}
                        renderOption={(props, option) => <div {...props} key={option.id} className={`${props.className} dropDownOption`}>
                            <div className="label">{option.label}</div>
                            <div className="description">{option.description}</div>
                        </div>}
                        filterOptions={(options) => options.length && typeof options[0].id !== "undefined" ? options : []}
                    />
                </div>

                <div className="row">
                    <FormControlLabel control={<Checkbox checked={active} onChange={changeActive} />} label="Активно" />
                </div>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default AdminSearchTagEditDialog;