import React from "react";
import "./style.scss";

const CardText = ({ title, subtitle }) => {

    return (
        <div className="CardText">
            <h3 className="CardTitle">{title}</h3>
            <p className="CardSubtitle">{subtitle}</p>
        </div>
    );
};

export default CardText;
