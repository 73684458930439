import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import {ReactComponent as KeyboardArrowUpIcon} from '../../assets/icons/arrowUp.svg';
import { Tooltip } from "@mui/material";
import InfoIcon from "../InfoIcon";

const CollapsedBlock = (props) => {

    const [open, setOpen] = useState(true);
    const [mounted, setMounted] = useState(false);

    const ref_content = useRef(null);

    useEffect(() => {
        if (props.filterOpen && !mounted) {
            setMounted(true);
            ref_content.current.style.maxHeight = "max-content";
            setTimeout(() => {
                ref_content.current.style.maxHeight = `${ref_content.current.clientHeight}px`;
                setOpen(props.open);
                setTimeout(() => {ref_content.current.style.transition = "max-height 0.5s ease-in-out"}, 100);
            }, 100);
        }
    }, [props.children, props.filterOpen]);


    const toggleCollapse = () => {
        setOpen(value => !value);
    }

    return <div className="CollapsedBlock">
        <div className="header" onClick={toggleCollapse}>
            <div className="title">
                {props.title}
                {typeof props.tooltip === "string"
                    ? <Tooltip title={props.tooltip} className="iContainer" placement="top" arrow>
                        <div><InfoIcon color="#94A3B8" /></div>
                    </Tooltip>
                    : null
                }
            </div>
            <div className="arrowContainer">
                {!open
                    ? <KeyboardArrowUpIcon className="arrow down" />
                    : <KeyboardArrowUpIcon className="arrow" />
                }
            </div>
        </div>
        <div ref={ref_content} className={`content ${open ? "" : "close"}`}>{props.children}</div>
    </div>
}

export default CollapsedBlock;