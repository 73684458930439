import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getRoutes = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/routes`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_saveRoute = async (id, route) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/routes/${id}`;
        const data = (await axios.put(`${apiHost}${api}`, route, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_addRoute = async (route) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/routes`;
        const data = (await axios.post(`${apiHost}${api}`, route, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}