import { renderToString } from 'react-dom/server'
import { api_addReqLog } from "../redux/actions/reqLog";
import MailTemplateToCustomer from "../mails/MailTemplateToCustomer";
import MailTemplateToCustomerEn from "../mails/MailTemplateToCustomerEn";
import React from "react";

export const getMailTemplate = (component) => {
    return renderToString(component);
}

export const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

export const reqLog = async (type, data) => {
    const reqLog = {
        type,
        data: { ...data }
    };

    await api_addReqLog(reqLog);
}

export const getClientMsg = (lang, text) => {
    return lang === "ru"
        ? getMailTemplate(<MailTemplateToCustomer text={text} />)
        : getMailTemplate(<MailTemplateToCustomerEn text={text} />);
}
