import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { file_allowedImageExt } from "../../../../../misc/file";
import { Store as notification } from 'react-notifications-component';
import { Error } from "@mui/icons-material";

const allowedFileTypesText = "";
const notEmptyText = "Заполните поле";

const EditBannerContactsForm = (props) => {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");

    const [imgSrcDesktop, setImgSrcDesktop] = useState("");
    const [imgSrcTablet, setImgSrcTablet] = useState("");
    const [imgSrcMobile, setImgSrcMobile] = useState("");
    const [imgSrcDesktopEn, setImgSrcDesktopEn] = useState("");
    const [imgSrcTabletEn, setImgSrcTabletEn] = useState("");
    const [imgSrcMobileEn, setImgSrcMobileEn] = useState("");
    const [imgSrcDesktopTr, setImgSrcDesktopTr] = useState("");
    const [imgSrcTabletTr, setImgSrcTabletTr] = useState("");
    const [imgSrcMobileTr, setImgSrcMobileTr] = useState("");

    const [onBannerClick, setOnBannerClick] = useState("-");
    const [link, setLink] = useState("");
    
    const [errName, setErrName] = useState(false);
    const [errImgSrcDesktop, setErrImgSrcDesktop] = useState(false);
    const [errImgSrcTablet, setErrImgSrcTablet] = useState(false);
    const [errImgSrcMobile, setErrImgSrcMobile] = useState(false);
    const [errImgSrcDesktopEn, setErrImgSrcDesktopEn] = useState(false);
    const [errImgSrcTabletEn, setErrImgSrcTabletEn] = useState(false);
    const [errImgSrcMobileEn, setErrImgSrcMobileEn] = useState(false);
    const [errImgSrcDesktopTr, setErrImgSrcDesktopTr] = useState(false);
    const [errImgSrcTabletTr, setErrImgSrcTabletTr] = useState(false);
    const [errImgSrcMobileTr, setErrImgSrcMobileTr] = useState(false);

    const [helperName, setHelperName] = useState("");
    const [helperImgSrcDesktop, setHelperImgSrcDesktop] = useState(allowedFileTypesText);
    const [helperImgSrcTablet, setHelperImgSrcTablet] = useState(allowedFileTypesText);
    const [helperImgSrcMobile, setHelperImgSrcMobile] = useState(allowedFileTypesText);
    const [helperImgSrcDesktopEn, setHelperImgSrcDesktopEn] = useState(allowedFileTypesText);
    const [helperImgSrcTabletEn, setHelperImgSrcTabletEn] = useState(allowedFileTypesText);
    const [helperImgSrcMobileEn, setHelperImgSrcMobileEn] = useState(allowedFileTypesText);
    const [helperImgSrcDesktopTr, setHelperImgSrcDesktopTr] = useState(allowedFileTypesText);
    const [helperImgSrcTabletTr, setHelperImgSrcTabletTr] = useState(allowedFileTypesText);
    const [helperImgSrcMobileTr, setHelperImgSrcMobileTr] = useState(allowedFileTypesText);

    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
        resetForm();
    }

    const resetForm = () => {
        setName("");
        setDescription("");
        setImgSrcDesktop("");
        setImgSrcTablet("");
        setImgSrcMobile("");
        setImgSrcDesktopEn("");
        setImgSrcTabletEn("");
        setImgSrcMobileEn("");
        setImgSrcDesktopTr("");
        setImgSrcTabletTr("");
        setImgSrcMobileTr("");
        setOnBannerClick("-");
        setLink("");

        clearError();    
    }

    const clearError = () => {
        setErrName(false);
        setHelperName("");

        setErrImgSrcDesktop(false);
        setHelperImgSrcDesktop(allowedFileTypesText);

        setErrImgSrcTablet(false);
        setHelperImgSrcTablet(allowedFileTypesText);

        setErrImgSrcMobile(false);
        setHelperImgSrcMobile(allowedFileTypesText);

        setErrImgSrcDesktopEn(false);
        setHelperImgSrcDesktopEn(allowedFileTypesText);

        setErrImgSrcTabletEn(false);
        setHelperImgSrcTabletEn(allowedFileTypesText);

        setErrImgSrcMobileEn(false);
        setHelperImgSrcMobileEn(allowedFileTypesText);

        setErrImgSrcDesktopTr(false);
        setHelperImgSrcDesktopTr(allowedFileTypesText);

        setErrImgSrcTabletTr(false);
        setHelperImgSrcTabletTr(allowedFileTypesText);

        setErrImgSrcMobileTr(false);
        setHelperImgSrcMobileTr(allowedFileTypesText);
    }

    const validate = () => {
        clearError();

        let hasNoErrors = true;
        
        if (name.trim() === "") {
            hasNoErrors = false;
            setErrName(true);
            setHelperName(notEmptyText);
        }

        if (imgSrcDesktop.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcDesktop(true);
            setHelperImgSrcDesktop(notEmptyText);
        }

        if (imgSrcTablet.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcTablet(true);
            setHelperImgSrcTablet(notEmptyText);
        }

        if (imgSrcMobile.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcMobile(true);
            setHelperImgSrcMobile(notEmptyText);
        }

        if (imgSrcDesktopEn.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcDesktopEn(true);
            setHelperImgSrcDesktopEn(notEmptyText);
        }

        if (imgSrcTabletEn.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcTabletEn(true);
            setHelperImgSrcTabletEn(notEmptyText);
        }

        if (imgSrcMobileEn.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcMobileEn(true);
            setHelperImgSrcMobileEn(notEmptyText);
        }

        if (imgSrcDesktopTr.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcDesktopTr(true);
            setHelperImgSrcDesktopTr(notEmptyText);
        }

        if (imgSrcTabletTr.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcTabletTr(true);
            setHelperImgSrcTabletTr(notEmptyText);
        }

        if (imgSrcMobileTr.trim() === "") {
            hasNoErrors = false;
            setErrImgSrcMobileTr(true);
            setHelperImgSrcMobileTr(notEmptyText);
        }

        return hasNoErrors;
    }

    const onSave = () => {
        if (typeof props.onSave === "function") {
            if (! validate()) return false;
            let formData = new FormData();

            formData.append('name', name);
            formData.append('description', description);
            formData.append('onClick', `${onBannerClick}:${link}`);

            let files = document.querySelector("input[name='imgSrcDesktop']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcDesktop', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcTablet']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcTablet', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcMobile']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcMobile', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcDesktopEn']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcDesktopEn', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcTabletEn']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcTabletEn', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcMobileEn']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcMobileEn', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcDesktopTr']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcDesktopTr', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcTabletTr']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcTabletTr', file);
            }
            else {
                return false;
            }

            files = document.querySelector("input[name='imgSrcMobileTr']").files;
            if (files.length === 1) {
                const file = files[0];
                if (!fileValidate(file)) return false;
                formData.append('imgSrcMobileTr', file);
            }
            else {
                return false;
            }

            formData.append('type', "contacts_page");

            props.onSave(formData);
            resetForm();
        }
    }

    const fileValidate = (file) => {
        // if (file_allowedImageExt.indexOf(file.type) === -1) {
        //     notification.addNotification({
        //         message: <div><Error /><span className="snackErrorText"> Недопустимый тип файла</span></div>,
        //         type: "danger",
        //         insert: "top",
        //         container: "bottom-right",
        //         dismiss: {
        //             duration: 5000
        //             }
        //     });
        //     return false;
        // }
        return true;
    }

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const onUploadDesktop = () => {
        let files = document.querySelector("input[name='imgSrcDesktop']").files;
        if (files.length === 1) {
            const file = files[0];
            //if (fileValidate(file)) {
                setImgSrcDesktop(file.name);
                return;
            //}
        }
        
        setImgSrcDesktop("");
    }

    const onUploadDesktopEn = () => {
        let files = document.querySelector("input[name='imgSrcDesktopEn']").files;
        if (files.length === 1) {
            const file = files[0];
            //if (fileValidate(file)) {
                setImgSrcDesktopEn(file.name);
                return;
            //}
        }
        
        setImgSrcDesktopEn("");
    }

    const onUploadDesktopTr = () => {
        let files = document.querySelector("input[name='imgSrcDesktopTr']").files;
        if (files.length === 1) {
            const file = files[0];
            //if (fileValidate(file)) {
                setImgSrcDesktopTr(file.name);
                return;
            //}
        }
        
        setImgSrcDesktopTr("");
    }

    const onUploadTablet = () => {
        let files = document.querySelector("input[name='imgSrcTablet']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcTablet(file.name);
                return;
            }
        }
        
        setImgSrcTablet("");
    }

    const onUploadTabletEn = () => {
        let files = document.querySelector("input[name='imgSrcTabletEn']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcTabletEn(file.name);
                return;
            }
        }
        
        setImgSrcTabletEn("");
    }

    const onUploadTabletTr = () => {
        let files = document.querySelector("input[name='imgSrcTabletTr']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcTabletTr(file.name);
                return;
            }
        }
        
        setImgSrcTabletTr("");
    }

    const onUploadMobile = () => {
        let files = document.querySelector("input[name='imgSrcMobile']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcMobile(file.name);
                return;
            }
        }
        
        setImgSrcMobile("");
    }

    const onUploadMobileEn = () => {
        let files = document.querySelector("input[name='imgSrcMobileEn']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcMobileEn(file.name);
                return;
            }
        }
        
        setImgSrcMobileEn("");
    }

    const onUploadMobileTr = () => {
        let files = document.querySelector("input[name='imgSrcMobileTr']").files;
        if (files.length === 1) {
            const file = files[0];
            if (fileValidate(file)) {
                setImgSrcMobileTr(file.name);
                return;
            }
        }
        
        setImgSrcMobileTr("");
    }

    const changeOnBannerClick = (e) => {
        setOnBannerClick(e.target.value);
        if (e.target.value !== "link")
            setLink("");
    }

    const changeLink = (e) => {
        setLink(e.target.value);
    }

    return <Dialog className="EditBannerContactsForm" open={props.open}>
        <DialogTitle>
            {props.title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <div className="row">
                    <TextField fullWidth size="small" label="Название" variant="outlined" value={name} onChange={onNameChange} error={errName} helperText={helperName} />
                </div>
                <div className="row">
                    <TextField fullWidth label="Описание" variant="outlined" multiline maxRows={1} minRows={1} value={description} onChange={onDescriptionChange} />
                </div>
                <div className="row file">
                    <TextField size="small" label="Слайд для десктопа" variant="outlined" disabled value={imgSrcDesktop} helperText={helperImgSrcDesktop} error={errImgSrcDesktop} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcDesktop" hidden onChange={onUploadDesktop}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для десктопа, en" variant="outlined" disabled value={imgSrcDesktopEn} helperText={helperImgSrcDesktopEn} error={errImgSrcDesktopEn} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcDesktopEn" hidden onChange={onUploadDesktopEn}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для десктопа, tr" variant="outlined" disabled value={imgSrcDesktopTr} helperText={helperImgSrcDesktopTr} error={errImgSrcDesktopTr} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcDesktopTr" hidden onChange={onUploadDesktopTr}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для планшета" variant="outlined" disabled value={imgSrcTablet} helperText={helperImgSrcTablet} error={errImgSrcTablet} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcTablet" hidden onChange={onUploadTablet}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для планшета, en" variant="outlined" disabled value={imgSrcTabletEn} helperText={helperImgSrcTabletEn} error={errImgSrcTabletEn} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcTabletEn" hidden onChange={onUploadTabletEn}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для планшета, tr" variant="outlined" disabled value={imgSrcTabletTr} helperText={helperImgSrcTabletTr} error={errImgSrcTabletTr} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcTabletTr" hidden onChange={onUploadTabletTr}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для телефона" variant="outlined" disabled value={imgSrcMobile} helperText={helperImgSrcMobile} error={errImgSrcMobile} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcMobile" hidden onChange={onUploadMobile}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для телефона, en" variant="outlined" disabled value={imgSrcMobileEn} helperText={helperImgSrcMobileEn} error={errImgSrcMobileEn} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcMobileEn" hidden onChange={onUploadMobileEn}/>
                    </Button>
                </div>

                <div className="row file">
                    <TextField size="small" label="Слайд для телефона, tr" variant="outlined" disabled value={imgSrcMobileTr} helperText={helperImgSrcMobileTr} error={errImgSrcMobileTr} />
                    <Button variant="contained" component="label" className="uploadBtn">
                        Загрузить файл
                        <input type="file" name="imgSrcMobileTr" hidden onChange={onUploadMobileTr}/>
                    </Button>
                </div>

                <div className="row">
                    <FormControl fullWidth size="small">
                        <InputLabel>Клик</InputLabel>
                        <Select
                            size="small"
                            value={onBannerClick}
                            label="Клик"
                            onChange={changeOnBannerClick}
                        >
                        <MenuItem value={"-"}>Ничего</MenuItem>
                        <MenuItem value={"moreInfoDialog"}>Диалог</MenuItem>
                        <MenuItem value={"link"}>Ссылка</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={`row ${onBannerClick === "link" ? "" : " hide"}`}>
                    <TextField size="small" required label="Ссылка" fullWidth value={link} onChange={changeLink} />
                </div>
                
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
            <Button variant="contained" onClick={onSave}>Сохранить</Button>
        </DialogActions>
    </Dialog>
}

export default EditBannerContactsForm;