import {
    ROUTES_CLEAR,
    ROUTES_FETCHED,
    ROUTES_FETCHING,
    ROUTES_SEARCH
} from "../types";

const initState = {
    fetching: false,
    fetched: false,
    data: {},
    type: null,
    limits: {},
    validTill: []
};

const routeReducer = (state = initState, action) => {
    const {type, payload} = action;

    switch(type) {
        case ROUTES_SEARCH: 
            state.data = payload.data;
            state.type = payload.type;
            state.limits = payload.limits;
            state.validTill = payload.freightValidTill;
            return {...state};
        case ROUTES_CLEAR: 
            state.data = {};
            return {...state};
        case ROUTES_FETCHING: 
            state.fetching = payload
            return {...state};
        case ROUTES_FETCHED: 
            state.fetched = payload
            return {...state};

        default:
            return state;
    }
}

export default routeReducer;