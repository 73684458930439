/* eslint-disable no-control-regex */
import { Tab, Tabs } from "@mui/material";
import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import "./style.scss";
import AdminBannersCarrierPage from "./AdminBannersCarrierPage";
import AdminBannersContactsPage from "./AdminBannersContactsPage";
import AdminBannersSearchPage from "./AdminBannersSearchPage";


const AdminBannerPage = () => {
    const [activeTab, setActiveTab] = React.useState(0);

    const onChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    return <div className="AdminBannerPage">
        <AdminLayout>
            <h1>Баннеры</h1>
            <div className="tabs">
                <Tabs value={activeTab} onChange={onChangeTab}>
                    <Tab label="Баннеры 'Грузоперевозчикам'" />
                    <Tab label="Баннеры 'Контакты'" />
                    <Tab label="Баннеры 'Поиск'" />
                </Tabs>
            </div>
            <div className="tabsContent">
                <div className={activeTab !== 0 ? "hide" : ""}>
                    <AdminBannersCarrierPage />
                </div>
                <div className={activeTab !== 1 ? "hide" : ""}>
                    <AdminBannersContactsPage />
                </div>
                <div className={activeTab !== 2 ? "hide" : ""}>
                    <AdminBannersSearchPage />
                </div>
            </div>
        </AdminLayout>
    </div>
}

export default AdminBannerPage;