/* eslint-disable no-control-regex */
import { Button } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import UDilaog from "../../../../components/dialogs/UDialog";
import Loader from "../../../../components/Loader";
import { getApiHost } from "../../../../misc/api";
import "./style.scss";
import { Delete } from "@mui/icons-material";
import ConfirmDialog from "../../../../components/dialogs/Confirm";
import { Store as notification } from 'react-notifications-component';
import { Error } from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { api_addBanner, api_deleteBanner, api_editBanner, api_getBanners } from "../../../../redux/actions/banners";
import EditBannerContactsForm from "./EditBannerContactsForm";

const AdminBannersContactsPage = () => {
    const [banners, setBanners] = useState([]);
    const [item, setItem] = useState(null);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [editDialogTitle, setEditDialogTitle] = useState("");
    const [isLoaderOpen, setIsLoaderOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [imgTitle, setImgTitle] = useState("");
    const [imgContent, setImgContent] = useState("");
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [dragItemId, setDragItemId] = useState(null);
    const [dragBottomItemId, setDragBottomItemId] = useState(null);

    useLayoutEffect(() => {
        getBanners();
    }, []);

    const getBanners = async () => {
        setIsLoaderOpen(true);
        const r = (await api_getBanners()).filter(x => x.type === "contacts_page");
        setBanners(r);
        setIsLoaderOpen(false);
    }

    const onAddBannerClick = () => {
        setEditDialogTitle("Добавление баннера");
        setIsOpenEditDialog(true);
    }

    const onCloseEditDialog = () => {
        setIsOpenEditDialog(false);
    };

    const onEditDialogSave = async (data) => {
        setIsLoaderOpen(true);

        const r = await api_addBanner(data);
        onCloseEditDialog();

        await getBanners();
        setIsLoaderOpen(false);
    }

    const openImg = (img, src) => {
        setImgTitle(img.name);
        setImgContent(<img style={{maxWidth: "100%", maxHeight: "100%"}} src={`${getApiHost()}/static/banners/${src}`} alt={`${img.name}-dialog`} />);
        setImgOpen(true);
    }

    const onImgClose = () => {
        setImgOpen(false);
    }

    const onDeleteBanner = (banner) => {
        setItem(banner);
        setConfirmOpen(true);
    }

    const onCloseConfirm = () => {
        setConfirmOpen(false);
    }

    const onConfirm = async () => {
        if (item && item._id) {
            setIsLoaderOpen(true);
            const r = await api_deleteBanner(item._id);
            if (r) {
                notification.addNotification({
                    message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Баннер был успешно удален</span></div>,
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
                await getBanners();
            }
            else {
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> Не удалось удалить баннер</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
            }
            setItem(null);
            onCloseConfirm();
            setIsLoaderOpen(false);
        }
    }

    const onDragStart = (e, banner) => {
        setDragItemId(banner._id);
    }

    const onDragEnd = (e, banner) => {
        setTimeout(() => {
            setDragItemId(null);
            setDragBottomItemId(null);
        }, 100);
    }

    const onDragOver = (e, banner) => {
        e.preventDefault();
        setDragBottomItemId(banner._id)
    }

    const onDrop = async (e, banner) => {
        e.preventDefault(); 
        let t;
        const dragIndex = banners.findIndex(x => x._id === dragItemId);
        const dropIndex = banners.findIndex(x => x._id === dragBottomItemId);
        if (dragIndex === dropIndex) return false;
        setIsLoaderOpen(true);
        if (dragIndex < dropIndex) {
            t = banners.slice(0, dragIndex);
            t.push(banners[dropIndex]);
            const tt = banners.slice(dragIndex + 1, dropIndex);
            t = t.concat(tt);
            t.push(banners[dragIndex]);
            t = t.concat(banners.slice(dropIndex + 1, banners.length));
            setBanners(t);
        }
        if (dragIndex > dropIndex) {
            t = banners.slice(0, dropIndex);
            t.push(banners[dragIndex]);
            const tt = banners.slice(dropIndex + 1, dragIndex);
            t = t.concat(tt);
            t.push(banners[dropIndex]);
            t = t.concat(banners.slice(dragIndex + 1, banners.length));
            setBanners(t);
        }
        for (let i = 0; i < t.length; i ++) {
            let _banner = t[i];
            _banner.sort = i + 1;
            await api_editBanner(_banner._id.toString(), _banner);
        }
        setIsLoaderOpen(false);
        return true;
    }

    return <div className="AdminBannersContactsPage">
        <div className="buttons">
            <Button variant="contained" onClick={onAddBannerClick}>Добавить</Button>
        </div>
        <div className="banners">
            <div className="banner header">
                <div></div>
                <div>Название</div>
                <div>Описание</div>
                <div>Десктоп</div>
                <div>Планшет</div>
                <div>Телефон</div>
            </div>
            {banners.map((x, index) => 
                <div 
                    className={`banner${index === banners.length - 1 ? " noBorders" : ""}${dragItemId && dragItemId === x._id ? " drag" : ""}${dragBottomItemId && dragBottomItemId === x._id ? " dragBottom" : ""}`} 
                    draggable={true} 
                    onDragStart={(e) => onDragStart(e, x)} 
                    onDragOver={(e) => onDragOver(e, x)} 
                    onDragEnd={(e) => onDragEnd(e, x)} 
                    onDrop={e => onDrop(e, x)}
                >
                    <div><Button variant="outlined" color="error" title="Удалить" onClick={() => onDeleteBanner(x)}><Delete /></Button></div>
                    <div>
                        <ImportExportIcon className="sort" />
                        {x.name}
                    </div>
                    <div title={x.description}>{x.description?.replace(new RegExp("/\n/g"), " ")}</div>
                    <div>
                        <div className="bannersContainer">
                            <img className="img" title="Desctop, ru" src={`${getApiHost()}/static/banners/${x.imgSrcDesktop}`} alt={`${x.name}-desktop`} onClick={() => openImg(x, x.imgSrcDesktop)} />
                            <img className="img" title="Desctop, en" src={`${getApiHost()}/static/banners/${x.imgSrcDesktopEn}`} alt={`${x.name}-desktopEn`} onClick={() => openImg(x, x.imgSrcDesktopEn)} />
                            <img className="img" title="Desctop, tr" src={`${getApiHost()}/static/banners/${x.imgSrcDesktopTr}`} alt={`${x.name}-desktopTr`} onClick={() => openImg(x, x.imgSrcDesktopTr)} />
                        </div>
                    </div>
                    <div>
                        <div className="bannersContainer">
                            <img className="img" title="Tablet, ru" src={`${getApiHost()}/static/banners/${x.imgSrcTablet}`} alt={`${x.name}-tablet`} onClick={() => openImg(x, x.imgSrcTablet)} />
                            <img className="img" title="Tablet, en" src={`${getApiHost()}/static/banners/${x.imgSrcTabletEn}`} alt={`${x.name}-tabletEn`} onClick={() => openImg(x, x.imgSrcTabletEn)} />
                            <img className="img" title="Tablet, tr" src={`${getApiHost()}/static/banners/${x.imgSrcTabletTr}`} alt={`${x.name}-tabletTr`} onClick={() => openImg(x, x.imgSrcTabletTr)} />
                        </div>
                    </div>
                    <div>
                        <div className="bannersContainer">
                            <img className="img" title="Mobile, ru" src={`${getApiHost()}/static/banners/${x.imgSrcMobile}`} alt={`${x.name}-mobile`} onClick={() => openImg(x, x.imgSrcMobile)} />
                            <img className="img" title="Mobile, en" src={`${getApiHost()}/static/banners/${x.imgSrcMobileEn}`} alt={`${x.name}-mobileEn`} onClick={() => openImg(x, x.imgSrcMobileEn)} />
                            <img className="img" title="Mobile, tr" src={`${getApiHost()}/static/banners/${x.imgSrcMobileTr}`} alt={`${x.name}-mobileTr`} onClick={() => openImg(x, x.imgSrcMobileTr)} />
                        </div>
                    </div>
                </div>
            )}
        </div>
        
        <Loader active={isLoaderOpen} />
        <EditBannerContactsForm title={editDialogTitle} item={item} open={isOpenEditDialog} onClose={onCloseEditDialog} onSave={onEditDialogSave} />
        <UDilaog open={imgOpen} title={imgTitle} content={imgContent} onClose={onImgClose} />
        <ConfirmDialog open={confirmOpen} title="Удаление" content="Вы точно желаете удалить слайд?" onClose={onCloseConfirm} onConfirm={onConfirm} />
    </div>
}

export default AdminBannersContactsPage;