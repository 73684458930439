import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import pack from '../package.json';

console.log(`App FRONTEND version: ${pack.version}. Updated at: ${pack.date}.`);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
