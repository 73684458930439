import React, { memo } from "react";
import { getHost } from "../../misc/api";

const MailToCustomerEn = memo(() => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <div
          style={{
            padding: "27px 60px",
            borderBottom: "1px solid #E2E8F0",
            height: "80px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "30px",
              marginRight: "8px",
              display: "inline-block",
            }}
          >
            <a
              style={{ textDecoration: "none !important" }}
              href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}
            >
              <img
                style={{ width: "100%" }}
                src={`${getHost()}/images/logo.png`}
                alt="logo"
              />
            </a>
          </div>
          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "700",
              fontSize: "30px",
              lineHeight: "14px",
              marginTop: "4px",
              display: "inline-block",
            }}
          >
            <a
              style={{
                textDecoration: "none !important",
                color: "#000000 !important",
              }}
              href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}
            >
              sealogic
            </a>
          </div>
        </div>

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            Thank you for contacting SeaLogic!
          </div>

          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "normal",
              fontsize: "12px",
              color: "#334155",
              marginTop: "12px",
            }}
          >
            Your request is being processed. We will get in touch with you
            shortly
          </div>

          <div
            style={{
              marginTop: "44px",
              fontFamily: "Arial",
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *This message was automatically generated. Please do not reply to it
          </div>
        </div>
        <div style={{ backgroundColor: "#E9F3FD", padding: "36px 60px" }}>
          <div style={{ display: "inline-block", width: "360px" }}>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://top.sealogic.io?utm_source=mail&utm_medium=mail&utm_campaign=line_rate"
            >
              Rating of Carriers{" "}
              <img
                src={`${getHost()}/images/new.png`}
                style={{
                  width: "35px",
                  height: "20px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </a>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/about?utm_source=mail&utm_medium=mail&utm_campaign=company"
            >
              Company
            </a>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/carrier?utm_source=mail&utm_medium=mail&utm_campaign=carrier"
            >
              Cooperation
            </a>
            <a
              style={{
                display: "block",
                height: "24px",
                marginBottom: "10px",
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                textDecoration: "none !important",
              }}
              href="https://sealogic.io/contact?utm_source=mail&utm_medium=mail&utm_campaign=contact"
            >
              Contacts
            </a>
          </div>
          <div style={{ display: "inline-block" }}>
            <div
              style={{
                fontFamily: "Arial",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#0F172A",
                fontWeight: "600",
              }}
            >
              Contact us
            </div>
            <div style={{ marginTop: "16px" }}>
              <a
                href="https://t.me/sealogic?utm_source=telegram&utm_medium=email&utm_campaign=chanel"
                style={{ marginRight: "12px" }}
              >
                <img
                  style={{
                    textDecoration: "none !important",
                    width: "36px",
                    height: "36px",
                  }}
                  src={`${getHost()}/images/tg.png`}
                  alt="telegram"
                />
              </a>
              <a href="mailto:info@sealogic.io">
                <img
                  style={{
                    textDecoration: "none !important",
                    width: "36px",
                    height: "36px",
                  }}
                  src={`${getHost()}/images/gmail.png`}
                  alt="mail"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
});

export default MailToCustomerEn;