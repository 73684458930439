/* eslint-disable react-hooks/exhaustive-deps */
import { Breadcrumbs, Checkbox, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import "./style.scss";
import SearchBar from "../../components/SearchBar";
import SearchResult from "../../components/SearchResult";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { api_clearRoutes } from "../../redux/actions";
import { combinePortsAndCities } from "../../misc/api";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";
import img_puzzles from "../../assets/imgs/puzzles.png";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";
import DialogRequest from "../../components/dialogs/Request";


const SearchPage = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const ref_searchLoader = useRef(null);

    const breadcrumbs = [
        <Link key="1" to={getLocalizeUrl(location, "/")}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</Link>,
        <Typography className="noLink services" key="2">{getLocalizeText("breadcrumbs/main", "services", getLanguage(location))}</Typography>,
        <Typography className="current" key="3">{getLocalizeText("breadcrumbs/main", "extendedSearch", getLanguage(location))}</Typography>
    ];

    const dispatch = useDispatch();
    const allPorts = useSelector(state => state.port.data);
    const allCities = useSelector(state => state.city.data);

    const [POL, setPol] = useState(null);
    const [POD, setPod] = useState(null);
    const [routesResult, setRoutesResult] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [openRequestDialog, setOpenRequestDialog] = useState(false);

    const routes = useSelector(state => state.route.data);

    useEffect(() => {
        return () => {
            dispatch(api_clearRoutes());
        }
    }, []);

    useEffect(() => {
        const all = combinePortsAndCities(allPorts, allCities);
        const polParam = params.get("pol")?.split(",")?.at(0) || "";
        const podParam = params.get("pod")?.split(",")?.at(0) || "";
        const pol = all.find(x => x.POL_ru === polParam || x.POL_en === polParam);
        const pod = all.find(x => x.POL_ru === podParam || x.POL_en === podParam);
        if (pol && pod) {
            setPol(pol);
            setPod(pod);
        }
    }, [location, allPorts, allCities]);

    useEffect(() => {
        let _routes = lodash.cloneDeep(routes);
        setRoutesResult(_routes);
    }, [routes]);

    useEffect(() => {
        if (Object.keys(routesResult).length)
            stopLoading();
    }, [routesResult]);

    const startLoading = () => {
        if (ref_searchLoader?.current) {
            ref_searchLoader.current.classList.add("animate");
            setTimeout(() => {
                if (ref_searchLoader?.current?.classList?.contains("animate")) {
                    ref_searchLoader.current.classList.remove("animate");
                    setTimeout(() => startLoading(), 100);
                }
            }, 2000);
        }
    }

    const stopLoading = () => {
        if (ref_searchLoader?.current)
            ref_searchLoader.current.classList.remove("animate");
    }

    const onSearch = (pol, pod) => {
        const all = combinePortsAndCities(allPorts, allCities);
        setPol(all.find(x => x.POL_en === pol.value));
        setPod(all.find(x => x.POL_en === pod.value));
        startLoading();
    }

    const onClickTab = (index) => {
        setActiveTab(index);
    }

    const closeRequestDialog = () => {
        setOpenRequestDialog(false);
        }

    return <div className="SearchPage">
        <MainLayout fixedHeader={true}>
            <div className="pageContent">
                <div className="searchBar">
                    <div className="tabs">
                        <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => onClickTab(0)}>{getLocalizeText("pages/main", "search.schedule", getLanguage(location))}</div>
                    </div>
                    <div className="tabsContent">
                        <div className={`tabContent ${activeTab !== 0 ? "hide" : ""}`}>
                            <SearchBar data={{POL, POD}} onSearch={onSearch} />
                        </div>
                    </div>
                </div>

                <div className={`blueBackground ${POL !== null && POD !==null ? "" : "hide"}`}>
                    <div ref={ref_searchLoader} className="searchLoader" />
                    <div className="resultContent">
                        <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                        
                        <div className="searchResult">
                            <SearchResult 
                                filter={{POL, POD}}
                                data={routesResult}
                            />
                        </div>
                    </div>
                </div>

                <div className={`whiteBackground ${routesResult?.exect ? "" : "noResults"}`}>
                    <h2>{getLocalizeText("pages/search", "bottomBlock.title", getLanguage(location))}</h2>
                    <div className="blocks">
                        <div className="block text">
                            <div className="title">{getLocalizeText("pages/search", "bottomBlock.title2", getLanguage(location))}</div>
                            <div className="description">
                                {getLocalizeText("pages/search", "bottomBlock.description", getLanguage(location))}
                            </div>
                            <div className="linkBtn" onClick={() => {setOpenRequestDialog(true)}}>
                                {getLocalizeText("pages/search", "bottomBlock.descriptionLink", getLanguage(location))} 
                                <IconLinkBtnArrow className="icon"/>
                            </div>
                        </div>
                        <div className="block img">
                            <img src={img_puzzles} alt="puzzles" />
                        </div>
                    </div>
                </div>
            </div>
            <DialogRequest open={openRequestDialog} onClose={closeRequestDialog} routeDisabled={false}/>
        </MainLayout>
    </div>
}

export default SearchPage;