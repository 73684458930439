/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect } from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import AdminMenu from "../../menues/AdminMenu";
import {Button} from "@mui/material";
import {ExitToApp, ArrowForward} from "@mui/icons-material"
import { api_checkAuthorization } from "../../redux/actions";
import img_logo from "../../assets/imgs/logo.png";


const AdminLayout = ({children}) => {
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/admin/login");
        }
    }, []);

    useEffect(() => {
        api_checkAuthorization();
    }, []);

    const logout = () => {
        localStorage.removeItem("token");
        navigate("/admin/login");
    }

    return <div className="AdminLayout">
        <div className="blocks">
            <div className="leftBlock">
                <h1>
                    <Link to="/">
                        <img className="logo" src={img_logo} alt="logo" />
                    </Link>
                    Admin Panel 
                    <ArrowForward className="arrow" />
                </h1>
                <AdminMenu />
                <div className="buttons">
                    <Button fullWidth variant="outlined" onClick={logout}>
                        Выйти
                        <ExitToApp className="icon" />
                    </Button>
                </div>
            </div>
            <div className="children">
                {children}
            </div>
        </div>
    </div>
}

export default AdminLayout