import { Button, Checkbox } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import "./style.scss";
import Loader from "../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AdminSearchTagEditDialog from "./AdminSearchTagEditDialog";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { api_deleteSearchTag, api_getSearchTags } from "../../../redux/actions/tag";
import ConfirmDialog from "../../../components/dialogs/Confirm";

const AdminSearchTagsPage = () => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            renderCell: (props) => {
                return <>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => onOpenEditDialog(props.row)}><EditIcon /></Button>
                    <Button variant="outlined" color="error" className="actionBtn" onClick={() => confirmDelete(props.row)}><DeleteIcon /></Button>
                </>
            }
        },
        {
            field: "name",
            headerName: "Наименование",
            flex: 1
        },
        {
            field: "POL",
            headerName: "Отправка",
            flex: 1,
            valueGetter: (props) => allPorts.find(x => x.POL_en === props.row.POL)?.POL_ru || props.row.POL
        },
        {
            field: "POD",
            headerName: "Прибытие",
            flex: 1,
            valueGetter: (props) => allPorts.find(x => x.POL_en === props.row.POD)?.POL_ru || props.row.POD
        },
        {
            field: "active",
            headerName: "Активно",
            width: 80,
            renderCell: (props) => {
                return <>
                    <Checkbox checked={props.row.active} disabled />
                </>
            }
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "datetime",
            valueGetter: (props) => {
                return moment(props.row.createdAt).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
    ];

    const allPorts = useSelector(state => state.port.data);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

    useEffect(() => {
        getSearchTags();
    }, []);

    const getSearchTags = async () => {
        setIsLoading(true);
        setData((await api_getSearchTags()).map(x => {
            x.id = x._id;
            return x;
        }));
        setIsLoading(false);
    }

    const onCloseEditDialog = () => {
        setIsOpenEditDialog(false);
        setSelectedItem(null);
    }

    const onOpenEditDialog = (item = null) => {
        setSelectedItem(item);
        setIsOpenEditDialog(true);
    }

    const onSave = () => {
        getSearchTags();
    }

    const confirmDelete = (item) => {
        setSelectedItem(item);
        setIsOpenConfirmDelete(true);
    }

    const onCloseConfirmDelete = () => {
        setSelectedItem(null);
        setIsOpenConfirmDelete(false);
    }

    const onConfirmDelete = async () => {
        setIsLoading(true);
        await api_deleteSearchTag(selectedItem._id);
        getSearchTags();
        onCloseConfirmDelete();
        setIsLoading(false);
    }

    return <div className="AdminSearchTagsPage">
    <AdminLayout>
        <h1>Поисковые тэги</h1>

        <div className="content">
            <div className="buttons">
                <Button className="btn mr" variant="contained" onClick={() => onOpenEditDialog()}>Добавить</Button>
            </div>
            
            <DataGrid
                className="table"
                rows={data}
                columns={columns}
                rowHeight={80}
                rowsPerPageOptions={[20, 50, 100]}
            />
        </div>

        <AdminSearchTagEditDialog title="Редактирование тэга" open={isOpenEditDialog} onClose={onCloseEditDialog} onSave={onSave} item={selectedItem} />
        <Loader active={isLoading}/>
        <ConfirmDialog open={isOpenConfirmDelete} title="Внимание" content="Вы действительно хотите удалить тэг?" onClose={onCloseConfirmDelete} onConfirm={onConfirmDelete} />
    </AdminLayout>
</div>
}

export default AdminSearchTagsPage;