import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { api_uploadCities, api_uploadCompanies, api_uploadLogos, api_uploadPorts, api_uploadRoutes } from "../../../redux/actions";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AdminLayout from "../../../layouts/AdminLayout";
import Loader from "../../../components/Loader";
import { api_getTagTypes, api_getTags } from "../../../redux/actions/tag";
import { useDispatch } from "react-redux";


const UploadRoutesPage = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async () => {
        setIsLoading(true);

        let formData = new FormData();

        let files = document.querySelector("input[name='routes']").files;
        for (let file of files) {
            formData.append('routes', file);
        }

        document.querySelector("input[name='routes']").value = "";
        const r = await api_uploadRoutes(formData);
        if (r && typeof r.imported === "number") {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Загружено: {r.imported}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }

        setIsLoading(false);
    }

    const onSubmitCompanies = async () => {
        setIsLoading(true);

        let formData = new FormData();

        let files = document.querySelector("input[name='companies']").files;
        for (let file of files) {
            formData.append('companies', file);
        }

        document.querySelector("input[name='companies']").value = "";
        const r = await api_uploadCompanies(formData);
        if (r && typeof r.imported === "number") {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Загружено: {r.imported}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });

            dispatch(api_getTagTypes());
            dispatch(api_getTags());
        }

        setIsLoading(false);
    }

    const onSubmitPorts = async () => {
        setIsLoading(true);

        let formData = new FormData();

        let files = document.querySelector("input[name='ports']").files;
        for (let file of files) {
            formData.append('ports', file);
        }

        document.querySelector("input[name='ports']").value = "";
        const r = await api_uploadPorts(formData);
        if (r && typeof r.imported === "number") {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Загружено: {r.imported}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }

        setIsLoading(false);
    }

    const onSubmitCities = async () => {
        setIsLoading(true);

        let formData = new FormData();

        let files = document.querySelector("input[name='cities']").files;
        for (let file of files) {
            formData.append('cities', file);
        }

        document.querySelector("input[name='cities']").value = "";
        const r = await api_uploadCities(formData);
        if (r && typeof r.imported === "number") {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Загружено: {r.imported}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }

        setIsLoading(false);
    }

    const onSubmitLogos = async () => {
        setIsLoading(true);

        let formData = new FormData();

        let files = document.querySelector("input[name='logos']").files;
        for (let file of files) {
            formData.append('logos', file);
        }
        
        document.querySelector("input[name='logos']").value = "";
        const r = await api_uploadLogos(formData);
        if (r && typeof r.imported === "number") {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText">  Загружено: {r.imported}</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }

        setIsLoading(false);
    }

    return <div className="UploadRoutesPage">
         <AdminLayout>
            <div className="pageContent">
                <h1>Загрузка данных</h1>
                <div className="block">
                    <div className="row">
                        <div className="title">CSV файл с маршрутами:</div>
                        <div className="value">
                            <TextField
                                type="file"
                                name="routes"
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button className="btn" variant="contained" onClick={onSubmit}>Загрузить</Button>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">CSV файл с компаниями:</div>
                        <div className="value">
                            <TextField
                                type="file"
                                name="companies"
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button className="btn" variant="contained" onClick={onSubmitCompanies}>Загрузить</Button>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">CSV файл с портами:</div>
                        <div className="value">
                            <TextField
                                type="file"
                                name="ports"
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button className="btn" variant="contained" onClick={onSubmitPorts}>Загрузить</Button>
                    </div>
                </div>
                <div className="block">
                    <div className="row">
                        <div className="title">CSV файл с городами без портов:</div>
                        <div className="value">
                            <TextField
                                type="file"
                                name="cities"
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button className="btn" variant="contained" onClick={onSubmitCities}>Загрузить</Button>
                    </div>
                </div>


                <div className="block">
                    <div className="row">
                        <div className="title">Логотипы компаний:</div>
                        <div className="value">
                            <TextField
                                type="file"
                                name="logos"
                                inputProps={{multiple: true}}
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <Button className="btn" variant="contained" onClick={onSubmitLogos}>Загрузить</Button>
                    </div>
                </div>
            </div>

            <Loader active={isLoading} />
         </AdminLayout>
    </div>
}

export default UploadRoutesPage;