import {
    TAGS_DATA,
    TAGS_FETCHED,
    TAGS_FETCHING
} from "../types";

const initState = {
    fetching: false,
    fetched: false,
    data: []
};

const tagReducer = (state = initState, action) => {
    const {type, payload} = action;

    switch(type) {
        case TAGS_DATA: 
            state.data = payload
            return {...state};
        case TAGS_FETCHING: 
            state.fetching = payload
            return {...state};
        case TAGS_FETCHED: 
            state.fetched = payload
            return {...state};

        default:
            return state;
    }
}

export default tagReducer;