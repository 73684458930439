import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import moment from "moment";
import AdminLayout from "../../../layouts/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { api_getTags } from "../../../redux/actions/tag";
import Loader from "../../../components/Loader";
import { api_getCompanies_notRedux } from "../../../redux/actions";
import AdminCompanyEditDialog from "./dialogs/AdminCompanyEditDialog";
import { getApiHost } from "../../../misc/api";
import { getCompanyLogoPath } from "../../../misc/file";

const AdminCompaniesPage = () => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            renderCell: (props) => {
                return <>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => onOpenEditDialog(props.row)}><EditIcon /></Button>
                    <Button variant="outlined" color="error" className="actionBtn"><DeleteIcon /></Button>
                </>
            }
        },
        {
            field: "img",
            headerName: "Лого",
            width: 80,
            renderCell: (props) => {
                return <>
                    <img className="logo" src={getCompanyLogoPath(props.row)} alt="logo" />
                </>
            }
        },
        {
            field: "code",
            headerName: "Код",
            flex: 1
        },
        {
            field: "company",
            headerName: "Компания",
            flex: 1
        },
        {
            field: "line",
            headerName: "Линия",
            flex: 1
        },
        {
            field: "address",
            headerName: "Адрес",
            flex: 1
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1
        },
        {
            field: "phone",
            headerName: "Телефон",
            flex: 1
        },
        {
            field: "fax",
            headerName: "Факс",
            flex: 1
        },
        {
            field: "phone2",
            headerName: "Телефон",
            flex: 1
        },
        {
            field: "site",
            headerName: "Сайт",
            flex: 1
        },
        {
            field: "isPartner",
            headerName: "Партнер",
            flex: 1
        },
        {
            field: "tags",
            headerName: "Тэги",
            cellClassName: "tagCell",
            renderCell: (params) => {
                const _tags = [];
                if (Array.isArray(params.row.tags)) {
                    for (const tagId of params.row.tags) {
                        const tag = tags.find(x => x._id === tagId);
                        if (tag) {
                            const tagType = tagTypes.find(x => x.code.toLowerCase() === tag.type.toLowerCase());
                            if (tagType) {
                                _tags.push(<div style={{background: tagType.background, color: tagType.color, borderRadius: "2px", fontSize: "10px", padding: "2px"}}>{tag.value}</div>);
                            }
                        }
                    }
                }
                return _tags;
            }
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return <div className="datetime">
                    <div>{moment(params.row.createdAt).format("DD.MM.YYYY")}</div>
                    <div>{moment(params.row.createdAt).format("HH:mm:ss")}</div>
                </div>;
            },
            sortComparator: (v1, v2) => {
                if (moment(v1).isAfter(moment(v2)))
                    return 1;
                if (moment(v1).isBefore(moment(v2)))
                    return -1;
                return 0
            }
        },
    ];

    const dispatch = useDispatch();

    const tags = useSelector(state => state.tag.data);
    const tagTypes = useSelector(state => state.tagType.data);

    const [data, setData] = useState([]);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getCompanies().then(r => setIsLoading(false));
    }, []);

    const getCompanies = async () => {
        setData((await api_getCompanies_notRedux()).map(x => {
            x.id = x._id;
            return x;
        }));
    }

    const getTags = () => {
        dispatch(api_getTags());
    }

    const onCloseEditDialog = () => {
        setIsOpenEditDialog(false);
        setSelectedItem(null);
    }

    const onOpenEditDialog = (item = null) => {
        setSelectedItem(item);
        setIsOpenEditDialog(true);
    }

    const onSave = () => {
        getCompanies();
        getTags();
    }

    return <div className="AdminCompaniesPage">
        <AdminLayout>
            <h1>Компании</h1>

            <div className="content">
                <div className="buttons">
                    <Button className="btn mr" variant="contained" onClick={() => onOpenEditDialog()}>Добавить</Button>
                </div>
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowHeight={80}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminCompanyEditDialog title="Редактирование компании" open={isOpenEditDialog} onClose={onCloseEditDialog} onSave={onSave} item={selectedItem} />
            <Loader active={isLoading}/>
        </AdminLayout>
    </div>
}

export default AdminCompaniesPage;