import { BrowserRouter, useLocation } from 'react-router-dom';
import './App.scss';
import AppRoutes from './router';
import {Provider} from "react-redux";
import store from "./redux/store";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import "./axios/interceptors/UnAutorized";
import 'moment/locale/ru';
import 'moment/locale/tr';

const App = () => {
    return <div className='App'>
        <ReactNotifications />  
        <Provider store={store}>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </Provider>
    </div>
}

export default App;
