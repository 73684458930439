import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "./style.scss";
import DialogSubscribeRouteMail from "../../components/dialogs/SubscribeRouteMail";
import moment from "moment";
import { api_getSettingByCode } from "../../redux/actions/settings";
import Footer2 from "../../components/Footer2";

let resetSubscribe = 12;
let activeSubscribe = false;

const MainLayout = ({fixedHeader, children, footer}) => {
    const [isOpenSubscribeDialog, setIsOpenSubscribeDialog] = useState(false);

    useEffect(() => {
        if (typeof window.rePlain === "function")
            window.rePlain();

        api_getSettingByCode("subscribeDialog_reset").then(r => {
            if (r)
                resetSubscribe = !isNaN(parseFloat(r.value.replace(",", "."))) ? parseFloat(r.value.replace(",", ".")) : resetSubscribe;
        });
        api_getSettingByCode("subscribeDialog_active").then(r => {
            if (r)
                activeSubscribe = r.value === "Y";
        });

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    }, []);

    const onScroll = async () => {
        const showedAt = localStorage.getItem("subscribeDialogShowedAt");
        // console.log(showedAt, resetSubscribe, moment().valueOf() - showedAt, resetSubscribe * 60 * 60 * 1000, moment().valueOf() - showedAt > resetSubscribe * 60 * 60 * 1000);
        if (activeSubscribe && (!showedAt || moment().valueOf() - showedAt > resetSubscribe * 60 * 60 * 1000)) {
            const delaySetting = await api_getSettingByCode("subscribeDialog_delay");
            let delay = 3;
            if (delaySetting)
                delay = parseInt(delaySetting.value, 10) > 0 ? parseInt(delaySetting.value, 10) : delay;
            localStorage.setItem("subscribeDialogShowedAt", moment().valueOf());
            setTimeout(() => {
                openSubscribeDialog();
            }, delay * 1000);
        }
    }

    const openSubscribeDialog = () => {
        setIsOpenSubscribeDialog(true);
    }

    const closeSubscribeDialog = () => {
        setIsOpenSubscribeDialog(false);
    }

    return <div className="MainLayout">
        <Header fixed={typeof fixedHeader === "boolean" && fixedHeader ? true : false} />
        <div className={`children ${fixedHeader ? "fixedHeader" : ""}`}>
            {children}
        </div>
        <DialogSubscribeRouteMail open={isOpenSubscribeDialog} onClose={closeSubscribeDialog} />
        {footer && footer === "no-form"
            ? <Footer2 />
            : <Footer/>
        }
    </div>
}

export default MainLayout