/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../../layouts/AdminLayout";

const AdminPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/admin/requestLogPage");
    }, []);

    return <div className="AdminPage">
        <AdminLayout>
            
        </AdminLayout>
    </div>
}

export default AdminPage;