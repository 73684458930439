import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment";
import AdminLayout from "../../../layouts/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AdminTagsEditDialog from "./AdminTagsEditDialog";
import { api_getTagTypes } from "../../../redux/actions/tag";
import InfoIcon from "../../../components/InfoIcon";

const AdminTagsPage = () => {
    const columns = [
        { 
            field: "id",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            sortable: false,
            filterable: false,
            width: 60,
            renderCell: (props) => {
                return <div>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => openEditDialog(props.row)}><EditIcon className="icon" /></Button>
                </div>
            }
        },
        { 
            field: "code",
            headerName: "Название",
            flex: 1
        },
        { 
            field: "color",
            headerName: "Цвет",
            flex: 1,
            renderCell: (props) => {
                return <div className="color" style={{color: props.row.color,backgroundColor: props.row.background}}>{props.row.code.toUpperCase()} &nbsp;<InfoIcon color={props.row.iColor || "#000"} /></div>
            }
        },
        {
            field: "createdAt",
            headerName: "Создан",
            width: 100,
            type: "datetime",
            valueGetter: (props) => {
                return moment(props.row.ETA).format("DD.MM.YYYY");
            }
        }
    ];

    const dispatch = useDispatch();

    const tags = useSelector(state => state.tagType.data);

    const [data, setData] = useState([]);
    const [isEditDialogOpen, setIsEditDialogOpen]= useState(false);
    const [selectedTeg, setSelectedTeg] = useState(null);

    useEffect(() => {
        setData(tags.map(x => {
            x.id = x._id;
            return x;
        }));
    }, [tags]);

    const updateTags = () => {
        dispatch(api_getTagTypes());
    }

    const openEditDialog = (item) => {
        setSelectedTeg(item);
        setIsEditDialogOpen(true);
    }

    const closeEditDialog = () => {
        setIsEditDialogOpen(false);
        setSelectedTeg(null);
    }

    const onSave = () => {
        updateTags();
    }

    return <div className="AdminTagsPage">
        <AdminLayout>
            <h1>Тэги</h1>

            <div className="content">
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminTagsEditDialog title="Редактирование тэга" open={isEditDialogOpen} onClose={closeEditDialog} onSave={onSave} item={selectedTeg} />
        </AdminLayout>
    </div>
}

export default AdminTagsPage;