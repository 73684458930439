import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getCarouselSlides = async (carouselName = null) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = carouselName?.trim() !== "" ? `/api/carouselSlide/carousel/${carouselName}` : `/api/carouselSlide/`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_addCarouselSlides = async (carouselName, newSlide) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.post(`${apiHost}/api/admin/carouselSlide/carousel/${carouselName}`, newSlide, {headers})).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_deleteCarouselSlides = async (id) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.delete(`${apiHost}/api/admin/carouselSlide/id/${id}`, {headers})).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_editCarouselSlides = async (id, slideData) => {
    try {
        const headers = {
            token: localStorage.getItem("token"),
            "Content-type": "multipart/form-data"
        };
        const data = (await axios.post(`${apiHost}/api/admin/carouselSlide/id/${id}`, slideData, {headers})).data;
        return data;
    }
    catch(e) {
        return false;
    }
}