import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import Loader from "../../../components/Loader";
import { api_authorize } from "../../../redux/actions";
import "./style.scss";
import { Store as notification } from 'react-notifications-component';
import { Error } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";


const AdminLoginPage = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const signIn = async () => {
        setLoading(true);
        const r = await api_authorize(email, password);
        setLoading(false);
        if (r) {
            navigate("/admin");
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не верная авторизация</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
    }

    return <div className="AdminLoginPage">
        <div className="shadow">
            <div className="block">
                <div className="row">
                    <TextField fullWidth label="E-mail" variant="outlined" value={email} onChange={onEmailChange} />
                </div>
                <div className="row">
                    <TextField fullWidth label="Пароль" type="password" variant="outlined" value={password} onChange={onPasswordChange} />
                </div>
                <div className="row buttons">
                    <Button variant="contained" onClick={signIn}>Вход</Button>
                </div>
            </div>
        </div>
        <Loader active={loading} text="Авторизация..." />
    </div>
}

export default AdminLoginPage;