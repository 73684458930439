/* eslint-disable no-control-regex */
import { Tab, Tabs } from "@mui/material";
import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import AdminSearchCarouselPage from "./AdminSearchCarouselPage";
import AdminTopCarouselPage from "./AdminTopCarouselPage";
import "./style.scss";


const AdminCarouselPage = () => {
    const [activeTab, setActiveTab] = React.useState(0);

    const onChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    return <div className="AdminCarouselPage">
        <AdminLayout>
            <h1>Карусель</h1>
            <div className="tabs">
                <Tabs value={activeTab} onChange={onChangeTab}>
                    <Tab label="Карусель с поиском" />
                    <Tab label="Карусель под поиском" />
                </Tabs>
            </div>
            <div className="tabsContent">
                <div className={activeTab !== 0 ? "hide" : ""}>
                    <AdminSearchCarouselPage />
                </div>
                <div className={activeTab !== 1 ? "hide" : ""}>
                    <AdminTopCarouselPage />
                </div>
            </div>
        </AdminLayout>
    </div>
}

export default AdminCarouselPage;