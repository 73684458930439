import { Button, ButtonGroup, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import icn_infoBlue from "../../../assets/icons/info_b.svg";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import moment from "moment";

const RouteTariffType = (props) => {
    const location = useLocation();

    const [tariffType, setTariffType] = useState("FILO");

    useEffect(() => {
        if (props.validTill) {
            if (hasPrices("FILO"))
                onBtnClick("FILO");
            else if (hasPrices("LIFO"))
                onBtnClick("LIFO");
            else if (hasPrices("FIFO"))
                onBtnClick("FIFO");
            else if (hasPrices("LILO"))
                onBtnClick("LILO");
        }
    }, [props.validTill]);

    const onBtnClick = (type) => {
        setTariffType(type);
        props.onChange(type);
    }

    const hasPrices = (_type) => {
        if (props.validTill) {
            const data = props.validTill.prices.find(x => x.type === _type);
            if (data && data.soc?.length && (moment(data.soc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment()) || moment(data.soc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment()))) {
                return true;
            }
            if (data && data.coc?.length && (moment(data.coc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment()) || moment(data.coc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment()))) {
                return true;
            }
        }
        return false;
    }

    return <div className="RouteTariffType">
        {hasPrices("FILO") || hasPrices("LIFO") || hasPrices("FIFO") || hasPrices("LILO")
            ? <>
                <div className="title">
                    {getLocalizeText("components/tariff", "title", getLanguage(location))}
                </div>

                <div className="buttons">
                    <ButtonGroup variant="outlined">
                        {hasPrices("FILO")
                            ? <Button className={tariffType == "FILO" ? "active" : ""} onClick={() => onBtnClick("FILO")}>FILO {tariffType == "FILO" ? <Tooltip title={getLocalizeText("components/tariff", "info.FILO", getLanguage(location))} className="iContainer" placement="top" arrow><img src={icn_infoBlue} alt="info" /></Tooltip> : null}</Button>
                            : null
                        }
                        {hasPrices("LIFO")
                            ? <Button className={tariffType == "LIFO" ? "active" : ""} onClick={() => onBtnClick("LIFO")}>LIFO {tariffType == "LIFO" ? <Tooltip title={getLocalizeText("components/tariff", "info.LIFO", getLanguage(location))} className="iContainer" placement="top" arrow><img src={icn_infoBlue} alt="info" /></Tooltip> : null}</Button>
                            : null
                        }
                        {hasPrices("FIFO")
                            ? <Button className={tariffType == "FIFO" ? "active" : ""} onClick={() => onBtnClick("FIFO")}>FIFO {tariffType == "FIFO" ? <Tooltip title={getLocalizeText("components/tariff", "info.FIFO", getLanguage(location))} className="iContainer" placement="top" arrow><img src={icn_infoBlue} alt="info" /></Tooltip> : null}</Button>
                            : null
                        }
                        {hasPrices("LILO")
                            ? <Button className={tariffType == "LILO" ? "active" : ""} onClick={() => onBtnClick("LILO")}>LILO {tariffType == "LILO" ? <Tooltip title={getLocalizeText("components/tariff", "info.LILO", getLanguage(location))} className="iContainer" placement="top" arrow><img src={icn_infoBlue} alt="info" /></Tooltip> : null}</Button>
                            : null
                        }
                    </ButtonGroup>
                </div>
            </>
            : null
        }
    </div>
}

export default RouteTariffType;