import React, { memo, useEffect, useRef } from "react";
import "./style.scss";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {ReactComponent as SvgShip} from "../../assets/icons/shipNew.svg";
import moment from "moment";
import { beforeThanToday, daysTitle, getDaysCount } from "../../misc/date";
import {ReactComponent as SvgTrain} from "../../assets/icons/_train.svg";
import CircleIcon from '@mui/icons-material/Circle';
import {ReactComponent as LocationOnIcon} from "../../assets/icons/pointWay.svg";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeCompanyName, getLocalizePortAndCode, getLocalizeText } from "../../misc/localization";
import RouteServices from "../SearchResultRouteNear/RouteServices";
import RouteTariffType from "../SearchResultRouteNear/RouteTariffTypes";
import SocCocPrice from "../SearchResultRouteNear/SocCocPrice";
import { getCompanyLogoPath } from "../../misc/file";
import { Button, Tooltip } from "@mui/material";
import { api_addBookingLog } from "../../redux/actions/bookingLog";
import { getFreaightHost } from "../../misc/api";
import EastIcon from '@mui/icons-material/East';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import InfoIcon from "../InfoIcon";
import {ReactComponent as IconClosed} from "../../assets/icons/closed.svg";

const SearchResultRouteVertical = memo((props) => {

    if (!props || !props.ports || !props.route) return null;

    if (props.route.type === "direct") return <DirectRoute route={props.route} ports={props.ports} filter={props.filter} isOpen={props.isOpen} companies={props.companies} goToBooking={props.goToBooking} showPlaceBtn={props.showPlaceBtn} sendRequest={props.sendRequest} onClose={props.onClose} validTill={props.validTill} validTillDate={props.validTillDate} tariff={props.tariff} onTariffChange={props.onTariffChange} />

    if (props.route.type === "transshipment") return <TransshipmentRoute route={props.route} ports={props.ports} filter={props.filter} isOpen={props.isOpen} companies={props.companies} goToBooking={props.goToBooking} showPlaceBtn={props.showPlaceBtn} sendRequest={props.sendRequest} onClose={props.onClose} validTill={props.validTill} validTillDate={props.validTillDate} tariff={props.tariff} onTariffChange={props.onTariffChange} />

    return null;
});

const DirectRoute = memo((props) => {
    const location = useLocation();

    const ref_count = useRef();
    const ref_from = useRef();
    const ref_to = useRef();

    useEffect(() => {
        if (props.isOpen)
            moveCountOfDates();
    }, [props.isOpen]);

    useEffect(() => {
        if (props.route)
            moveCountOfDates();
    }, [props.route]);

    const moveCountOfDates = () => {
        const from = ref_from.current.offsetTop;
        const to = ref_to.current.offsetTop;
        ref_count.current.style.top = `${(from + to) / 2}px`;
    }

    if (!props || !props.ports || !props.route) return null;

    return <div x-data-type="direct" className="SearchResultRouteVertical">
        <div className={`topVertical ${props.route.POLDistance || props.route.PODDistance ? "noDetails" : ""}`}>
            <div className="mainBlock">
                <div className="routeContainer">
                    <div className="routeContainerBlock">
                        {props.route.POLDistance
                            ? <div className="blockVertical">
                                <div className="routeLine land">
                                    <LocationOnIcon className="start arrow" />
                                </div>
                                <div className="routeData">
                                    <div className="from">
                                        <div className="routeDistance">
                                            {props.route.POLDistance && Math.round(props.route.POLDistance / 1000) > 0
                                                ?   <div className="distance">
                                                        <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                            <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                        </Tooltip>
                                                        {Math.round(props.route.POLDistance / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                    </div>
                                                :   <div className="distance">
                                                        <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                            <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                        </Tooltip>
                                                        1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                    </div>
                                            }
                                        </div>
                                        <div className="routeTitleDistance">
                                            {props.ports.find(x => x.POL_en === props.filter.POL.POL_en)
                                                ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter.POL.POL_en), getLanguage(location))
                                                :   props.filter.POL.POL_en
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        <div ref={ref_from} className="location from">
                            {props.route.POLDistance
                                ? <CircleIcon className="point" />
                                : <LocationOnIcon className="locationPoint" />
                            }
                        </div>

                        <div ref={ref_count} className="countOfDays">
                            <div className="text">{getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate())} {daysTitle(getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate()), getLanguage(location))}</div>
                        </div>

                        <div className="blockVertical">
                            <div className="routeLine"/>
                            <div className="routeData">
                                <div className={`from${typeof props.route.POLDistance !== "undefined" ? " notFirst" : ""}`}>
                                    <div className="routeDate">
                                        <SvgShip className="ship" />
                                        {moment(props.route?.ETD).format("DD MMMM YYYY")}
                                    </div>
                                    <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POL).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                </div>
                            </div>
                        </div>

                        {props.route.extensions && props.route.extensions.map(ext => <>
                            <CircleIcon className="point" />
                            <div className="blockVertical">
                                <div className="routeLine" />
                                <div className="routeData">
                                    <div className="from notFirst">
                                        <div className="routeDate">
                                            <SvgShip className="ship" />
                                            {moment(ext.date).format("DD MMMM YYYY")}
                                        </div>
                                        <div className="routeTitle">{props.ports.filter(port => port.POL_en === ext.port).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                    </div>
                                </div>
                            </div>
                        </>)}

                        <div ref={ref_to} className="location to">
                            {props.route.PODDistance
                                ? <CircleIcon className="point" />
                                : <LocationOnIcon className="locationPoint" />
                            }
                        </div>

                        {typeof props.route.PODDistance !== "undefined"
                            ? <>
                                <div className="blockVertical">
                                    <div className="routeLine land">
                                        <LocationOnIcon className="end locationPoint" />
                                    </div>
                                    <div className="routeData">
                                        <div className="from podDistance">
                                            <div className="routeDate">
                                                <SvgShip className="ship" />
                                                {moment(props.route?.ETA).format("DD MMMM YYYY")}
                                            </div>
                                            <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POD).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`blockVertical last withDistance`}>
                                    <div />
                                    <div className="routeData">
                                        <div className="to distance">
                                            <div className="routeDistance">
                                                {props.route.PODDistance && Math.round(props.route.PODDistance / 1000) > 0
                                                    ?   <div className="distance">
                                                            <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                                <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                            </Tooltip>
                                                            {Math.round(props.route.PODDistance / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                        </div>
                                                    :   <div className="distance">
                                                            <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                                <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                            </Tooltip>
                                                            1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                        </div>
                                                }
                                            </div>
                                            <div className="routeTitleDistance">
                                                {props.ports.find(x => x.POL_en === props.filter.POD.POL_en)
                                                    ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter.POD.POL_en), getLanguage(location))
                                                    :   props.filter.POD.POL_en
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <div className={`blockVertical last`}>
                                <div />
                                <div className="routeData">
                                    <div className="to">
                                        <div className="routeDate">
                                            <SvgShip className="ship" />
                                            {moment(props.route?.ETA).format("DD MMMM YYYY")}
                                        </div>
                                        <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POD).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`landingOverTablet ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                        <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                    </div>
                </div>

                <div className="servicesTariffContainer">
                    {props.companies.find(x => x.line === props.route.line)?.tags?.length
                        ? <div className="services">
                            <RouteServices company={props.companies.find(x => x.line === props.route.line)} />
                        </div>
                        : null
                    }
                    <div className="tariffTypes">
                        <RouteTariffType validTill={props.validTill || null} onChange={props.onTariffChange} />
                    </div>
                </div>
            </div>
            <div className={`detailsBtn ${props.route.extensions && props.route.extensions.length ? "hasExtRoutes" : ""} ${props.route.POLDistance || props.route.PODDistance ? "hasDistance" : ""}`} onClick={props.onClose}>
                {getLocalizeText("components/searchResult", "open", getLanguage(location))}
                <ArrowDropUpIcon className="arrow" />
            </div>
        </div>
        <div className="bottomVertical">
            <div className="company">
                <div className="companyContainer">
                    <img src={getCompanyLogoPath(props.companies.find(x => x.line === props.route.line) || {code: "default"})} alt="company_logo" />
                    <div className="name" title={props.companies.filter(x => x.line === props.route.line).filter((x, i) => i === 0).map(x => x.company)}>
                        {getLocalizeCompanyName(props.companies.find(x => x.line === props.route.line) || {company: "<unknown namw>"}, location)}
                    </div>
                    <div className={`landingOver ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                        <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                    </div>
                </div>

                <div className="soccocBlock">
                    <div className="soccocContainer">
                        <SocCocPrice validTill={props.validTill || null} tariff={props.tariff} />
                    </div>
                </div>
            </div>
            <div className="application" x-data-validTill={props.validTillDate || "undefined"} x-data-allowToBooking={props.route.allowToBooking.toString()} x-data-cutOffDate={props.route.cutOffDate}>
                {
                    props.showPlaceBtn(props)
                    ? <Button fullWidth className={`btn booking`} variant="contained" onClick={() => props.goToBooking(props.route)}>{getLocalizeText("components/searchResult", "goToBooking", getLanguage(location))}</Button>
                    : null
                }

                <Button fullWidth variant="outlined" className={`request ${props.route.allowToBooking && props.route.cutOffDate && moment(props.route.cutOffDate).isValid() && moment(props.route.cutOffDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))) && ((props.validTillDate && moment(props.validTillDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))))) ? "hasBooking" : ""} ${beforeThanToday(props.route.ETD) ? "hide" : ""}`} onClick={() => props.sendRequest(props.route)}>{getLocalizeText("components/searchResult", "sendQuery", getLanguage(location))}</Button>
            </div>
        </div>
    </div>
});

const TransshipmentRoute = memo((props) => {
    const location = useLocation();

    const ref_count = useRef();
    const ref_from = useRef();
    const ref_to = useRef();

    useEffect(() => {
        moveCountOfDates();
    }, [props.isOpen]);

    const moveCountOfDates = () => {
        const from = ref_from.current.offsetTop;
        const to = ref_to.current.offsetTop;
        ref_count.current.style.top = `${(from + to) / 2}px`;
    }

    if (!props || !props.ports || !props.route) return null;

    return <div x-data-type="transshipment" className="SearchResultRouteVertical transshipment">
        <div className={`topVertical ${props.route.POLDistance || props.route.PODDistance ? "noDetails" : ""}`}>
            <div className="mainBlock">
                <div className="routeContainer">
                    <div className="routeContainerBlock">
                        {props.route.POLDistance
                            ? <div className="blockVertical">
                                <div className="routeLine land">
                                    <LocationOnIcon className="start arrow" />
                                </div>
                                <div className="routeData">
                                    <div className="from">
                                        <div className="routeDistance">
                                            {props.route.POLDistance && Math.round(props.route.POLDistance / 1000) > 0
                                                ?   <div className="distance">
                                                        <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                            <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                        </Tooltip>                                                    {Math.round(props.route.POLDistance / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                    </div>
                                                :   <div className="distance">
                                                        <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                            <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                        </Tooltip>
                                                        1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                    </div>
                                            }
                                        </div>
                                        <div className="routeTitleDistance">
                                            {props.ports.find(x => x.POL_en === props.filter.POL.POL_en)
                                                ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter.POL.POL_en), getLanguage(location))
                                                :   props.filter.POL.POL_en
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        <div ref={ref_from} className="location from">
                            {props.route.POLDistance
                                ? <CircleIcon className="point" />
                                : <LocationOnIcon className="locationPoint" />
                            }
                        </div>

                        <div ref={ref_count} className="countOfDays">
                            <div className="text">{getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate())} {daysTitle(getDaysCount(moment(props.route.ETD).toDate(), moment(props.route.ETA).toDate()), getLanguage(location))}</div>
                        </div>

                        <div className="blockVertical">
                            <div className={`routeLine${props.route.transport1 === "rail" ? " rail" : ""}`} />
                            <div className="routeData">
                                <div className={`from${typeof props.route.POLDistance !== "undefined" ? " notFirst" : ""}`}>
                                    <div className="routeDate">
                                        {props.route.transport1 === "rail"
                                            ? <SvgTrain className="train" />
                                            : <SvgShip className="ship" />
                                        }
                                        {moment(props.route?.ETD).format("DD MMMM YYYY")}
                                    </div>
                                    <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POL).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="blockVertical">
                            <div className={`routeLine${props.route.transport2 === "rail" ? " rail" : ""}`}>
                                <CircleIcon className="start" />
                            </div>
                            <div className="routeData transshipment">
                                <div className="from notFirst">
                                    <div className="routeDate">
                                        {props.route.transport1 === "rail"
                                            ? <SvgTrain className="train" />
                                            : <SvgShip className="ship" />
                                        }
                                        <EastIcon className="arrow" />
                                        {props.route.transport2 === "rail"
                                            ? <SvgTrain className="train" />
                                            : <SvgShip className="ship" />
                                        }
                                        {moment(props.route?.ETD).format("DD MMMM YYYY")}
                                    </div>

                                    <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.transshipmentLocation).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                </div>
                            </div>
                        </div>

                        <div ref={ref_to} className="location to">
                            {props.route.PODDistance
                                ? <CircleIcon className="point" />
                                : <LocationOnIcon className="locationPoint" />
                            }
                        </div>

                        {typeof props.route.PODDistance !== "undefined"
                            ? <>
                                <div className="blockVertical">
                                    <div className="routeLine land">
                                        <LocationOnIcon className="start hide" />
                                        <div className="line" />
                                        <LocationOnIcon className="end" />
                                    </div>
                                    <div className="routeData">
                                        <div className="from notFirst">
                                            <div className="routeDate">
                                                {props.route.transport2 === "rail"
                                                    ? <SvgTrain className="train" />
                                                    : <SvgShip className="ship" />
                                                }
                                                {moment(props.route?.ETA).format("DD MMMM YYYY")}
                                            </div>
                                            <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POD).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`blockVertical last withDistance`}>
                                    <div />
                                    <div className="routeData">
                                        <div className="to distance">
                                            <div className="routeDistance">
                                                {props.route.PODDistance && Math.round(props.route.PODDistance / 1000) > 0
                                                    ?   <div className="distance">
                                                            <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                                <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                            </Tooltip>
                                                            {Math.round(props.route.PODDistances / 1000)} {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                        </div>
                                                    :   <div className="distance">
                                                            <Tooltip title={getLocalizeText("components/searchResult", "info.own", getLanguage(location))} className="iContainer" placement="top" arrow>
                                                                <span><InfoIcon className="infoIcon" color="#94A3B8" /></span>
                                                            </Tooltip>
                                                            1-5 {getLocalizeText("components/searchResult", "km", getLanguage(location))}
                                                        </div>
                                                }
                                            </div>
                                            <div className="routeTitleDistance">
                                                {props.ports.find(x => x.POL_en === props.filter.POD.POL_en)
                                                    ?   getLocalizePortAndCode(props.ports.find(x => x.POL_en === props.filter.POD.POL_en), getLanguage(location))
                                                    :   props.filter.POD.POL_en
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : <div className={`blockVertical last`}>
                                <div />
                                <div className="routeData">
                                    <div className="to">
                                        <div className="routeDate">
                                            {props.route.transport2 === "rail"
                                                ? <SvgTrain className="train" />
                                                : <SvgShip className="ship" />
                                            }
                                            {moment(props.route?.ETA).format("DD MMMM YYYY")}
                                        </div>
                                        <div className="routeTitle">{props.ports.filter(port => port.POL_en === props.route.POD).map(port => `${getLocalizePortAndCode(port, getLanguage(location))}`)}</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`landingOverTablet ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                        <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                    </div>
                </div>

                <div className="servicesTariffContainer">
                    {props.companies.find(x => x.line === props.route.line)?.tags?.length
                        ? <div className="services">
                            <RouteServices company={props.companies.find(x => x.line === props.route.line)} />
                        </div>
                        : null
                    }
                    <div className="tariffTypes">
                        <RouteTariffType validTill={props.validTill || null} onChange={props.onTariffChange} />
                    </div>
                </div>
            </div>

            <div className={`detailsBtn ${props.route.extensions && props.route.extensions.length ? "hasExtRoutes" : ""} ${props.route.POLDistance || props.route.PODDistance ? "hasDistance" : ""}`} onClick={props.onClose}>
                {getLocalizeText("components/searchResult", "open", getLanguage(location))}
                <ArrowDropUpIcon className="arrow" />
            </div>
        </div>
        <div className="bottomVertical">
            <div className="company">
                <div className="companyContainer">
                    <img src={getCompanyLogoPath(props.companies.find(x => x.line === props.route.line) || {code: "default"})} alt="company_logo" />
                    <div className="name" title={props.companies.filter(x => x.line === props.route.line).filter((x, i) => i === 0).map(x => x.company)}>
                        {getLocalizeCompanyName(props.companies.find(x => x.line === props.route.line) || {company: "<unknown namw>"}, location)}
                    </div>
                    <div className={`landingOver ${beforeThanToday(props.route.ETD) ? "" : "hide"}`}>
                        <IconClosed />{getLocalizeText("components/searchResult", "landingOver", getLanguage(location))}
                    </div>
                </div>
                <div className="soccocBlock">
                    <div className="soccocContainer">
                        <SocCocPrice validTill={props.validTill || null} tariff={props.tariff} />
                    </div>
                </div>
            </div>
            <div className="application" x-data-validTill={props.validTillDate || "undefined"} x-data-allowToBooking={props.route.allowToBooking.toString()} x-data-cutOffDate={props.route.cutOffDate}>
                {
                    props.showPlaceBtn(props)
                        ? <Button fullWidth className={`btn booking`} variant="contained" onClick={() => props.goToBooking(props.route)}>{getLocalizeText("components/searchResult", "goToBooking", getLanguage(location))}</Button>
                        : null
                }

                <Button fullWidth variant="outlined" className={`request ${props.route.allowToBooking && props.route.cutOffDate && moment(props.route.cutOffDate).isValid() && moment(props.route.cutOffDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))) && ((props.validTillDate && moment(props.validTillDate).isSameOrAfter(moment(moment().format("YYYY-MM-DDT00:00:00Z"))))) ? "hasBooking" : ""} ${beforeThanToday(props.route.ETD) ? "hide" : ""}`} onClick={() => props.sendRequest(props.route)}>{getLocalizeText("components/searchResult", "sendQuery", getLanguage(location))}</Button>
            </div>
        </div>
    </div>
});

export default SearchResultRouteVertical;
