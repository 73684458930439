import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import { api_getSearchLogs } from "../../../redux/actions";
import moment from "moment";
import { CSVLink } from "react-csv";
import AdminLayout from "../../../layouts/AdminLayout";

const columns = [
    { 
        field: 'id',
        hide: true
    },
    {
      field: 'pol',
      width: 300
    },
    {
      field: 'pod',
      width: 300
    },
    {
      field: 'ip',
      width: 120
    },
    {
      field: 'date',
      width: 160
    }
  ];

const SearchLogPage = () => {
    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        api_getSearchLogs().then(r => {
            setData(r.map(x => {
                return {
                    id: x._id.toString(),
                    pol: x.pol,
                    pod: x.pod,
                    ip: x.ip,
                    date: moment(x.createdAt).utcOffset(3).format("DD.MM.YYYY HH:mm:ss")
                }
            }));
            setCsvData([["pol","pod","ip","date"]].concat(r.map(x => {
                return [x.pol, x.pod, x.ip, moment(x.createdAt).utcOffset(3).format("DD.MM.YYYY HH:mm:ss")];
            })));
        });
    }, []);

    return <div className="SearchLogPage">
        <AdminLayout>
            <div className="content">
                <div className="buttons">
                    <CSVLink data={csvData} filename={`seach-logs_${moment().format("YYYY-MM-DD_HH-mm-ss")}`}><Button className="btn" variant="contained">Выгрузить</Button></CSVLink>
                </div>
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    pageSize={20}
                    disableSelectionOnClick
                />
            </div>
        </AdminLayout>
    </div>
}

export default SearchLogPage;