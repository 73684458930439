import React from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";

const AdminMenu = () => {
    return <div className="AdminMenu">
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/requestLogPage">Статистика</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/upload">Загрузка данных</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/routes">Маршруты</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/companies">Компании</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/tags">Тэги</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/searchTags">Поисковые тэги</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/banners">Баннеры</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/lineRate">Рейтинг линий</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/mailTemplates">Шаблоны писем</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/domContent">Dom блоки</NavLink>
        <div className="devider" />
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/settings">Настройки</NavLink>
        <NavLink className={({isActive}) => isActive ? "menuItem active" : "menuItem"} to="/admin/users">Пользователи</NavLink>

    </div>
}

export default AdminMenu;