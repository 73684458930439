import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField } from "@mui/material";
import { api_getSearchLogs } from "../../../../../redux/actions";
import moment from "moment";
import { CSVLink } from "react-csv";
import Loader from "../../../../../components/Loader";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/ru";
import { api_getBookingLogs } from "../../../../../redux/actions/bookingLog";

const TabBookingLog = (props) => {
    const columns = [
        { 
            field: 'id',
            hide: true
        },
        { 
            field: 'orderId',
            flex: 1
        },
        { 
            field: 'countryPol',
            flex: 1
        },
        {
          field: 'pol',
          flex: 1
        },
        { 
            field: 'countryPod',
            flex: 1
        },
        {
          field: 'pod',
          flex: 1
        },
        {
            field: 'createdAt',
            type: "date",
            width: 160,
            renderCell: (params) => {
                    return <div className="datetime">
                        <div>{moment(params.row.createdAt).format("DD.MM.YYYY")}</div>
                        <div>{moment(params.row.createdAt).format("HH:mm:ss")}</div>
                    </div>;
            },
            sortComparator: (v1, v2) => {
                if (moment(v1).isAfter(moment(v2)))
                    return 1;
                if (moment(v1).isBefore(moment(v2)))
                    return -1;
                return 0
            }
        }
    ];

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        getData();
    }, []);

    const getData = async(filter) => {
        setIsLoading(true);
        const r = await api_getBookingLogs();

        setData(r.map(x => {
            return {
                id: x._id.toString(),
                orderId: x.data.orderId,
                pol: x.data.polEn,
                countryPol: x.data.countryPolEn,
                countryPod: x.data.countryPodEn,
                pod: x.data.podEn,
                createdAt: x.createdAt
            }
        }));
        
        setIsLoading(false);
    }

    return <div className={`TabBookingLog ${props.active ? "" : "hide"}`}>  
        
        <DataGrid
            className="table"
            rows={data}
            columns={columns}
            pageSize={20}
            disableSelectionOnClick
        />
        <Loader active={isLoading} />
    </div>
}

export default TabBookingLog;