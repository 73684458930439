import React, { memo } from "react";
import MailHeader from "../MailHeader";
import MailBody from "../MailBody";

const MailRequestSeaSubscription = memo((props) => {
    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <MailHeader />

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "left", marginBottom: "44px"}}>
                Заявка на подписку:
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontSize: "12px", fontWeight: "normal", lineHeight: "16px", alignItems: "center", color: "#334155", marginBottom: "12px"}}>
                <b style={{fontWeight: "600", fontSize: "12px", color: "#0F172A", marginRight: "10px"}}>План подписки</b> {props.tariffName} {props.period}
            </div>

            <MailBody
                name={props.name}
                email={props.email}
                phone={props.phone}
                comment={props.comment}
            />

            <div style={{marginTop: "44px", fontFamily: ["Montserrat", "Arial"], fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
                *Данное письмо сформировано автоматически. Отвечать на него не нужно.
            </div>
        </div>
        <div style={{backgroundColor: "#E9F3FD", height: "75px"}}>&nbsp;</div>
    </div>
});

export default MailRequestSeaSubscription;
