import "./style.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getLanguage, getLocalizeText, getLocalizeUrl } from "../../misc/localization";
import { Breadcrumbs, Button, ButtonGroup, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import MainLayout from "../../layouts/MainLayout";
import TariffCard from "../../components/TariffCard";
import Card from "../../components/Card";
import CardText from "../../components/CardText";
import icon_bc_divider from "../../assets/icons/breadcrumb_divider.svg";
import { ReactComponent as ShipSide } from "../../assets/icons/ship-port-side.svg";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";
import { ReactComponent as IconClipboard } from "../../assets/icons/clipboard-check.svg";
import { ReactComponent as IconShip } from "../../assets/icons/ship-outline.svg";
import { ReactComponent as IconDesktop } from "../../assets/icons/desktop.svg";
import img_seafreight_account from "../../assets/imgs/seafreight_account.jpg";
import { api_getSubscribeTariffs } from "../../redux/actions/seafreightTariffs";
import DialogRequestCommon from "../../components/dialogs/RequestCommon";
import { getClientMsg, getMailTemplate, reqLog } from "../../misc/mail";
import { api_mailSend } from "../../redux/actions";
import MailRequestSeaDemo from "../../mails/MailRequestSeaDemo";
import MailRequestSeaSubscription from "../../mails/MailRequestSeaSubscription";
import DialogRequestSubscription from "../../components/dialogs/RequestSubscription";

const SeaFreightPage = () => {
    const locPath = "pages/seafreight";
    const location = useLocation();
    const tariffSection = useRef();
    const breadcrumbs = [
        <Link key="1" to={getLocalizeUrl(location, "/")}>{getLocalizeText("breadcrumbs/main", "main", getLanguage(location))}</Link>,
        <Typography className="current" key="2">{getLocalizeText("breadcrumbs/main", "seafreight", getLanguage(location))}</Typography>
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [tariffs, setTariffs] = useState({});
    const [tariffPeriods, setTariffPeriods] = useState(["3", "6", "12"]);
    const [activeTariffPeriod, setActiveTariffPeriod] = useState("12");
    const [selectedTariff, setSelectedTariff] = useState({
        id: '',
        name: '',
        period: '',
    });

    useEffect(() => {
        getTariffs();
    }, []);

    const getTariffs = async() => {
        setIsLoading(true);
        setTariffs(await getTariffsData());
        setIsLoading(false);
    }

    const getTariffsData = async () => {
        const lang = getLanguage(location);
        let res;
        res = await  api_getSubscribeTariffs(getLanguage(location));

        if (lang !== 'ru' && (!res || !res.length)) {
            res = await  api_getSubscribeTariffs('ru');
        }

        const filteredRes = res.filter((el) => el.optionsOn);

        if (!filteredRes.length) {
            res = mokTariffs;
        } else {
            res = res.reduce((acc, el) => {
                let { period } = el;
                return {...acc, [period]: [...(acc[period] || []), el]};
            },{});
            const periods = Object.keys(res);
            await setTariffPeriods(periods);
            await setActiveTariffPeriod(periods[periods.length-1]);
            setPrevPrice(res, periods);
        }
        return res;
    };

    const setPrevPrice = (tariffs, periods) => {
        periods.forEach((period, periodIdx) => {
            if (periodIdx !== 0) {
                tariffs[period].forEach((i, iIdx )=> i.prevPrice = tariffs[tariffPeriods[0]][iIdx].price);
            } else {
                tariffs[period].forEach((i)=> i.prevPrice = "");
            }
        })
    }

    const mokTariffs = {
        '3': [
            {
                _id: '1',
                name: 'Базовый',
                price: '22000',
                country: 'ru',
                period: '3',
                description: '',
                optimal: false,
                optionsOn: ['до 2 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '2',
                name: 'Оптимальный',
                price: '33000',
                period: '3',
                description: '',
                optimal: true,
                optionsOn: ['до 6 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '3',
                name: 'Расширенный',
                price: '39000',
                period: '3',
                description: '',
                optimal: false,
                optionsOn: ['до 10 пользователей'],
                optionsOff:  [],
            }
        ],
        '6': [
            {
                _id: '4',
                name: 'Базовый',
                price: '20000',
                prevPrice: '22000',
                country: 'ru',
                period: '6',
                description: '',
                optimal: false,
                optionsOn: ['до 2 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '5',
                name: 'Оптимальный',
                price: '30000',
                prevPrice: '33000',
                period: '6',
                description: '',
                optimal: true,
                optionsOn: ['до 6 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '6',
                name: 'Расширенный',
                price: '35000',
                prevPrice: '39000',
                period: '6',
                description: '',
                optimal: false,
                optionsOn: ['до 10 пользователей'],
                optionsOff:  [],
            }
        ],
        '12': [
            {
                _id: '7',
                name: 'Базовый',
                price: '18000',
                prevPrice: '22000',
                country: 'ru',
                period: '12',
                description: '',
                optimal: false,
                optionsOn: ['до 2 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '8',
                name: 'Оптимальный',
                price: '27000',
                prevPrice: '33000',
                period: '12',
                description: '',
                optimal: true,
                optionsOn: ['до 6 пользователей'],
                optionsOff:  [],
            },
            {
                _id: '9',
                name: 'Расширенный',
                price: '31000',
                prevPrice: '39000',
                period: '12',
                description: '',
                optimal: false,
                optionsOn: ['до 10 пользователей'],
                optionsOff:  [],
            }
        ],
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [openDemoDialog, setOpenDemoDialog] = useState(false);

    const onOpenDialog = (id, name, period) => {
        setSelectedTariff({ id, name, period });
        setOpenDialog(true);
    };

    let navigate = useNavigate();

    const onFormSuccess = async (data) => {
        const { tariffName, period, name, email, phone, comment } = data;
        if (typeof window.ym === "function")
            window.ym(89710989,'reachGoal','seafreightSubscription');

        // Save to logs
        const logData = {
            tariffName,
            period: `${period} мес.`,
            name,
            email,
            phone,
            comment
        }
        await reqLog("seafreightSubscription", logData);

        // Send to sea-logic
        const msg = getMailTemplate(
            <MailRequestSeaSubscription
                tariffName={tariffName}
                period={`${period} мес.`}
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
        api_mailSend("SeaFreight заявка на подписку", msg, null, "html");

        // Send to client
        const text = getLocalizeText(locPath, "mailText", getLanguage(location));
        const sbj = getLocalizeText(locPath, "mailSbj", getLanguage(location));
        const msgClient = getClientMsg(getLanguage(location), text);

        api_mailSend(sbj, msgClient, email, "html");

        const successTitle = getLocalizeText( locPath, "success.title", getLanguage(location));
        const successText = getLocalizeText( locPath, "success.text", getLanguage(location));
        navigateToSuccess(successTitle, successText);
    }

    const onFormDemoSuccess = async (data) => {
        const { name, email, phone, comment } = data;

        if (typeof window.ym === "function") window.ym(89710989,'reachGoal','seafreightDemo');

        await reqLog("seafreightDemo", { name, email, phone, comment });

        // Send to sea-logic
        const msg = getMailTemplate(
            <MailRequestSeaDemo
                name={name}
                email={email}
                phone={phone}
                comment={comment}
            />);
        api_mailSend("SeaFreight демо-доступ", msg, null, "html");

        // Send to client
        const text = getLocalizeText(locPath, "mailDemoText", getLanguage(location));
        const sbj = getLocalizeText(locPath, "mailDemoSbj", getLanguage(location));
        const msgClient = getClientMsg(getLanguage(location), text);

        api_mailSend(sbj, msgClient, email, "html");

        const successTitle = getLocalizeText( locPath, "success.title", getLanguage(location));
        const successText = getLocalizeText( locPath, "success.textDemo", getLanguage(location));
        navigateToSuccess(successTitle, successText);
    }

    const navigateToSuccess = (title, text) => {
        navigate(
            getLocalizeUrl(location, "/success"),
            { state: { title, text }}
        );
    }

    return <div className="SeaFreightPage">
        <Helmet>
            <title>{getLocalizeText( locPath, "meta.title", getLanguage(location))}</title>
            <meta name="description" content={getLocalizeText( locPath, "meta.description", getLanguage(location))} />
        </Helmet>

        <MainLayout fixedHeader={true}>
            <div className="pageContent">
                <Breadcrumbs className="breadcrumbs" separator={<img className="bc_divider" src={icon_bc_divider} />}>
                    {breadcrumbs}
                </Breadcrumbs>

                <h1 className="title">{getLocalizeText( locPath, "title", getLanguage(location))}</h1>
                <div className="blockContent">
                    <section className="gridType1 sectionAbout">
                        <div className="container">
                            <p className="text"><span className="brandColor">SeaFreight</span> {getLocalizeText("pages/seafreight", "description", getLanguage(location))}</p>
                            <a className="linkBtn" onClick={() => tariffSection.current.scrollIntoView()}>
                                {getLocalizeText( locPath, "descriptionLink", getLanguage(location))}
                                <IconLinkBtnArrow className="icon"/>
                            </a>
                        </div>

                        <img className="account" src={img_seafreight_account} alt="account" />
                    </section>

                    <section className="sectionAdvantages">
                        <h2 className="title">
                            {getLocalizeText( locPath, "advantages.title", getLanguage(location))}
                        </h2>
                        <ul className="cardList1">
                            <li>
                                <Card
                                    title={getLocalizeText( locPath, "advantages.1.title", getLanguage(location))}
                                    icon={<IconClipboard />}
                                />
                            </li>
                            <li>
                                <Card
                                    title={getLocalizeText( locPath, "advantages.2.title", getLanguage(location))}
                                    icon={<IconShip />}
                                />
                            </li>
                            <li>
                                <Card
                                    title={getLocalizeText( locPath, "advantages.3.title", getLanguage(location))}
                                    icon={<IconDesktop />}
                                />
                            </li>
                        </ul>
                    </section>

                    <section className="sectionWho">
                        <h2 className="title">
                            {getLocalizeText( locPath, "who.title", getLanguage(location))}
                        </h2>
                        <ul className="commonList">
                            {getLocalizeText( locPath, "who.list", getLanguage(location)).map((item, idx) => (
                                <li key={idx} className="commonListItem">
                                    <span className="commonListItemMark">{idx + 1}</span>
                                    <p className="commonListItemText">{item}</p>
                                </li>
                            ))}
                        </ul>
                    </section>

                    <section className="sectionUsefull">
                        <h2 className="title">
                            {getLocalizeText( locPath, "usefull.title", getLanguage(location))}
                        </h2>
                        <ul className="container">
                            {getLocalizeText( locPath, "usefull.list", getLanguage(location)).map((item, idx) => (
                                <li key={idx}>
                                    <CardText
                                        title={item.title}
                                        subtitle={item.text}
                                    />
                                </li>
                            ))}
                            <li className="listItemImg">
                                <ShipSide className="img"/>
                            </li>
                        </ul>
                    </section>

                    <section id="tariffs" ref={tariffSection} className="sectionSubscription">
                        <h2 className="title">
                            {getLocalizeText( locPath, "tariffs.title", getLanguage(location))}
                        </h2>
                        <div className="container">
                            <ButtonGroup size="small" className="buttonGroup">
                                {tariffPeriods.map((item, idx) => (
                                    <Button key={idx} className={activeTariffPeriod === item ? "active" : ""} onClick={() => setActiveTariffPeriod(item)}>{item} мес.</Button>
                                ))}
                            </ButtonGroup>
                            <TariffCard
                                tariffs={tariffs[activeTariffPeriod] || []}
                                chooseTariff={(id, name, period) => onOpenDialog(id, name, period)}
                                fullBadge={true}
                            />
                            <DialogRequestSubscription
                                tariff={selectedTariff}
                                periods={tariffPeriods}
                                open={openDialog}
                                onClose={() => setOpenDialog(false)}
                                onSuccess={(data) => onFormSuccess(data)}
                            />
                        </div>

                        <div className="demoBlock">
                            <h3>{getLocalizeText( locPath, "try", getLanguage(location))}</h3>

                            <button className="linkBtn" onClick={() => setOpenDemoDialog(true)}>
                                {getLocalizeText( locPath, "tryLink", getLanguage(location))}
                                <IconLinkBtnArrow className="icon"/>
                            </button>
                        </div>
                        <DialogRequestCommon
                            open={openDemoDialog}
                            onClose={() => setOpenDemoDialog(false)}
                            onSuccess={(data) => onFormDemoSuccess(data)}
                            title={'SeaFreight'}
                        />
                    </section>
                </div>
            </div>
        </MainLayout>
    </div>
}

export default SeaFreightPage;
