import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Checkbox } from "@mui/material";
import AdminLayout from "../../../layouts/AdminLayout";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../../../components/Loader";
import { api_deleteUser, api_getUsers } from "../../../redux/actions/users";
import AdminUserEditDialog from "./dialogs/AdminUserEditDialog";
import ConfirmDialog from "../../../components/dialogs/Confirm";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";

const AdminUsersPage = () => {
    const columns = [
        {
            field: "id",
            headerName: "ID",
            hide: true
        },
        {
            field: "actions",
            headerName: "",
            renderCell: (props) => {
                return <>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => onOpenEditDialog(props.row)}><EditIcon /></Button>
                    <Button variant="outlined" color="error" className="actionBtn" onClick={() => onDelete(props.row)}><DeleteIcon /></Button>
                </>
            }
        },
        {
            field: "email",
            headerName: "E-MailRequestTariff",
            flex: 1
        },
        {
            field: "password",
            headerName: "Пароль",
            width: 140,
            valueGetter: () => {
                return "**********";
            }
        },
        {
            field: "active",
            headerName: "Активно",
            width: 80,
            renderCell: (props) => {
                return <>
                    <Checkbox checked={props.row.active} disabled />
                </>
            }
        }
    ];

    const [data, setData] = useState([]);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getUsers().then(r => setIsLoading(false));
    }, []);

    const getUsers = async () => {
        setData((await api_getUsers()).map(x => {
            x.id = x._id;
            return x;
        }));
    }

    const onCloseEditDialog = () => {
        setIsOpenEditDialog(false);
        setSelectedItem(null);
    }

    const onOpenEditDialog = (item = null) => {
        setSelectedItem(item);
        setIsOpenEditDialog(true);
    }

    const onSave = () => {
        getUsers();
    }

    const onDelete = (item) => {
        setSelectedItem(item);
        setIsOpenConfirmDialog(true);
    }

    const onCloseConfirmDialog = () => {
        setSelectedItem(null);
        setIsOpenConfirmDialog(false);
    }

    const onConfirm = async () => {
        const r = await api_deleteUser(selectedItem.id);
        if (r) {
            notification.addNotification({
                message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Пользователь успешно удален</span></div>,
                type: "success",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });

            getUsers();
        }
        else {
            notification.addNotification({
                message: <div><Error /><span className="snackErrorText"> Не удалось удалить пользователя</span></div>,
                type: "danger",
                insert: "top",
                container: "bottom-right",
                dismiss: {
                    duration: 5000
                    }
            });
        }
        setSelectedItem(null);
        setIsOpenConfirmDialog(false);
    }

    return <div className="AdminUsersPage">
        <AdminLayout>
            <h1>Пользователи</h1>

            <div className="content">
                <div className="buttons">
                    <Button className="btn mr" variant="contained" onClick={() => onOpenEditDialog()}>Добавить</Button>
                </div>
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowHeight={80}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminUserEditDialog title="Редактирование пользователя" open={isOpenEditDialog} onClose={onCloseEditDialog} onSave={onSave} item={selectedItem} />
            <ConfirmDialog title="Удаление пользователя" content="Вы действительно хотите удалить данного пользователя?" open={isOpenConfirmDialog} onClose={onCloseConfirmDialog} onConfirm={onConfirm} />
            <Loader active={isLoading}/>
        </AdminLayout>
    </div>
}

export default AdminUsersPage;
