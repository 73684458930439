import React, { useEffect, useState } from "react";
import "./style.scss";
import AdminLayout from "../../../layouts/AdminLayout";
import { Button } from "@mui/material";
import AdminUploadLineRateDialog from "./AdminUploadLineRateDialog";
import moment from "moment";
import ConfirmDialog from "../../../components/dialogs/Confirm";
import Loader from "../../../components/Loader";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from "@mui/x-data-grid";
import { api_deleteLineRateTable, api_getLineRateTable } from "../../../redux/actions/lineRate";

const AdminLineRatePage = () => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            width: 100,
            renderCell: (props) => {
                return <>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => openEditDialog(props.row)}><EditIcon /></Button>
                    <Button variant="outlined" color="error" className="actionBtn" onClick={() => confirmDelete(props.row)}><DeleteIcon /></Button>
                </>
            }
        },
        {
            field: "period",
            headerName: "Период",
            flex: 1
        },
        {
            field: "location",
            headerName: "Локация",
            flex: 1
        },
        {
            field: "periodEn",
            headerName: "Период, en",
            flex: 1
        },
        {
            field: "locationEn",
            headerName: "Локация, en",
            flex: 1
        },
        {
            field: "lines",
            headerName: "Компании",
            width: 100,
            valueGetter: (props) => props.row.lines.length
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "datetime",
            valueGetter: (props) => {
                return moment(props.row.createdAt).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isOpenUploadDialog, setIsOpenUploadDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

    useEffect(() => {
        getLineRates();
    }, []);

    const getLineRates = async() => {
        setIsLoading(true);
        setData((await api_getLineRateTable()).map(x => {
            x.id = x._id;
            return x;
        }));
        setIsLoading(false);
    }

    const openEditDialog = (item) => {
        setSelectedItem(item)
        setIsOpenUploadDialog(true);
    }

    const openUploadDialog = () => {
        setIsOpenUploadDialog(true);
    }

    const closeUploadDialog = () => {
        setIsOpenUploadDialog(false);
    }

    const confirmDelete = (item) => {
        setSelectedItem(item);
        setIsOpenConfirmDelete(true);
    }

    const onCloseConfirmDelete = () => {
        setSelectedItem(null);
        setIsOpenConfirmDelete(false);
    }

    const onConfirmDelete = async () => {
        setIsLoading(true);
        await api_deleteLineRateTable(selectedItem._id);
        getLineRates();
        onCloseConfirmDelete();
        setIsLoading(false);
    }

    return <div className="AdminLineRatePage">
        <AdminLayout>
            <h1>Рейтинг линий</h1>

            <div className="content">
                <div className="buttons">
                    <Button variant="contained" onClick={openUploadDialog}>Добавить таблицу рейтинга</Button>
                </div>

                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowHeight={80}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminUploadLineRateDialog open={isOpenUploadDialog} onClose={closeUploadDialog} onSave={getLineRates} item={selectedItem} />
            <ConfirmDialog open={isOpenConfirmDelete} title="Внимание" content="Вы действительно хотите удалить тэг?" onClose={onCloseConfirmDelete} onConfirm={onConfirmDelete} />
            <Loader active={isLoading}/>
        </AdminLayout>
    </div>
}

export default AdminLineRatePage;