import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getReqLogs = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/requestLogs`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_addReqLog = async (reqLog) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/requestLogs`;
        const data = (await axios.post(`${apiHost}${api}`, reqLog, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveReqLog = async (id, reqLog) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/requestLogs/${id}`;
        const data = (await axios.put(`${apiHost}${api}`, reqLog, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}