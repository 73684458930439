import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { api_addLineRateTable, api_saveLineRateTable } from "../../../../redux/actions/lineRate";
import { Store as notification } from 'react-notifications-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Error } from "@mui/icons-material";

const errTextFillField = "Заполните поле";

const AdminUploadLineRateDialog = (props) => {
    const [period, setPeriod] = useState("");
    const [location, setLocation] = useState("");
    const [periodEn, setPeriodEn] = useState("");
    const [locationEn, setLocationEn] = useState("");
    const [periodTr, setPeriodTr] = useState("");
    const [locationTr, setLocationTr] = useState("");
    const [rateTable, setRateTable] = useState("");

    const [errPeriod, setErrPeriod] = useState(false);
    const [errLocation, setErrLocation] = useState(false);
    const [errRateTable, setErrRateTable] = useState(false);

    const [hPeriod, setHPeriod] = useState("");
    const [hLocation, setHLocation] = useState("");
    const [hRateTable, setHRateTable] = useState("");

    useEffect(() => {
        if (props.item) {
            setPeriod(props.item.period);
            setPeriodEn(props.item.periodEn);
            setPeriodTr(props.item.periodTr);
            setLocation(props.item.location);
            setLocationEn(props.item.locationEn);
            setLocationTr(props.item.locationTr);
            const data = [];
            for (const line of props.item.lines)
                data.push(`${line.position}\t${line.line}\t${line.value}\t${line.percent}\t${line.changedPlace}`);
            setRateTable(data.join("\n"));
        }
    }, [props.item]);

    const changePeriod = (e) => {
        setPeriod(e.target.value);
    }

    const changeLoaction = (e) => {
        setLocation(e.target.value);
    }

    const changePeriodEn = (e) => {
        setPeriodEn(e.target.value);
    }

    const changeLoactionEn = (e) => {
        setLocationEn(e.target.value);
    }

    const changePeriodTr = (e) => {
        setPeriodTr(e.target.value);
    }

    const changeLoactionTr = (e) => {
        setLocationTr(e.target.value);
    }

    const changeRateTable = (e) => {
        setRateTable(e.target.value);
    }

    const resetForm = () => {
        setPeriod("");
        setLocation("");
        setPeriodEn("");
        setLocationEn("");
        setPeriodTr("");
        setLocationTr("");
        setRateTable("");
    }

    const clearErrors = () => {
        setErrPeriod(false);
        setHPeriod("");

        setErrLocation(false);
        setHLocation("");

        setErrRateTable(false);
        setHRateTable("");
    }

    const onClose = () => {
        clearErrors();
        resetForm();

        if (typeof props.onClose === "function")
            props.onClose();
    }

    const onSave = async () => {
        clearErrors();
        let hasErrors = false;

        if (!period.length) {
            setErrPeriod(true);
            setHPeriod(errTextFillField);
            hasErrors = true;
        }

        if (!location.length) {
            setErrLocation(true);
            setHLocation(errTextFillField);
            hasErrors = true;
        }

        if (!rateTable.length) {
            setErrRateTable(true);
            setHRateTable(errTextFillField);
            hasErrors = true;
        }

        if (!hasErrors) {
            const lines = rateTable.split("\n");
            let rows = [];
            for (const line of lines) {
                const cells = line.split("\t");
                const position = cells.length >= 1 && parseInt(cells[0].trim(), 10) > 0 ? parseInt(cells[0].trim(), 10) : false;
                const _line = cells.length >= 2 ? cells[1].trim() : false;
                const value = cells.length >= 3 && parseInt(cells[2].trim(), 10) > 0 ? parseInt(cells[2].trim(), 10) : false;
                const percent = cells.length >= 4 && parseFloat(cells[3].replace(",", ".").trim(), 10) > 0 ? parseFloat(cells[3].replace(",", ".").trim(), 10) : false;
                let changedPlace = cells.length >= 5 && !isNaN(parseInt(cells[4].trim(), 10)) ? parseInt(cells[4].trim(), 10) : 0;
                changedPlace = cells.length >= 5 && changedPlace === 0 && cells[4].toLowerCase().trim() === "new" ? "new" : changedPlace;

                if (position && _line && value !== false && percent !== false) {
                    rows.push({
                        position,
                        line: _line,
                        value,
                        percent,
                        changedPlace
                    });
                }
            }

            rows = rows.sort((a, b) => {
                if (a.position > b.position) 
                    return 1;
                if (a.position < b.position) 
                    return -1;
                return 0;
            });

            const data = {
                period: period.trim(),
                location: location.trim(),
                periodEn: periodEn.trim(),
                locationEn: locationEn.trim(),
                periodTr: periodTr.trim(),
                locationTr: locationTr.trim(),
                lines: rows
            };

            const r = props.item ? await api_saveLineRateTable(props.item._id, data) : await api_addLineRateTable(data);
            if (r) {
                notification.addNotification({
                    message: <div><CheckCircleOutlineIcon /><span className="snackSuccessText"> Таблица рейтинга была успешна сохранена</span></div>,
                    type: "success",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });

                if (typeof props.onSave === "function")
                    props.onSave();
                
                onClose();
            }
            else {
                notification.addNotification({
                    message: <div><Error /><span className="snackErrorText"> Не удалось сохранить таблицу рейтинга</span></div>,
                    type: "danger",
                    insert: "top",
                    container: "bottom-right",
                    dismiss: {
                        duration: 5000
                        }
                });
            }
        }
    }


    return <Dialog className="AdminUploadLineRateDialog" open={props.open}>
    <DialogTitle>
        Добавление таблицы рейтинга
    </DialogTitle>
    <DialogContent>
        <DialogContentText>
            
            <div className="row grid-3">
                <TextField required size="small" fullWidth label="Период" value={period} onChange={changePeriod} error={errPeriod} helperText={hPeriod} />
                <TextField size="small" fullWidth label="Период, en" value={periodEn} onChange={changePeriodEn} />
                <TextField size="small" fullWidth label="Период, tr" value={periodTr} onChange={changePeriodTr} />
            </div>
            
            <div className="row grid-3">
                <TextField required size="small" fullWidth label="Город" value={location} onChange={changeLoaction} error={errLocation} helperText={hLocation} />
                <TextField size="small" fullWidth label="Город, en" value={locationEn} onChange={changeLoactionEn} />
                <TextField size="small" fullWidth label="Город, tr" value={locationTr} onChange={changeLoactionTr} />
            </div>

            <div className="row">
                <TextField required className="rateTable" size="small" fullWidth label="Таблица рейтинга" multiline rows={20} value={rateTable} onChange={changeRateTable}  error={errRateTable} helperText={hRateTable} />
            </div>

        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button className="mr" variant="outlined" onClick={onClose} autoFocus>Отмена</Button>
        <Button variant="contained" onClick={onSave}>Сохранить</Button>
    </DialogActions>
</Dialog>
}

export default AdminUploadLineRateDialog;