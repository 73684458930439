import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CollapsedBlock from "../CollapsedBlock";
import "./style.scss";
import {ReactComponent as Icon_calendar} from "../../assets/icons/calendar.svg";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/ru';
import 'dayjs/locale/tr';
import { getLanguage, getLocalizeCompanyName, getLocalizeText } from "../../misc/localization";
import { useLocation } from "react-router-dom";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { getCompanyLogoPath } from "../../misc/file";
import { isDesktop } from "../../misc/dom";
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const SearchFilter = (props) => {
    const location = useLocation();

    const ref_searchLoader = useRef(null);

    const companies = useSelector(state => state.company.data);
    const allTags = useSelector(state => state.tag.data);

    const [exact, setExact] = useState(false);
    const [near, setNear] = useState(false);
    const [cutOffDate, setCutOffDate] = useState(null);
    const [checkedTags, setCheckedTags] = useState([]);
    const [withPrices, setWithPrices] = useState(false);
    const [woPrices, setWoPrices] = useState(false);
    const [checkedCompanies, setCheckedCompanies] = useState([]);

    useEffect(() => {
        if (props.isLoading)
            startLoading();
        else
            stopLoading();
    }, [props.isLoading]);

    const startLoading = () => {
        if (ref_searchLoader?.current) {
            ref_searchLoader.current.classList.add("animate");
            setTimeout(() => {
                if (ref_searchLoader?.current?.classList.contains("animate")) {
                    ref_searchLoader.current.classList.remove("animate");
                    setTimeout(() => startLoading(), 100);
                }
            }, 2000);
        }
    }

    const stopLoading = () => {
        ref_searchLoader.current.classList.remove("animate");
    }

    const getFilterRoutes = () => {
        return <div className="checkboxContainer">
              {props.exact.length 
                ? <FormControlLabel className="checkbox" control={<Checkbox checked={exact} onChange={(e) => setExact(e.target.checked)} />} label={getLocalizeText("components/searchFilter", "direct", getLanguage(location))} />
                : null
              }
              {props.near.length 
                ? <FormControlLabel className="checkbox" control={<Checkbox checked={near} onChange={(e) => setNear(e.target.checked)} />} label={getLocalizeText("components/searchFilter", "alternative", getLanguage(location))} />
                : null
              }
        </div>
    }

    const changeCutOffDate = (value) => {
        setCutOffDate(value);
    }

    const getFilterCutOff = () => {
        return <div className="filterBlock">
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLanguage(location)}>
                <DesktopDatePicker
                    components={{
                        OpenPickerIcon: Icon_calendar
                    }}
                    className="cutOffDate"
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={cutOffDate}
                    onChange={changeCutOffDate}
                    renderInput={(params) => <TextField size="small" {...params} />}
                />
            </LocalizationProvider>
        </div>
    }

    const getFilterServices = () => {
        const tags = [];
        if (Array.isArray(props.exact)) {
            props.exact.forEach(x => {
                const company = companies.find(y => y.line === x.line);
                if (company) {
                    for (const tagId of company.tags) {
                        const tag = allTags.find(x => x._id === tagId);
                        if (tag && !tags.some(y => y.value === tag.value)) {
                            tags.push(tag);
                        }
                    }
                }
            });
        }

        if (Array.isArray(props.near)) {
            props.near.forEach(x => {
                const company = companies.find(y => y.line === x.line);
                if (company) {
                    for (const tagId of company.tags) {
                        const tag = allTags.find(x => x._id === tagId);
                        if (tag && !tags.some(y => y.value === tag.value)) {
                            tags.push(tag);
                        }
                    }
                }
            });
        }

        return <div className="checkboxContainer">
            {tags.map(x => <FormControlLabel className="checkbox" control={<Checkbox checked={checkedTags.find(y => y.value === x.value)?.checked || false} onChange={(e) => checkTag(x.value, e.target.checked)} />} label={x.value.toUpperCase()} />)}
        </div>
    }

    const getFilterPrice = () => {
        return <div className="checkboxContainer">
              <FormControlLabel className="checkbox" control={<Checkbox checked={withPrices} onChange={(e) => setWithPrices(e.target.checked)} />} label={getLocalizeText("components/searchFilter", "withPrice", getLanguage(location))} />
              <FormControlLabel className="checkbox" control={<Checkbox checked={woPrices} onChange={(e) => setWoPrices(e.target.checked)} />} label={getLocalizeText("components/searchFilter", "woPrice", getLanguage(location))} />
        </div>
    }

    const getFilterCompanies = () => {
        const uniqCompanies = [];
        if (Array.isArray(props.exact)) {
            props.exact.forEach(x => {
                const company = companies.find(y => y.line === x.line);
                if (company) {
                    if (!uniqCompanies.some(y => y.company === company.company))
                        uniqCompanies.push(company);
                }
            });
        }

        if (Array.isArray(props.near)) {
            props.near.forEach(x => {
                const company = companies.find(y => y.line === x.line);
                if (company) {
                    if (!uniqCompanies.some(y => y.company === company.company))
                        uniqCompanies.push(company);
                }
            });
        }

        return <div className="checkboxContainer">
            {uniqCompanies.map(x => <FormControlLabel className="checkbox company" labelPlacement="start" control={<Checkbox checked={checkedCompanies.find(y => y.company === x.company)?.checked || false} onChange={(e) => checkCompanies(x.company, e.target.checked)} />} label={<><img src={getCompanyLogoPath(x)} alt="logo" /><div title={getLocalizeCompanyName(x, location)}>{getLocalizeCompanyName(x, location)}</div></>} />)}
        </div>
    }

    const checkCompanies = (company, checked) => {
        const _checked = lodash.cloneDeep(checkedCompanies);
        const index = _checked.findIndex(x => x.company === company);
        if (index === -1) {
            _checked.push({
                company,
                checked
            });
        }
        else {
            _checked[index].checked = checked;
        }
        setCheckedCompanies(_checked);
    }

    const checkTag = (value, checked) => {
        const _checked = lodash.cloneDeep(checkedTags);
        const index = _checked.findIndex(x => x.value === value);
        if (index === -1) {
            _checked.push({
                value,
                checked
            });
        }
        else {
            _checked[index].checked = checked;
        }
        setCheckedTags(_checked);
    }

    const applyFilter = () => {
        const data = {
            exact,
            near,
            cutOffDate: cutOffDate ? cutOffDate.toDate() : null,
            checkedTags: checkedTags.filter(x => x.checked),
            withPrices,
            woPrices,
            checkedCompanies: checkedCompanies.filter(x => x.checked)
        };
        props.onApplyFilter(data);
    }

    const resetFilter = () => {
        setExact(false);
        setNear(false);
        setCutOffDate(null);
        setCheckedTags([]);
        setCheckedCompanies([]);
        setWithPrices(false);
        setWoPrices(false);

        props.onResetFilter();
    }

    const handleClose = () => {
        props.onResetFilter();
    }

    return <div className="SearchFilter">
        <div className="closeIcon" onClick={handleClose}>
            <CloseIcon className="close" />
        </div>
        <div className={`searchLoaderContainer ${!props.isLoading ? "hide" : ""}`}>
            <div ref={ref_searchLoader} className={`searchLoader`} />
        </div>
        {props.isLoading
            ? null
            : <>
                <div className="titleFilter">{getLocalizeText("components/searchFilter", "filter", getLanguage(location))}</div>

                {props.exact && props.near && props.exact.length + props.near.length > 0
                    ? <CollapsedBlock 
                        title={getLocalizeText("components/searchFilter", "routes", getLanguage(location))}
                        open={true}
                        children={getFilterRoutes()}
                    />
                    : null
                }

                <CollapsedBlock 
                    title="CUT-OFF"
                    tooltip={getLocalizeText("components/searchFilter", "tooltip.cutOff", getLanguage(location))}
                    open={isDesktop ? true : false}
                    children={getFilterCutOff()}
                    filterOpen={props.open}
                />

                {props.exact && props.near && props.exact.length + props.near.length > 0
                    ? <CollapsedBlock 
                        title={getLocalizeText("components/searchFilter", "services", getLanguage(location))}
                        open={isDesktop ? true : false}
                        children={getFilterServices()}
                        filterOpen={props.open}
                    />
                    : null
                }

                {props.exact && props.near && props.exact.length + props.near.length > 0
                    ? <CollapsedBlock 
                        title={getLocalizeText("components/searchFilter", "cost", getLanguage(location))}
                        open={isDesktop ? true : false}
                        children={getFilterPrice()}
                        filterOpen={props.open}
                    />
                    : null 
                }

                {props.exact && props.near && props.exact.length + props.near.length > 0
                    ? <CollapsedBlock 
                        title={getLocalizeText("components/searchFilter", "companies", getLanguage(location))}
                        open={isDesktop ? true : false}
                        children={getFilterCompanies()}
                        filterOpen={props.open}
                    />
                    : null
                }

                <div className="buttons filterBlock">
                    <Button variant="contained" className="btn" onClick={applyFilter}>{getLocalizeText("components/searchFilter", "apply", getLanguage(location))}</Button>                
                    <Button variant="outlined" className="btn outlined reset" onClick={resetFilter} >{getLocalizeText("components/searchFilter", "reset", getLanguage(location))}</Button>
                </div>
            </>
        }
    </div>
}

export default SearchFilter;