import axios from "axios";
import { getApiHost } from "../../misc/api";

const apiHost = getApiHost();

export const api_getBookingLogs = async () => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/bookingLogs`;
        const data = (await axios.get(`${apiHost}${api}`, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_addBookingLog = async (bookingLog) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/bookingLogs`;
        const data = (await axios.post(`${apiHost}${api}`, bookingLog, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}

export const api_saveBookingLog = async (id, bookingLog) => {
    try {
        const headers = {
            token: localStorage.getItem("token")
        };
        const api = `/api/bookingLogs/${id}`;
        const data = (await axios.put(`${apiHost}${api}`, bookingLog, {headers})).data;
        return data;
    }
    catch(e) {
        return null;
    }
}