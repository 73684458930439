import React, { useEffect, useState } from "react";
import "./style.scss";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";
import AdminLayout from "../../../layouts/AdminLayout";
import { api_getRoutes } from "../../../redux/actions/routes";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import img_ship from "../../../assets/icons/shipNew.svg";
import img_rail from "../../../assets/icons/trainNew.svg";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { localization_dataGrid } from "../../../misc/dom";
import AdminRouteEditDialog from "./dialogs/AdminRouteEditDialog";
import { api_getTags } from "../../../redux/actions/tag";
import Loader from "../../../components/Loader";

const AdminRoutesPage = () => {
    const columns = [
        { 
            field: "id",
            headerName: "ID",
            hide: true
        },
        { 
            field: "actions",
            headerName: "",
            renderCell: (props) => {
                return <>
                    <Button variant="outlined" className="actionBtn mr" onClick={() => onOpenEditDialog(props.row)}><EditIcon /></Button>
                    <Button variant="outlined" color="error" className="actionBtn"><DeleteIcon /></Button>
                </>
            }
        },
        {
            field: "type",
            headerName: "Тип",
            width: 60,
            renderCell: (params) => {
                return <div title={params.row.type}>{params.row.type === "direct" ? <ArrowForwardIcon /> : <LocalShippingIcon/>}</div>
            }
        },
        {
            field: "line",
            headerName: "Линия",
            flex: 1
        },
        {
            field: "vessel",
            headerName: "Судно",
            flex: 1
        },
        {
            field: "voyage",
            headerName: "Рейс",
            flex: 1
        },
        {
            field: "POL",
            headerName: "Отправка",
            flex: 1,
            renderCell: (props) => <div title={allPorts.find(x => x.POL_en === props.row.POL)?.POL_ru || props.row.POL}>{allPorts.find(x => x.POL_en === props.row.POL)?.POL_ru || props.row.POL}</div>
        },
        {
            field: "POD",
            headerName: "Прибытие",
            flex: 1,
            renderCell: (props) => <div title={allPorts.find(x => x.POL_en === props.row.POD)?.POL_ru || props.row.POD}>{allPorts.find(x => x.POL_en === props.row.POD)?.POL_ru || props.row.POD}</div>
        },
        {
            field: "ETD",
            headerName: "Д. отпр.",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return moment(params.row.ETD).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "ETA",
            headerName: "Д. приб.",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return moment(params.row.ETA).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "transport1",
            headerName: "Тр. 1",
            width: 50,
            renderCell: (params) => {
                const tr = params.row.transport1 && typeof params.row.transport1 === "string" ? (params.row.transport1 === "sea" ? <img src={img_ship} alt="ship" /> : <img src={img_rail} alt="rail" />) : "";
                return <div title={params.row.transport1}>{tr}</div>;
            }
        },
        {
            field: "transport2",
            headerName: "Тр. 2",
            width: 50,
            renderCell: (params) => {
                const tr = params.row.transport2 && typeof params.row.transport2 === "string" ? (params.row.transport2 === "sea" ? <img src={img_ship} alt="ship" /> : <img src={img_rail} alt="rail" />) : "";
                return <div title={params.row.transport2}>{tr}</div>;
            }
        },
        {
            field: "transshipmentLocation",
            headerName: "Перегрузка",
            flex: 1,
            valueGetter: (props) => allPorts.find(x => x.POL_en === props.row.transshipmentLocation)?.POL_ru || props.row.transshipmentLocation
        },
        {
            field: "tags",
            headerName: "Тэги",
            cellClassName: "tagCell",
            renderCell: (params) => {
                const _tags = [];
                for (const tagId of params.row.tags) {
                    const tag = tags.find(x => x._id === tagId);
                    if (tag) {
                        const tagType = tagTypes.find(x => x.code === tag.type);
                        if (tagType) {
                            _tags.push(<div style={{background: tagType.background, color: tagType.color, borderRadius: "2px", fontSize: "10px", padding: "2px"}}>{tag.value}</div>);
                        }
                    }
                }
                return _tags;
            }
        },
        {
            field: "priority",
            headerName: "Приор.",
            width: 60,
            type: "number"
        },
        {
            field: "allowToBooking",
            headerName: "Букинг",
            width: 60,
            type: "boolean",
            renderCell: (params) => {
                return params.row.allowToBooking ? "Да" : "Нет";
            },
        },
        {
            field: "cutOffDate",
            headerName: "cut off",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return params.row.cutOffDate ? moment(params.row.cutOffDate).format("DD.MM.YYYY") : "";
            },
            sortComparator: (v1, v2) => {
                if (!v1 || !v2) return -1;
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "bookingValidTill",
            headerName: "Букинг до",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return params.row.bookingValidTill ? moment(params.row.bookingValidTill).format("DD.MM.YYYY") : "";
            },
            sortComparator: (v1, v2) => {
                if (!v1 || !v2) return -1;
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
        {
            field: "createdAt",
            headerName: "Создано",
            width: 100,
            type: "date",
            renderCell: (params) => {
                return moment(params.row.createdAt).format("DD.MM.YYYY");
            },
            sortComparator: (v1, v2) => {
                if (moment(v1, "DD.MM.YYYY").isAfter(moment(v2, "DD.MM.YYYY")))
                    return 1;
                if (moment(v1, "DD.MM.YYYY").isBefore(moment(v2, "DD.MM.YYYY")))
                    return -1;
                return 0
            }
        },
    ];

    const dispatch = useDispatch();

    const allPorts = useSelector(state => state.port.data);
    const tags = useSelector(state => state.tag.data);
    const tagTypes = useSelector(state => state.tagType.data);

    const [data, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getRoutes().then(r => setIsLoading(false));
    }, []);

    useEffect(() => {
        setCsvData([[
            "type",
            "line",
            "vessel",
            "voyage", 
            "POL_eng", 
            "transport_1", 
            "transshipment_location", 
            "transport_2", 
            "POD_eng", "ETD", 
            "ETA", 
            "update", 
            "RATE1", 
            "RATE2", 
            "RATE3", 
            "RATE4", 
            "search_priority_code",
            "allowToBooking",
            "cutOffDate"
        ]].concat(data.map(x => {
            const tag1 = Array.isArray(x.tags) && x.tags.length >= 1 && x.tags[0].trim() ? tags.find(y => y._id === x.tags[0].trim())?.value || "" : "";
            const tag2 = Array.isArray(x.tags) && x.tags.length >= 2 && x.tags[1].trim() ? tags.find(y => y._id === x.tags[1].trim())?.value || "" : "";
            const tag3 = Array.isArray(x.tags) && x.tags.length >= 3 && x.tags[2].trim() ? tags.find(y => y._id === x.tags[2].trim())?.value || "" : "";
            const tag4 = Array.isArray(x.tags) && x.tags.length >= 4 && x.tags[3].trim() ? tags.find(y => y._id === x.tags[3].trim())?.value || "" : "";

            return [
                x.type,
                x.line,
                x.vessel,
                x.voyage, 
                x.POL, 
                x.transport1, 
                x.transshipmentLocation, 
                x.transport2, 
                x.POD, 
                moment(x.ETD).format("DD.MM.YYYY"), 
                moment(x.ETA).format("DD.MM.YYYY"), 
                moment(x.updatedAt).format("DD.MM.YYYY"), 
                tag1, 
                tag2,
                tag3,
                tag4, 
                x.priority,
                x.allowToBooking ? 1 : 0,
                x.cutOffDate || "",
            ];
        })));
    }, [data]);

    const getRoutes = async () => {
        setData((await api_getRoutes()).map(x => {
            x.id = x._id;
            x.ETD = moment(x.ETD).toDate();
            x.ETA = moment(x.ETA).toDate();
            x.cutOffDate = x.cutOffDate ? moment(x.cutOffDate).toDate() : "";
            x.bookingValidTill = x.bookingValidTill ? moment(x.bookingValidTill).toDate() : "";
            return x;
        }));
    }

    const getTags = () => {
        dispatch(api_getTags());
    }

    const onCloseEditDialog = () => {
        setIsOpenEditDialog(false);
        setSelectedItem(null);
    }

    const onOpenEditDialog = (item = null) => {
        setSelectedItem(item);
        setIsOpenEditDialog(true);
    }

    const onSave = () => {
        getRoutes();
        getTags();
    }

    return <div className="AdminRoutesPage">
        <AdminLayout>
            <h1>Маршруты</h1>

            <div className="content">
                <div className="buttons">
                    <Button className="btn mr" variant="contained" onClick={() => onOpenEditDialog()}>Добавить</Button>
                    <CSVLink data={csvData} filename={`routes_${moment().format("YYYY-MM-DD_HH-mm-ss")}`}><Button className="btn" variant="contained">Выгрузить</Button></CSVLink>
                </div>
                <DataGrid
                    className="table"
                    rows={data}
                    columns={columns}
                    rowHeight={80}
                    rowsPerPageOptions={[20, 50, 100]}
                />
            </div>

            <AdminRouteEditDialog title="Редактирование маршрута" open={isOpenEditDialog} onClose={onCloseEditDialog} onSave={onSave} item={selectedItem} />
            <Loader active={isLoading}/>
        </AdminLayout>
    </div>
}

export default AdminRoutesPage;