import axios from "axios";

axios.interceptors.response.use((response) => {
    return response;
}, 
(error) => {
    if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/admin/login";
    }

    return Promise.reject(error);
});