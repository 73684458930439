import React, { useEffect, useState } from "react";
import { formatNumber } from "../../../misc/number";
import moment from "moment";

const SocCocPrice = (props) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.validTill && props.tariff) {
            setData(props.validTill.prices?.find(x => x.type.toLowerCase() === props.tariff.toLowerCase()));
        }
    }, [props.validTill, props.tariff]);
    

    return <div className="SocCocPrice">
        {data
            ? <>
                {data.soc?.length && (moment(data.soc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment()) || moment(data.soc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment()))
                    ? <div className="socContainer">
                        <div className="title">SOC</div>
                        <div className="prices">
                            {data.soc.find(x => x.name.toLowerCase() === "20dc") && moment(data.soc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment())
                                ? <div className="price">20DC – <span>{formatNumber(data.soc.find(x => x.name.toLowerCase() === "20dc")?.value)} {data.soc.find(x => x.name.toLowerCase() === "20dc")?.currency}</span></div>
                                : null
                            }
                            {data.soc.find(x => x.name.toLowerCase() === "40hc") && moment(data.soc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment())
                                ? <div className="price">40HC – <span>{formatNumber(data.soc.find(x => x.name.toLowerCase() === "40hc")?.value)} {data.soc.find(x => x.name.toLowerCase() === "40hc")?.currency}</span></div>
                                : null
                            }
                        </div>
                    </div>
                    : null
                }
                {data.coc?.length && (moment(data.coc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment()) || moment(data.coc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment()))
                    ? <div className="cocContainer">
                        <div className="title">COC</div>
                        <div className="prices">
                            {data.coc.find(x => x.name.toLowerCase() === "20dc") && moment(data.coc.find(x => x.name.toLowerCase() === "20dc")?.validTill || "").isSameOrAfter(moment())
                                ? <div className="price">20DC – <span>{formatNumber(data.coc.find(x => x.name.toLowerCase() === "20dc")?.value)} {data.coc.find(x => x.name.toLowerCase() === "20dc")?.currency}</span></div>
                                : null
                            }
                            {data.coc.find(x => x.name.toLowerCase() === "40hc") && moment(data.coc.find(x => x.name.toLowerCase() === "40hc")?.validTill || "").isSameOrAfter(moment())
                                ? <div className="price">40HC – <span>{formatNumber(data.coc.find(x => x.name.toLowerCase() === "40hc")?.value)} {data.coc.find(x => x.name.toLowerCase() === "40hc")?.currency}</span></div>
                                : null
                            }
                        </div>
                    </div>
                    : null
                }
            </>
            : null
        }
    </div>
}

export default SocCocPrice;