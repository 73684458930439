import React, { memo } from "react";
import { renderToString } from "react-dom/server";
import { getHost } from "../../misc/api";

const MailRequest = memo((props) => {

    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <div style={{display: "flex", flexDirection: "row", gap: "0px", padding: "27px 60px", borderBottom: "1px solid #E2E8F0", height: "80px", boxSizing: "border-box"}}>
            <div style={{width: "30px", marginRight: "8px"}}>
                <a style={{textDecoration: "none !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    <img style={{width: "100%"}} src={`${getHost()}/images/logo.png`} alt="logo" />
                </a>
            </div>
            <div style={{fontFamily: "Arial", fontWeight: "700", fontSize: "30px", lineHeight: "14px", marginTop: "4px"}}>
                <a style={{textDecoration: "none !important", color: "#000000 !important"}} href={`${getHost()}/?utm_source=pablic&utm_medium=team&utm_content=team`}>
                    sealogic
                </a>
            </div>
        </div>

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: "Arial", textTransform: "uppercase", fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "center", marginBottom: "66px"}}>
                Данные по заявке:
            </div>

            {props.number && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Номер: <b>{props.number}</b>
            </div>}

            {props.line && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Линия: <b>{props.line}</b>
            </div>}

            {props.vessel && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Судно: <b>{props.vessel}</b>
            </div>}

            {props.voyage && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
            Рейс: <b>{props.voyage}</b>
            </div>}

            {props.pol && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Порт отправления: <b>{props.pol}</b>
            </div>}

            {props.pod && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Порт прибытия: <b>{props.pod}</b>
            </div>}

            {props.eta && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Дата отправления (ETD): <b>{props.etd}</b>
            </div>}

            {props.etd && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Дата прибытия (ETA): <b>{props.eta}</b>
            </div>}

            {props.route && <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Маршрут: <b>{props.route}</b>
            </div>}

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Тип контейнера: <b>{props.type}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Количество: <b>{props.count}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Имя: <b>{props.name}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                e-mail: <b>{props.email}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Телефон: <b>{props.phone}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Комментарий: <br/><b style={{marginLeft: "20px", display: "block"}} dangerouslySetInnerHTML={{__html: props.comment?.replace(/\n/, renderToString(<br />))}} />
            </div>

            {props.utm?.fullUrl && props.utm?.url
                ? <div style={{padding: "64px 0px"}}>
                    <div style={{fontFamily: "Arial", fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", marginBottom: "20px"}}>
                        Дополнительная информация
                    </div>

                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        Sent from the page: <a target="_blank" href={props.utm?.url} style={{wordBreak: "break-all"}}>{props.utm?.fullUrl}</a>
                    </div>

                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        UTM source: <b>{props.utm?.source || ""}</b>
                    </div>

                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        UTM medium: <b>{props.utm?.medium || ""}</b>
                    </div>
                    
                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        UTM campaign: <b>{props.utm?.campaign || ""}</b>
                    </div>

                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        UTM content: <b>{props.utm?.content || ""}</b>
                    </div>

                    <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                        UTM term: <b>{props.utm?.term || ""}</b>
                    </div>
                </div>
                : null
            }

            <div style={{marginTop: "44px", fontFamily: "Arial", fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
                *Данное письмо сформировано автоматически. Отвечать на него не нужно.
            </div>
        </div>
        <div style={{backgroundColor: "#E9F3FD", height: "75px"}}>&nbsp;</div>
    </div>
});

export default MailRequest;