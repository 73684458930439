import React, { useEffect, useState } from "react";
import "./style.scss";
import img_lightning from "../../assets/imgs/lightning.svg";
import { Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useLocation, useNavigate } from "react-router-dom";
import { api_getActualRoutes } from "../../redux/actions";
import moment from "moment";
import { useSelector } from "react-redux";
import DialogRequest from "../dialogs/Request";
import SearchResultRouteNear from "../SearchResultRouteNear";
import { getLanguage, getLocalizeText } from "../../misc/localization";
import { isDesktop, isMobile } from "../../misc/dom";
import { api_getFreightValidTill } from "../../redux/actions/freight";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";
import { cloneDeep } from "lodash";

const ActualTransactions = (props) => {
    const location = useLocation();

    moment.locale(getLanguage(location));

    const ports = useSelector(state => state.port.data);
    const companies = useSelector(state => state.company.data);

    const navigate = useNavigate();
    const [fromRussia, setFromRussia] = useState([]);
    const [toRussia, setToRussia] = useState([]);
    const [active, setActive] = useState(isMobile ? 0 : 1);
    const [routes, setRoutes] = useState([]);
    const [openRequestDialog, setOpenRequestDialog] = useState(false);
    const [requestDialogRoute, setRequestDialogRoute] = useState(null);


    useEffect(() => {
        getActual();
    }, []);

    useEffect(() => {
        if (!fromRussia.some(x => typeof x.validTill !== "undefined"))
            addValidTill(fromRussia, setFromRussia);
        if (active === 2)
            setRoutes(fromRussia);
    }, [fromRussia]);

    useEffect(() => {
        if (!toRussia.some(x => typeof x.validTill !== "undefined"))
            addValidTill(toRussia, setToRussia);
        if (active === 1)
            setRoutes(toRussia);
    }, [toRussia]);

    const getActual = async () => {
        const r = await api_getActualRoutes();
        let routesToRussia = [];
        if (r) {
            if (typeof r.routesFromRussia !== "undefined" && Array.isArray(r.routesFromRussia)) {
                let routesFromRussia = r.routesFromRussia.filter(x => x.priority).sort((a, b) => {
                    const aPriority = a.priority;
                    const bPriority = b.priority;
                    if (aPriority !== bPriority) {
                        return aPriority < bPriority ? -1 : 1;
                    }
                    else {
                        return moment(a.ETD).isBefore(moment(b.ETD)) ? -1 : (moment(a.ETD).isAfter(moment(b.ETD)) ? 1 : 0);
                    }
                });

                routesFromRussia = routesFromRussia.concat(r.routesFromRussia.filter(x => !x.priority).sort((a, b) => {
                    if (moment(a.ETD).isBefore(moment(b.ETD))) {
                        return -1;
                    }
                    if (moment(a.ETD).isAfter(moment(b.ETD))) {
                        return 1;
                    }
                    return 0;
                }));
                setFromRussia(routesFromRussia);
            }
            if (typeof r.routesToRussia !== "undefined" && Array.isArray(r.routesToRussia)) {
                routesToRussia = r.routesToRussia.filter(x => x.priority).sort((a, b) => {
                    const aPriority = a.priority;
                    const bPriority = b.priority;
                    if (aPriority !== bPriority) {
                        return aPriority < bPriority ? -1 : 1;
                    }
                    else {
                        return moment(a.ETD).isBefore(moment(b.ETD)) ? -1 : (moment(a.ETD).isAfter(moment(b.ETD)) ? 1 : 0);
                    }
                });
                routesToRussia = routesToRussia.concat(r.routesToRussia.filter(x => !x.priority).sort((a, b) => {
                    if (moment(a.ETD).isBefore(moment(b.ETD))) {
                        return -1;
                    }
                    if (moment(a.ETD).isAfter(moment(b.ETD))) {
                        return 1;
                    }
                    return 0;
                }));
                setToRussia(routesToRussia);
            }
            setRoutes(routesToRussia);
            setActive(1);
        }
    }

    const addValidTill = async (routes, saveFn) => {
        let needToSave = false;
        routes = cloneDeep(routes);
        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            if (typeof route.validTill === "undefined") {
                const pol = ports.find(x => x.POL_en === route.POL);
                const pod = ports.find(x => x.POL_en === route.POD);
                if (pol && pod) {
                    try {
                        const validTills = await api_getFreightValidTill(pol.POL_en, pol.POL_ru, pod.POL_en, pod.POL_ru);
                        const validTill = validTills?.find(x => x.line === route.line);
                        if (validTill) {
                            routes[i].validTill = validTill;
                            routes[i].validTillDate = validTill.prices.find(x => x.soc.find(y => y.validTill))?.soc.find(y => y.validTill)?.validTill || validTill.prices.find(x => x.coc.find(y => y.validTill))?.coc.find(y => y.validTill)?.validTill || null;
                            needToSave = true;
                            //console.log(route);
                        }
                    }
                    catch(e) {
                        console.log(e);
                    }
                }
            }
        }

        if (needToSave) {
            // console.log(routes);
            saveFn(routes);
        }
    }

    const goToSearch = () => {
        navigate("search");
    }

    const sendRequest = (route) => {
        setRequestDialogRoute(route);
        setOpenRequestDialog(true);
    }

    const closeRequestDialog = () => {
        setOpenRequestDialog(false);
        setRequestDialogRoute(null);
    }

    const onActive = (act) => {
        setRoutes(act === 1 ? toRussia : fromRussia);
        setActive(act);
    }

    return <div className="ActualTransactions">
        <div className="header">
            <div className="text">
                <div className="title">{getLocalizeText("components/actualTransactions", "title", getLanguage(location))}</div>
                <div className="date">{isDesktop ? moment().format(getLanguage(location) === "ru" ? "DD MMM YYYY, dddd" : "MMM DD YYYY, dddd") : moment().format(getLanguage(location) === "ru" ? "DD MMM YYYY, dddd" : "MMM DD YYYY, dddd")}</div>
            </div>
            <div className={`btnContainer${typeof props.noSearchBtn === "boolean" && props.noSearchBtn ? " hide" : ""}`}>
                <div className="linkBtn" onClick={goToSearch}>{getLocalizeText("components/actualTransactions", "find", getLanguage(location))} <IconLinkBtnArrow className="icon"/></div>
            </div>
        </div>
        <div className="subHeader">
            <div className="buttonsContainer">
                <Button className={active === 1 ? "active" : ""} onClick={() => onActive(1)}>{getLocalizeText("components/actualTransactions", "import", getLanguage(location))}</Button>
                <Button className={active === 2 ? "active" : ""}  onClick={() => onActive(2)}>{getLocalizeText("components/actualTransactions", "export", getLanguage(location))}</Button>
            </div>
            <div className={`btnContainer`}>
                <div className="linkBtn" onClick={goToSearch}>{getLocalizeText("components/actualTransactions", "find", getLanguage(location))} <IconLinkBtnArrow className="icon"/></div>
            </div>
        </div>
        <div className="blocks">
            {routes.map(route => <div key={`actual_${route._id.toString()}}`}>
                <SearchResultRouteNear
                    route={route}
                    ports={ports}
                    companies={companies}
                    sendRequest={sendRequest}
                    validTill={route.validTill || null}
                    validTillDate={route.validTillDate || null}
                />
            </div>)}
        </div>
        <DialogRequest open={openRequestDialog} onClose={closeRequestDialog} route={requestDialogRoute} routeDisabled={true}/>
    </div>
}

export default ActualTransactions;