import React from "react";
import pdf from "../../assets/pdf/privacyPolicy.pdf";
import pdfEn from "../../assets/pdf/privacyPolicyEn.pdf";
import pdfTr from "../../assets/pdf/privacyPolicyTr.pdf";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { getLanguage } from "../../misc/localization";

const PrivacyPolicyPage = () => {
    const location = useLocation();

    return <div className="PrivacyPolicyPage">
        <object data={getLanguage(location) === "ru" ? pdf : (getLanguage(location) === "en" ? pdfEn : pdfTr)} type="application/pdf" width="100%" height="100%">
            <p>Download <a href={getLanguage(location) === "ru" ? pdf : (getLanguage(location) === "en" ? pdfEn : pdfTr)} download>Privacy policy</a></p>
        </object>
    </div>
}

export default PrivacyPolicyPage;